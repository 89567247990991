import { useEffect, useState } from "react";

import clsx from "clsx";
import { useParams } from "react-router";

import { Card } from "../../../components/base/Card";
import { SectionLoading } from "../../../components/shared/SectionLoading";
import { IDataPointProfile } from "../../../types/DataPoint/DataPointProfile";
import useSuperAdminDataPointProfiles from "../../../data/datapoint/superadmin/useSuperAdminDataPointProfiles";
import { DuplicateDataPointProfile } from "../../../components/DataPoint/DuplicateDataPointProfile";
import { EditDataPointProfile } from "../../../components/DataPoint/EditDataPointProfile";

export function DataPointProfileAddEditPage() {
  const { dataPointProfileId } = useParams();
  const { dataPointProfiles } = useSuperAdminDataPointProfiles();
  const [activeTab, setActiveTab] = useState(0);
  const [dataPointProfile, setDataPointProfile] = useState<
    IDataPointProfile | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataPointProfileId) {
      if (!dataPointProfiles.isLoading && dataPointProfiles.data) {
        setDataPointProfile(
          dataPointProfiles.data?.find(
            (profile) => profile.id === dataPointProfileId,
          ),
        );
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [dataPointProfileId, dataPointProfiles.isLoading, dataPointProfiles.data]);

  return loading ? (
    <SectionLoading />
  ) : (
    <Card
      title={
        dataPointProfile ? "Edit Data Point Profile" : "New Data Point Profile"
      }
    >
      {dataPointProfile ? (
        <div className="p-2">
          <EditDataPointProfile dataPointProfile={dataPointProfile} />
        </div>
      ) : (
        <>
          <div className="mt-6 flex space-x-4" aria-label="Tabs">
            {["Duplicate", "New"].map((tab, i) => (
              <div
                key={tab}
                className={clsx(
                  activeTab === i
                    ? "bg-primary/20 text-primary"
                    : "text-gray-500 hover:text-gray-700 dark:hover:text-gray-300",
                  "rounded-md px-3 py-2 text-sm font-medium hover:cursor-pointer",
                )}
                onClick={() => setActiveTab(i)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="p-2">
            {activeTab === 0 ? <DuplicateDataPointProfile /> : <div></div>}
          </div>
        </>
      )}
    </Card>
  );
}
