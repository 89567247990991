import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { IDataPoint } from "../../../types/DataPoint/DataPoint";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { CreateEditDataPoint } from "../../../components/DataPoint/CreateEditDataPoint";
import useSuperAdminDataPointMutations from "../../../data/datapoint/superadmin/useSuperAdminDataPointMutation";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";

const colHelper = createColumnHelper<IDataPoint>();

export function DataPointsPage() {
  const { delDataPoint, updateDataPoint } = useSuperAdminDataPointMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editDataPoint, setEditDataPoint] = useState<IDataPoint | undefined>(
    undefined,
  );
  const [deleteDataPoint, setDeleteDataPoint] = useState<
    IDataPoint | undefined
  >(undefined);
  const { isLoading, typeLookup } = useSuperAdminHardwareLookups();

  const handleDeleteDataPoint = (dataPoint: IDataPoint) => {
    setDeleteDataPoint(dataPoint);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteDataPoint = () => {
    delDataPoint.mutate(deleteDataPoint!);
    setDeleteOpen(false);
  };

  const handleRestoreDataPoint = (dataPoint: IDataPoint) => {
    updateDataPoint.mutate({ ...dataPoint, restore: true });
  };

  const handleEditDataPoint = (dataPoint: IDataPoint) => {
    setEditDataPoint(dataPoint);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editDataPoint && setTimeout(() => setEditDataPoint(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor("id", { header: "Id" }),
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor("name", { header: "Name" }),
    colHelper.accessor("unit", { header: "Unit" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreDataPoint(info.row.original)}
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditDataPoint(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteDataPoint(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditDataPoint
        open={editOpen}
        onClose={handleCreateEditClose}
        dataPoint={editDataPoint}
      />
      <ConfirmDelete
        itemName={deleteDataPoint?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteDataPoint()}
      />
      <Card title="Data Points">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Data Point</Button>
        </div>
        <div>
          <Table<IDataPoint>
            url="/superadmin/datapoint"
            queryKey={["superadmin", "datapoint"]}
            columns={cols}
            enableShowDeleted
            filterFields={[{ field: "name", fieldLabel: "Name" }]}
          />
        </div>
      </Card>
    </>
  );
}
