import { useState } from "react";

import clsx from "clsx";

import useDevice from "../../data/device/useDevice";
import { IHardware } from "../../types/Hardware/Hardware";
import { useLiveReading } from "../../contexts/LiveReadingContext";
import { DeviceStatusPopover } from "./DeviceStatusPopover";

interface DeviceStatusBarProps {
  hardware: IHardware;
}

export function DeviceStatusBar({ hardware }: DeviceStatusBarProps) {
  const { device } = useDevice(hardware.deviceId ?? "");
  const { latest, loading } = useLiveReading();
  const [open, setOpen] = useState(false);

  if (
    device.isLoading ||
    !device.data ||
    !device.data.length ||
    loading ||
    !latest ||
    latest.error == null
  ) {
    return <></>;
  } else {
    const state = device.data![0].device.cloud.connected
      ? latest!.error.code > 0
        ? "Error"
        : "Online"
      : "Offline";

    return (
      <div className="relative">
        <div className="flex flex-row justify-end md:justify-start items-center space-x-4">
          {state === "Error" ? (
            <>
              <p>{state}</p>
              <div
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                className={clsx(
                  "w-6 h-6 rounded-full",
                  {
                    Online: "bg-green-600",
                    Error: "bg-amber-600",
                    Offline: "bg-red-600",
                  }[state],
                )}
              />
              <DeviceStatusPopover
                open={open}
                connected={device.data![0].device.cloud.connected}
                error={latest.error}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
