import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotifierContextProvider } from "react-headless-notifier";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "react-query/devtools";

import routes from "./routes";
import appInfo from "../package.json";
import { UserContextProvider } from "./contexts/UserContext";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import { PageTitleContextProvider } from "./contexts/PageTitleContext";
import { ErrorFallback } from "./ErrorFallback";

console.log(`V${appInfo.version}`);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const notifierConfig = {
  max: 3,
  duration: 8000,
  position: "topRight",
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotifierContextProvider config={notifierConfig}>
        <CookiesProvider>
          <UserContextProvider>
            <ThemeContextProvider>
              <PageTitleContextProvider>
                <RouterProvider router={router} />
              </PageTitleContextProvider>
            </ThemeContextProvider>
          </UserContextProvider>
        </CookiesProvider>
      </NotifierContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
