import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Modal } from "../../layouts/Modal";
import { TextField } from "../base/TextField";
import { DataPointSelect } from "./DataPointSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import { IDataPointOption } from "../../types/DataPoint/DataPointOption";
import useSuperAdminDataPointOptionMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointOptionMutation";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import { GlobalHardwareModelFilterBanner } from "../shared/GlobalHardwareModelFilterBanner";
import { IHardwareModel } from "../../types/Hardware/Hardware";
import { Toggle } from "../base/Toggle";

export interface CreateEditDataPointOptionProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  dataPointOption?: IDataPointOption;
  prefill?: {
    hardwareModel: IHardwareModel;
    dataPointId: string;
  };
}

export function CreateEditDataPointOption({
  open,
  onClose,
  dataPointOption,
  prefill,
}: CreateEditDataPointOptionProps) {
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IDataPointOption>();
  const { hardwareTypes } = useHardwareTypes();
  const { createDataPointOption, updateDataPointOption } =
    useSuperAdminDataPointOptionMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [readOnly, setReadOnly] = useState(false);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createDataPointOption.mutate({
      ...data,
      readOnly: readOnly,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateDataPointOption.mutate({
      ...dataPointOption,
      ...data,
      readOnly: readOnly,
    });
    handleClose();
  };

  useEffect(() => {
    if (dataPointOption) {
      setReadOnly(dataPointOption.readOnly);
      reset({
        hardwareTypeId: dataPointOption.hardwareTypeId,
        hardwareManufacturerId: dataPointOption.hardwareManufacturerId,
        hardwareModelId: dataPointOption.hardwareModelId,
        dataPointId: dataPointOption.dataPointId,
        value: dataPointOption.value,
        label: dataPointOption.label,
        hardwareModel: dataPointOption.hardwareModel,
      });
    } else if (prefill) {
      reset({
        hardwareModel: prefill.hardwareModel,
        hardwareModelId: prefill.hardwareModel.id,
        dataPointId: prefill.dataPointId,
      });
    } else if (globalHardwareModelFilter) {
      setReadOnly(false);
      reset({
        hardwareModelId: globalHardwareModelFilter.id,
        hardwareManufacturerId:
          globalHardwareModelFilter.hardwareManufacturerId,
        hardwareTypeId: globalHardwareModelFilter.hardwareTypeId,
      });
    } else {
      setReadOnly(false);
      reset({});
    }
  }, [
    reset,
    open,
    dataPointOption,
    prefill,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${dataPointOption ? "Edit" : "Create"} Data Point Option`}
      description={`Use this form to ${
        dataPointOption ? "edit" : "create"
      } a data point option`}
    >
      <GlobalHardwareModelFilterBanner />
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(dataPointOption ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("dataPointId");
              },
            })}
            type={watch("hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("dataPointId");
              },
            })}
            manufacturer={watch("hardwareManufacturerId")}
          />
          <DataPointSelect
            {...register("dataPointId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={dataPointOption?.dataPointId || prefill?.dataPointId}
          />
          <TextField {...register("value")} label="Value" />
          <TextField {...register("label")} label="Label" />

          <div className="mt-6 flex sm:col-span-2 justify-between">
            <div className="space-x-4">
              <Button type="submit">
                {dataPointOption ? "Update" : "Create"}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <Toggle
              label="Read Only"
              checked={readOnly}
              onChange={setReadOnly}
            />
          </div>
        </form>
      )}
    </Modal>
  );
}
