import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { useActionLookups } from "../../../hooks/useActionLookups";
import { actionTransformationQueryKeys } from "../../../data/QueryKeys";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { useDataPointLookups } from "../../../hooks/useDataPointLookups";
import { IActionTransformation } from "../../../types/Action/ActionTransformation";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import { CreateEditActionTransformation } from "../../../components/Action/CreateEditActionTransformation";
import useSuperAdminActionTransformationMutations from "../../../data/action/useSuperAdminActionTransformationMutation";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";
import { SectionLoading } from "../../../components/shared/SectionLoading";

const colHelper = createColumnHelper<IActionTransformation>();

export function ActionTransformationsPage() {
  const { delActionTransformation, updateActionTransformation } =
    useSuperAdminActionTransformationMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editActionTransformation, setEditActionTransformation] = useState<
    IActionTransformation | undefined
  >(undefined);
  const [deleteActionTransformation, setDeleteActionTransformation] = useState<
    IActionTransformation | undefined
  >(undefined);
  const {
    isLoading: isHardwareLookupLoading,
    manufacturerLookup,
    modelLookup,
    typeLookup,
  } = useSuperAdminHardwareLookups();
  const { isLoading: isActionLookupLoading, actionLookup } = useActionLookups();
  const { isLoading: isDataPointLookupLoading, functionsLookup } =
    useDataPointLookups();

  const handleDeleteActionTransformation = (
    actionTransformation: IActionTransformation,
  ) => {
    setDeleteActionTransformation(actionTransformation);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteActionTransformation = () => {
    delActionTransformation.mutate(deleteActionTransformation!);
    setDeleteOpen(false);
  };

  const handleRestoreActionTransformation = (
    actionTransformation: IActionTransformation,
  ) => {
    updateActionTransformation.mutate({
      ...actionTransformation,
      restore: true,
    });
  };

  const handleEditActionTransformation = (
    actionTransformation: IActionTransformation,
  ) => {
    setEditActionTransformation(actionTransformation);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editActionTransformation &&
      setTimeout(() => setEditActionTransformation(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" },
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => actionLookup(row.actionId)?.name, {
      header: "Action",
    }),
    colHelper.accessor((row) => functionsLookup(row.functionId)?.name, {
      header: "Function",
    }),
    colHelper.accessor("arguments", {
      header: "Arguments",
      cell: (info) =>
        info.getValue() === null ? (
          <span className="opacity-70 dark:text-white italic">null</span>
        ) : (
          info.getValue()
        ),
    }),
    colHelper.accessor("order", { header: "Order" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() =>
                handleRestoreActionTransformation(info.row.original)
              }
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleEditActionTransformation(info.row.original)
                }
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleDeleteActionTransformation(info.row.original)
                }
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditActionTransformation
        open={editOpen}
        onClose={handleCreateEditClose}
        actionTransformation={editActionTransformation}
      />
      <ConfirmDelete
        itemName={"Action Transformation"}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteActionTransformation()}
      />
      <Card title="Action Transformations">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>
            Add Action Transformation
          </Button>
        </div>
        {isHardwareLookupLoading ||
        isActionLookupLoading ||
        isDataPointLookupLoading ? (
          <SectionLoading />
        ) : (
          <div>
            <Table<IActionTransformation>
              url="/superadmin/action/transformation"
              queryKey={actionTransformationQueryKeys.superAdminAll()}
              columns={cols}
              predefinedFilterFields={
                globalHardwareModelFilter
                  ? [
                      {
                        field: "hardwareModelId",
                        value: globalHardwareModelFilter.id,
                        fieldLabel: "Hardware Model",
                        valueLabel: globalHardwareModelFilter.name,
                      },
                    ]
                  : undefined
              }
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
