import { useState } from "react";
import { Button } from "../base/Button";
import { TariffUpdate } from "./Tariff/TariffUpdate";
import useOrganisationTariffs from "./../../data/organisation/useOrganisationTariffs";
import { LoadingWheel } from "../base/LoadingWheel";
import { IOrganisationTariff } from "../../types/Organisation/Organisation";
import { FieldName } from "react-hook-form";

export function TariffTab() {
  const { tariffs } = useOrganisationTariffs();
  const [tariffUpdateOpen, setTariffUpdateOpen] = useState(false);
  const [tariffField, setTariffField] =
    useState<FieldName<IOrganisationTariff>>();

  const handleTariffUpdate = (fieldName: FieldName<IOrganisationTariff>) => {
    setTariffField(fieldName);
    setTariffUpdateOpen(true);
  };

  return (
    <>
      <TariffUpdate
        open={tariffUpdateOpen}
        onClose={() => setTariffUpdateOpen(false)}
        fieldName={tariffField!}
        currentTariffs={
          tariffs.data && tariffs.data?.length > 0 ? tariffs.data[0] : undefined
        }
      />
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-2xl font-semibold leading-7 text-primary">
            Tariffs
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
            View and edit your tariff information.
          </p>
          {tariffs.isLoading ? (
            <LoadingWheel />
          ) : (
            <dl className="mt-6 space-y-6 divide-y divide-gray-300 border-t border-gray-400 text-sm leading-6 dark:divide-gray-600 dark:border-gray-500">
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Electricity Consumption £/kWh
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200">
                    {tariffs.data?.length && tariffs.data.length > 0 ? (
                      `£${tariffs.data[0].electricityImport}/kWh`
                    ) : (
                      <span className="italic">Not set</span>
                    )}
                  </div>
                  <Button
                    size="sm"
                    className="font-semibold"
                    onClick={() => handleTariffUpdate("electricityImport")}
                  >
                    Update
                  </Button>
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    </>
  );
}
