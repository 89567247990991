import React from "react";

import { LoadingWheel } from "../base/LoadingWheel";
import { Select } from "../base/Select";

import useHardwareModels from "../../data/hardware/useHardwareModel";

export interface ModelSelectProps {
  manufacturer: string;
  label?: string;
}

export const ModelSelect = React.forwardRef<any, ModelSelectProps>(
  ({ manufacturer, label, ...rest }, ref) => {
    const { hardwareModels } = useHardwareModels(manufacturer);

    if (hardwareModels.isLoading) {
      return (
        <div className="flex items-center justify-center">
          <LoadingWheel size="sm" />
        </div>
      );
    } else {
      return (
        <Select
          disabled={!manufacturer}
          ref={ref}
          label={label ? label : "Model"}
          {...rest}
        >
          <option disabled selected hidden></option>
          {hardwareModels.data?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </Select>
      );
    }
  },
);
