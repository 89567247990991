import { useQuery } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";
import { IDataPointOption } from "../../../types/DataPoint/DataPointOption";
import { IDefaultResponseWithData } from "../../../types/system/DefaultResponse";

export default function useSuperAdminDataPointOptions(
  dataPointProfileId?: string,
) {
  const { get } = useApiHelper();

  const dataPointOptions = useQuery({
    queryKey: ["superadmin", "datapoint", "options", dataPointProfileId],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPointOption>>(
        `/v1/superadmin/datapoint/option${
          dataPointProfileId ? `/${dataPointProfileId}` : ""
        }`,
      ).then((res) => res.data.data),
  });

  return {
    dataPointOptions,
  };
}
