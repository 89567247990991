import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";

import { Toggle } from "../base/Toggle";
import { IUser } from "../../types/User/User";
import useOrganisationUsersMutations from "../../data/organisationUsers/useOrganisationUsersMutations";
import useOrganisation from "../../data/organisation/useOrganisation";
import useOrganisationInvitesMutations from "../../data/organisationUsers/useOrganisationInvitesMutations copy";
import useOrganisationUsers from "../../data/organisationUsers/useOrganisationUsers";

export interface CreateEditUserProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  user?: IUser;
  invite?: boolean;
}

export function CreateEditUser({
  open,
  onClose,
  user,
  invite = false,
}: CreateEditUserProps) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const { users } = useOrganisationUsers();
  const { createUser, updateUser } = useOrganisationUsersMutations();
  const { updateInvite } = useOrganisationInvitesMutations();
  const { organisation } = useOrganisation();
  const [admin, setAdmin] = useState(users.data?.length ? false : true);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => {
      reset({});
      setAdmin(false);
    }, 200);
  };

  const handleCreate = (data: any) => {
    setValue("email", data.email.toLowerCase());

    createUser.mutate({
      ...data,
      email: data.email.toLowerCase(),
      forename: data.forename.trim(),
      surnamae: data.surname.trim(),
      roleId: admin
        ? organisation.data?.adminRole
        : organisation.data?.readRole,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    let body = {
      ...user,
      ...data,
      roleId: admin
        ? organisation.data?.adminRole
        : organisation.data?.readRole,
    };

    if (invite) {
      updateInvite.mutate(body);
    } else {
      updateUser.mutate(body);
    }
    handleClose();
  };

  useEffect(() => {
    setAdmin(users.data?.length === 0 ? true : false);
  }, [open]);

  useEffect(() => {
    if (user) {
      setAdmin(user.roleId === organisation.data?.adminRole);
      reset({
        forename: user.forename,
        surname: user.surname,
        email: user.email,
      });
    } else {
      reset({});
    }
  }, [reset, user]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${user ? "Edit" : "Invite"} User`}
      description={`Use this form to ${user ? "edit" : "invite"} a user`}
    >
      <form
        onSubmit={handleSubmit(user ? handleUpdate : handleCreate)}
        className="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-6 sm:gap-y-4"
      >
        {organisation.isLoading || users.isLoading ? (
          <SectionLoading />
        ) : (
          <>
            <TextField
              {...register("forename")}
              label="Forename"
              disabled={user !== undefined}
            />
            <TextField
              {...register("surname")}
              label="Surname"
              disabled={user !== undefined}
            />
            <TextField
              {...register("email")}
              label="Email Address"
              disabled={user !== undefined}
            />

            <div className="flex items-center">
              <Toggle
                label="Make Admin"
                className="mt-4"
                checked={admin}
                onChange={setAdmin}
              />
            </div>

            <div className="mt-6 sm:col-span-2 space-x-4">
              <Button type="submit">{user ? "Update" : "Invite"}</Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
}
