import { useEffect } from "react";

import { Statistics } from "../../components/History/Statistics";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { GraphSettings } from "../../components/History/GraphSettings";
import { GraphSettingsContextProvider } from "../../contexts/GraphSettingsContext";
import useUserConfigurations from "../../data/user/useUserConfigurations";
import { LoadingWheel } from "../../components/base/LoadingWheel";
import { ConfigurationType } from "../../types/UserConfiguration/UserConfiguration";
import { useUserContext } from "../../contexts/UserContext";
import { Card } from "../../components/base/Card";

export function HistoryPage() {
  const { setPageTitle } = usePageTitle();
  const { organisationId, loading } = useUserContext();
  const { userConfigurations } = useUserConfigurations(1);

  useEffect(() => {
    setPageTitle("Historical Readings");
  }, [setPageTitle]);

  useEffect(() => {}, [userConfigurations.data]);

  if (userConfigurations.isLoading || loading) {
    return (
      <Card className="flex items-center justify-center">
        <LoadingWheel className="my-8" />
      </Card>
    );
  } else {
    return (
      <GraphSettingsContextProvider>
        <GraphSettings />
        <Statistics
          userConfiguration={
            userConfigurations.data && userConfigurations.data!.length > 0
              ? userConfigurations.data![0]
              : {
                  type: ConfigurationType.statistic,
                  ownerId: organisationId!,
                  configuration: [],
                }
          }
        />
      </GraphSettingsContextProvider>
    );
  }
}
