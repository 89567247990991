import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Modal } from "../../layouts/Modal";
import { TextField } from "../base/TextField";
import { ActionSelect } from "./ActionSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import useDataPointFunctions from "../../data/datapoint/useDataPointFunctions";
import { IActionTransformation } from "../../types/Action/ActionTransformation";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import { GlobalHardwareModelFilterBanner } from "../shared/GlobalHardwareModelFilterBanner";
import useSuperAdminActionTransformationMutations from "../../data/action/useSuperAdminActionTransformationMutation";

export interface CreateEditActionTransformationProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  actionTransformation?: IActionTransformation;
}

export function CreateEditActionTransformation({
  open,
  onClose,
  actionTransformation,
}: CreateEditActionTransformationProps) {
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IActionTransformation>();
  const { hardwareTypes } = useHardwareTypes();
  const { dataPointFunctions } = useDataPointFunctions();
  const { createActionTransformation, updateActionTransformation } =
    useSuperAdminActionTransformationMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [backend, setBackend] = useState(true);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createActionTransformation.mutate({
      ...data,
      backend: backend,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateActionTransformation.mutate({
      ...actionTransformation,
      ...data,
      backend: backend,
    });
    handleClose();
  };

  useEffect(() => {
    if (actionTransformation) {
      reset({
        hardwareTypeId: actionTransformation.hardwareTypeId,
        hardwareManufacturerId: actionTransformation.hardwareManufacturerId,
        hardwareModelId: actionTransformation.hardwareModelId,
        actionId: actionTransformation.actionId,
        arguments: actionTransformation.arguments,
        order: actionTransformation.order,
        hardwareModel: actionTransformation.hardwareModel,
      });
      setBackend(actionTransformation.backend);
    } else if (globalHardwareModelFilter) {
      reset({
        hardwareModelId: globalHardwareModelFilter.id,
        hardwareManufacturerId:
          globalHardwareModelFilter.hardwareManufacturerId,
        hardwareTypeId: globalHardwareModelFilter.hardwareTypeId,
        order: 0,
      });
    } else {
      reset({ order: 0 });
    }
  }, [
    open,
    reset,
    actionTransformation,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${
        actionTransformation ? "Edit" : "Create"
      } Action Transformation`}
      description={`Use this form to ${
        actionTransformation ? "edit" : "create"
      } an action transformation`}
    >
      <GlobalHardwareModelFilterBanner />
      {hardwareTypes.isLoading || dataPointFunctions.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(
            actionTransformation ? handleUpdate : handleCreate,
          )}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("actionId");
              },
            })}
            type={watch("hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("actionId");
              },
            })}
            manufacturer={watch("hardwareManufacturerId")}
          />
          <ActionSelect
            {...register("actionId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={actionTransformation?.actionId}
          />
          <Select
            {...register("functionId", {
              value: actionTransformation
                ? actionTransformation.functionId
                : "",
            })}
            label="Function"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {dataPointFunctions.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <TextField {...register("arguments")} label="Arguments" />
          <TextField {...register("order")} label="Order" />
          <div className="flex items-center">
            <Toggle
              className="mt-1"
              label="Backend only"
              checked={backend}
              onChange={(val) => setBackend(val)}
            />
          </div>
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">
              {actionTransformation ? "Update" : "Create"}
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
