import { useQuery } from "react-query";

import { IUser } from "../../types/User/User";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useUser() {
  const { get } = useApiHelper();

  const user = useQuery({
    queryKey: ["user"],
    queryFn: () =>
      get<IDefaultResponseWithData<IUser>>("/v1/user").then(
        (res) => res.data.data[0],
      ),
  });

  return {
    user,
  };
}
