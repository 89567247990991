import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IHardwareSelection } from "../../types/Hardware/Hardware";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useSuperAdminHardwareModels() {
  const { get } = useApiHelper();

  const hardwareModels = useQuery({
    queryKey: ["superadmin", "hardware", "models"],
    queryFn: () =>
      get<IDefaultResponseWithData<IHardwareSelection>>(
        `/v1/superadmin/hardware/models`,
      ).then((res) => res.data.data),
  });

  return {
    hardwareModels,
  };
}
