import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

import { IActionProfile } from "../../types/Action/ActionProfile";
import { actionProfileQueryKeys } from "../QueryKeys";

export default function useSuperAdminActionProfiles(hardwareModelId: string) {
  const { get } = useApiHelper();

  const actionProfiles = useQuery({
    queryKey: actionProfileQueryKeys.superAdminByModel(hardwareModelId),
    queryFn: () =>
      get<IDefaultResponseWithData<IActionProfile>>(
        `/v1/superadmin/action/profile/${hardwareModelId}`,
      ).then((res) => res.data.data),
  });

  return {
    actionProfiles,
  };
}
