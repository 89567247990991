import { XMarkIcon } from "@heroicons/react/24/outline";
import { useGlobalHardwareModelFilter } from "./../../contexts/GlobalHardwareFilterContext";

export function GlobalHardwareModelFilterBanner() {
  const { globalHardwareModelFilter, setGlobalHardwareModelFilter } =
    useGlobalHardwareModelFilter();

  if (globalHardwareModelFilter) {
    return (
      <div className="px-4 py-3 rounded border flex justify-between items-center">
        <span>
          Filtering for {globalHardwareModelFilter?.hardwareManufacturer.name}{" "}
          {globalHardwareModelFilter?.name}
        </span>
        <XMarkIcon
          className="w-6 h-6"
          onClick={() => setGlobalHardwareModelFilter({ type: "reset" })}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
