import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Modal } from "../../layouts/Modal";
import { TextField } from "../base/TextField";
import { SectionLoading } from "../shared/SectionLoading";
import { IDataPoint } from "../../types/DataPoint/DataPoint";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import useSuperAdminDataPointMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointMutation";
import { arch } from "os";

export interface CreateEditDataPointProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  dataPoint?: IDataPoint;
}

export function CreateEditDataPoint({
  open,
  onClose,
  dataPoint,
}: CreateEditDataPointProps) {
  const { register, reset, handleSubmit } = useForm<IDataPoint>({
    defaultValues: {
      icon: "fas,",
    },
  });
  const { hardwareTypes } = useHardwareTypes();
  const { createDataPoint, updateDataPoint } =
    useSuperAdminDataPointMutations();
  const [customerEnable, setCustomerEnable] = useState(true);
  const [installerEnable, setInstallerEnable] = useState(true);
  const [graphEnable, setGraphEnable] = useState(false);
  const [archiveEnable, setArchiveEnable] = useState(false);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createDataPoint.mutate({
      ...data,
      customerEnable,
      installerEnable,
      graphEnable,
      archiveEnable,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateDataPoint.mutate({
      ...dataPoint,
      ...data,
      customerEnable,
      installerEnable,
      graphEnable,
      archiveEnable,
    });
    handleClose();
  };

  useEffect(() => {
    if (dataPoint) {
      reset({
        name: dataPoint.name,
        unit: dataPoint.unit,
        icon: dataPoint.icon,
        hardwareTypeId: dataPoint.hardwareTypeId,
      });
      setCustomerEnable(dataPoint.customerEnable);
      setInstallerEnable(dataPoint.installerEnable);
      setGraphEnable(dataPoint.graphEnable);
      setArchiveEnable(dataPoint.archiveEnable);
    }
  }, [reset, dataPoint, hardwareTypes.data, hardwareTypes.isLoading]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${dataPoint ? "Edit" : "Create"} Data Point`}
      description={`Use this form to ${
        dataPoint ? "edit" : "create"
      } a data point`}
    >
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(dataPoint ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <TextField {...register("name", { required: true })} label="Name" />
          <TextField {...register("unit")} label="Unit" />
          <TextField {...register("icon")} label="Icon" />
          <div className="pt-5 flex flex-col row-span-2 space-y-3">
            <Toggle
              label="Enable Graph"
              checked={graphEnable}
              onChange={setGraphEnable}
            />
            <Toggle
              label="Enable Archive"
              checked={archiveEnable}
              onChange={setArchiveEnable}
            />
            <Toggle
              label="Visable to Customer"
              checked={customerEnable}
              onChange={setCustomerEnable}
            />
            <Toggle
              label="Visable to Installer"
              checked={installerEnable}
              onChange={setInstallerEnable}
            />
          </div>
          <Select {...register("hardwareTypeId")} label="Hardware Type">
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {`${type.name}`}
              </option>
            ))}
          </Select>
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">{dataPoint ? "Update" : "Create"}</Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
