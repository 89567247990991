import { Cookies } from "react-cookie";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";

import Queue from "../utils/queue";
import { userLsKey } from "../constants/common";
import { useTheme } from "../contexts/ThemeContext";
import { useUserContext } from "../contexts/UserContext";
import { authAPIURL, cookieSettings, resourceAPIURL } from "../config";

export let isRefreshing = false;

export interface ApiHelperConfig {
  apiUrl?: string;
  headers?: HeadersInit;
  options?: object;
  withCredentials?: boolean;
  addToken?: boolean;
}

export interface ApiHelperFunctionConfig
  extends Omit<ApiHelperConfig, "apiUrl"> {}

export class APIAuthError extends Error {
  retry: boolean;

  constructor(retry: boolean, message: string) {
    super(message);
    this.retry = retry;
  }
}

interface APIHeaders extends AxiosRequestHeaders {
  Authorization: string;
}

export default function useApiHelper(
  config: ApiHelperConfig = {
    apiUrl: undefined,
    headers: {},
    options: {},
    withCredentials: false,
    addToken: true,
  },
) {
  const { installerId, superAdminId, setUser } = useUserContext();
  const { setTheme } = useTheme();
  const cookies = new Cookies(undefined, cookieSettings);

  const client = axios.create();
  client.defaults.baseURL = config.apiUrl ? config.apiUrl : resourceAPIURL;
  client.defaults.withCredentials = config.withCredentials;
  client.defaults.headers.common = {
    "Content-Type": "application/json",
    ...config.headers,
  } as APIHeaders;

  client.interceptors.request.use(
    (cfg) => {
      if (config.addToken) {
        // let userState = localStorage.getItem(userLsKey);
        let userJson = cookies.get(userLsKey);
        // let userJson = JSON.parse(userState!);
        cfg.headers!["Authorization"] = `Bearer ${userJson.token}`;
      }

      if (installerId) {
        cfg.headers!["X-Installer-Id"] = `${installerId}`;
      }

      if (superAdminId) {
        cfg.headers!["X-SuperAdmin-Id"] = `${superAdminId}`;
      }

      return cfg;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  if (config.addToken) {
    client.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            if (isRefreshing) {
              return new Promise(function (resolve, reject) {
                Queue.enqueue({ resolve, reject });
              })
                .then((token) => {
                  originalConfig.headers["Authorization"] = "Bearer " + token;
                  return axios(originalConfig);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }

            isRefreshing = true;

            return new Promise(function (resolve, reject) {
              // let userState = localStorage.getItem(userLsKey);
              // let userJson = JSON.parse(userState!);
              let userJson = cookies.get(userLsKey);
              axios
                .post(
                  `${authAPIURL}/v1/sso/token`,
                  {
                    organisationId: userJson?.organisationId ?? "",
                    type: "portal",
                  },
                  {
                    withCredentials: true,
                  },
                )
                .then(({ data }) => {
                  // localStorage.setItem(
                  //   userLsKey,
                  //   JSON.stringify({ ...userJson, token: data.token! })
                  // );

                  cookies.set(userLsKey, { ...userJson, token: data.token });

                  setUser({
                    type: "setToken",
                    payload: { token: data.token! },
                  });

                  isRefreshing = false;

                  originalConfig.headers["Authorization"] =
                    "Bearer " + data.token;

                  Queue.dequeue(null, data.token);

                  resolve(axios(originalConfig));
                })
                .catch((err) => {
                  console.error("Token refresh error - logging out", err);
                  setUser({ type: "logout" });
                  setTheme({ type: "loading", payload: false });
                  reject(err);
                  Queue.dequeue(err, null);
                })
                .then(() => {
                  isRefreshing = false;
                });
            }).catch(() => {
              cookies.remove(userLsKey);
            });
          }

          return Promise.reject(err);
        }

        return Promise.reject(err);
      },
    );
  }

  function get<T>(path: string, config?: AxiosRequestConfig) {
    return client.get<T>(path, config);
  }

  function put<T = any, U = any>(
    path: string,
    body: T,
    config?: AxiosRequestConfig,
  ) {
    return client.put<T, AxiosResponse<U>>(path, body, config);
  }

  function patch<T>(path: string, body: T, config?: AxiosRequestConfig) {
    return client.patch(path, body, config);
  }

  function post<T = any, U = any>(
    path: string,
    body: T,
    config?: AxiosRequestConfig,
  ) {
    return client.post<T, AxiosResponse<U>>(path, body, config);
  }

  function del(path: string, config?: AxiosRequestConfig) {
    return client.delete(path, config);
  }

  return {
    get,
    put,
    patch,
    post,
    del,
  };
}
