import { ITimeInterval } from "@amcharts/amcharts5/.internal/core/util/Time";
import { Duration } from "luxon";

export interface GranularityCondition {
  maxHours?: number;
  minHours?: number;
  granularities: GranularityOption[];
}

type GranularityOption =
  | "5 Seconds"
  | "Hourly"
  | "Daily"
  | "Weekly"
  | "Monthly"
  | "Yearly";

export const GranularityIndex: { [key in GranularityOption]: number } = {
  "5 Seconds": 0,
  Hourly: 3,
  Daily: 4,
  Weekly: 5,
  Monthly: 6,
  Yearly: 7,
};

export const GraphBaseIntervalGranularity: {
  [key in GranularityOption]: ITimeInterval;
} = {
  "5 Seconds": { timeUnit: "second", count: 4 },
  Hourly: { timeUnit: "hour", count: 1 },
  Daily: { timeUnit: "day", count: 1 },
  Weekly: { timeUnit: "week", count: 1 },
  Monthly: { timeUnit: "month", count: 1 },
  Yearly: { timeUnit: "year", count: 1 },
};

export const GranularityDuration: { [key in GranularityOption]: Duration } = {
  "5 Seconds": Duration.fromObject({ second: 5 }),
  Hourly: Duration.fromObject({ hour: 1 }),
  Daily: Duration.fromObject({ day: 1 }),
  Weekly: Duration.fromObject({ week: 1 }),
  Monthly: Duration.fromObject({ month: 1 }),
  Yearly: Duration.fromObject({ year: 1 }),
};

export default GranularityOption;
