import { IHardwareModel } from "../Hardware/Hardware";
import { IBaseModel } from "../system/BaseModel";
import { IAction } from "./Action";

export interface IActionProfile extends IBaseModel {
  hardwareTypeId: string;
  hardwareManufacturerId: string;
  hardwareModelId: string;
  hardwareModel: IHardwareModel;
  command: string;
  responseLength: number;
  responseTimeout: number;
  actionId: string;
  dataPointId: string;
  inputType: InputType;
  step: number;
  restore?: boolean;
}

export enum InputType {
  None,
  Toggle,
  Select,
  Value,
}
