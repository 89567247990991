import { useMutation, useQueryClient } from "react-query";
import useApiHelper from "../../hooks/useApiHelper";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import {
  ICustomer,
  ICustomerCreateRequest,
  ICustomerDeleteRequest,
  ICustomerUpdateRequest,
} from "../../types/Customer/Customer";

export function useCustomerMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Customer");

  const createCustomer = useMutation({
    mutationFn: (customer: ICustomerCreateRequest) =>
      post<ICustomerCreateRequest, ICustomer>(
        "/v1/installer/customers",
        customer,
      ),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["installer", "customers"]);
    },
  });

  const updateCustomer = useMutation({
    mutationFn: (customer: ICustomerUpdateRequest) =>
      put<ICustomerUpdateRequest, ICustomer>(
        `/v1/installer/customers/${customer.id}`,
        customer,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["installer", "customers"]);
    },
  });

  const createInstaller = useMutation({
    mutationFn: (installer: ICustomerCreateRequest) =>
      post("/v1/superadmin/installer", installer),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["superadmin", "installers"]);
    },
  });

  const deleteCustomer = useMutation({
    mutationFn: (customer: ICustomerDeleteRequest) =>
      del(`/v1/installer/customers/${customer.id}`),
    // Removed as handled in modal
    // onError: (error) => handleError(error, "unlink"),
    onSuccess: (data) => handleSuccess(data, "unlinke"),
    onSettled: () => {
      queryClient.invalidateQueries(["installer", "customers"]);
    },
  });

  return {
    createCustomer,
    updateCustomer,
    createInstaller,
    deleteCustomer,
  };
}
