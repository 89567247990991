import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Alert } from "../../base/Alert";
import { Button } from "../../base/Button";
import { authAPIURL } from "../../../config";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { TextField } from "../../base/TextField";
import useApiHelper from "../../../hooks/useApiHelper";
import { SectionLoading } from "../../shared/SectionLoading";
import { MFAEnableResponse } from "../../../types/responses/mfa/MFAEnableResponse";
import { MFARecoveryCodesResponse } from "../../../types/responses/mfa/MFARecoveryCodesResponse";
import { useNotification } from "../../../hooks/useNotifications";
import useUser from "../../../data/user/useUser";
import { futimesSync } from "fs";

interface StepButtonsProps {
  handleNext: () => void;
  handleBack: () => void;
  handleCancel: () => void;
  step: number;
}

function StepButtons({
  handleNext,
  handleBack,
  handleCancel,
  step,
}: StepButtonsProps) {
  return (
    <div className="mt-6 flex justify-between">
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
      <div className="space-x-2">
        <Button disabled={step === 0} onClick={handleBack}>
          Back
        </Button>
        <Button onClick={handleNext}>{step === 2 ? "Finish" : "Next"}</Button>
      </div>
    </div>
  );
}

function RecoveryCodes(buttonProps: StepButtonsProps) {
  const { get } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
  });
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  let running = false;

  const handleCopyCodes = async () =>
    await navigator.clipboard.writeText(recoveryCodes!.join("\n"));

  const handleDownloadCodes = () => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(recoveryCodes!.join("\n")),
    );
    element.setAttribute("download", "planet-devices-recovery-codes.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const getRecoveryCodes = async () => {
    try {
      const codesResponse =
        await get<MFARecoveryCodesResponse>("/v1/mfa/recover");

      if (codesResponse.status === 200) {
        setRecoveryCodes(codesResponse.data.codes);
      } else {
        throw Error("Error getting MFA details");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!running) {
      running = true;
      getRecoveryCodes();
    }
  }, []);

  return loading ? (
    <SectionLoading />
  ) : error ? (
    <div className="mt-6">
      <Alert
        severity="error"
        title="Error"
        message="Failed to get multi-factor activation details, please try again"
      />
    </div>
  ) : (
    <div>
      <p>
        Please take a copy of these codes and keep them safe, they will allow
        you to gain access to your account in the event of an authenticator not
        being available
      </p>
      <div className="w-1/2 grid lg:grid-cols-2 mx-auto mt-10">
        {recoveryCodes?.map((code) => (
          <div className="py-1.5 px-3 m-0.5 border text-center rounded bg-gray-600 border-gray-800 font-mono">
            {code}
          </div>
        ))}
      </div>
      <div className="mt-10 flex justify-center">
        <div className="space-x-2">
          <Button onClick={handleCopyCodes}>Copy to Clipboard</Button>
          <Button onClick={handleDownloadCodes}>Download</Button>
        </div>
      </div>

      <StepButtons {...buttonProps} />
    </div>
  );
}

function VerifyOTP(buttonProps: StepButtonsProps) {
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
  });
  const [otp, setOtp] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otpIncorrect, setOtpIncorrect] = useState(false);

  const verifyOTP = async () => {
    setLoading(true);
    try {
      const verifyResponse = await post("/v1/mfa/verify", { code: otp });

      if (verifyResponse.status === 200) {
        buttonProps.handleNext();
      } else {
        throw Error("Error getting MFA details");
      }
    } catch (e: any) {
      if (e.response.status && e.response.status === 400) {
        setOtpIncorrect(true);
      } else {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <SectionLoading />
  ) : error ? (
    <div className="mt-6">
      <Alert
        severity="error"
        title="Error"
        message="Failed to get multi-factor activation details, please try again"
      />
    </div>
  ) : (
    <div>
      <p>
        Please enter the 6 digit code shown on your app to verify the setup has
        been successful
      </p>
      {otpIncorrect && (
        <Alert
          className="mt-6"
          severity="warn"
          title="Verification Failed"
          message="Failed to verify OTP, please try again"
        />
      )}
      <div className="mt-4 mx-auto w-32 space-y-2">
        <TextField
          label="OTP"
          onChange={(e) => setOtp(e.currentTarget.value)}
        />
      </div>
      <StepButtons {...buttonProps} handleNext={verifyOTP} />
    </div>
  );
}

function QRDisplay(buttonProps: StepButtonsProps) {
  const { get } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
  });
  const [loading, setLoading] = useState(true);
  const [qrCode, setQRCode] = useState<string>();
  const [manualCode, setManualCode] = useState<string>();
  const [error, setError] = useState(false);
  let running = false;

  const getQRCode = async () => {
    try {
      const enableResponse = await get<MFAEnableResponse>("/v1/mfa/enable");

      if (enableResponse.status === 200) {
        setQRCode(enableResponse.data.qrCode);
        setManualCode(enableResponse.data.manual);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!qrCode && !running) {
      running = true;
      getQRCode();
    }
  }, []);

  return loading ? (
    <SectionLoading />
  ) : error ? (
    <div className="mt-6">
      <Alert
        severity="error"
        title="Error"
        message="Failed to get multi-factor activation details, please try again"
      />
    </div>
  ) : (
    <div>
      <p>
        Please scan the below QR code with your preferred authenticator app,
        alternatively manually enter the code shown below the QR code
      </p>
      <img alt="QR Code" src={qrCode} className="mx-auto mt-10 w-44 h-48" />
      <div className="w-3/6 mx-auto mt-4 p-4 rounded bg-gray-300 dark:bg-white/10">
        <p className="text-center font-mono">{manualCode}</p>
      </div>
      <StepButtons {...buttonProps} />
    </div>
  );
}

export function MFAEnable({ open, onClose }: ModalControlProps) {
  const { user } = useUser();
  const [step, setStep] = useState(0);
  const addNotification = useNotification();

  const handleCancel = () => {
    setStep(0);
    onClose(true);
  };

  const handleNext = () => {
    if (step === 2) {
      user.refetch();
      addNotification(
        "success",
        "Successfully enabled multi-factor authentication",
      );
      handleCancel();
    } else {
      setStep((step) => step + 1);
    }
  };

  const handleBack = () => setStep((step) => step - 1);

  const baseStepProps: StepButtonsProps = {
    handleCancel: handleCancel,
    handleBack: handleBack,
    handleNext: handleNext,
    step: step,
  };

  const getStep = () => {
    switch (step) {
      case 2:
        return <RecoveryCodes {...baseStepProps} />;
      case 1:
        return <VerifyOTP {...baseStepProps} />;
      case 0:
      default:
        return <QRDisplay {...baseStepProps} />;
    }
  };

  return (
    <Modal title="Multi-Factor Setup" open={open} onClose={onClose}>
      {getStep()}
    </Modal>
  );
}
