import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IHardwareModel } from "../../types/Hardware/Hardware";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useHardwareModelsMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware Model");

  const createHardwareModel = useMutation({
    mutationFn: (hardwareModel) =>
      post("/v1/superadmin/hardware/models", hardwareModel),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "models"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "models"]);
    },
  });

  const updateHardwareModel = useMutation({
    mutationFn: (hardwareModel: IHardwareModel) =>
      put<IHardwareModel>(
        `/v1/superadmin/hardware/models/${hardwareModel.id}`,
        hardwareModel,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "models"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "models"]);
    },
  });

  const delHardwareModel = useMutation({
    mutationFn: (hardwareModel: IHardwareModel) =>
      del(`/v1/superadmin/hardware/models/${hardwareModel.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "models"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "models"]);
    },
  });

  return {
    createHardwareModel,
    updateHardwareModel,
    delHardwareModel,
  };
}
