import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

import { IHardwareType } from "../../types/Hardware/Hardware";

export default function useHardwareTypeMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware Type");

  const createHardwareType = useMutation({
    mutationFn: (hardwareType) =>
      post("/v1/superadmin/hardware/types", hardwareType),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "types"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "types"]);
    },
  });

  const updateHardwareType = useMutation({
    mutationFn: (hardwareType: IHardwareType) =>
      put<IHardwareType>(
        `/v1/superadmin/hardware/types/${hardwareType.id}`,
        hardwareType,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "types"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "types"]);
    },
  });

  const delHardwareType = useMutation({
    mutationFn: (hardwareType: IHardwareType) =>
      del(`/v1/superadmin/hardware/types/${hardwareType.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "types"]);
      queryClient.invalidateQueries(["superadmin", "hardware", "types"]);
    },
  });

  return {
    createHardwareType,
    updateHardwareType,
    delHardwareType,
  };
}
