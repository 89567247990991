import { useState } from "react";

import { AxiosError } from "axios";

import { Alert } from "../../base/Alert";
import { Button } from "../../base/Button";
import { Toggle } from "../../base/Toggle";
import { Modal } from "../../../layouts/Modal";
import { SectionLoading } from "../../shared/SectionLoading";
import { IDefaultResponse } from "../../../types/system/DefaultResponse";
import useOrganisationInstallerMutations from "../../../data/organisationInstaller/useOrganisationInstallerMutations";

export interface ConfirmDeleteProps {
  installerName: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function UnlinkInstaller({
  installerName,
  open,
  onCancel,
  onConfirm,
}: ConfirmDeleteProps) {
  const { deleteInstaller } = useOrganisationInstallerMutations();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      setSubmitting(false);
      setError(undefined);
    }, 200);
  };

  const handleConfirm = () => {
    setSubmitting(true);

    deleteInstaller.mutate(undefined, {
      onSuccess: () => onConfirm(),
      onError: (err) =>
        setError((err as AxiosError<IDefaultResponse>).response?.data?.message),
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Confirm Unlink"
      description={`Are you sure you want to unlink ${installerName}?`}
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      {submitting ? (
        <SectionLoading />
      ) : error !== undefined ? (
        <div className="text-left">
          <Alert severity="error" title="Unlink Error" message={error} />
        </div>
      ) : (
        <>
          <div className="my-6 mb-10 flex flex-col items-center justify-center space-y-4">
            <p>
              When unlinking an installer you relinquish all visibility and
              control of your devices. The devices will also be removed from the
              installers subscription if they are currently assigned a tier. It
              is then your responsibility to manage subscriptions.
            </p>
          </div>
        </>
      )}
      <div className="mt-6 space-x-4">
        <Button disabled={error !== undefined} onClick={() => handleConfirm()}>
          Confirm
        </Button>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
