import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";
import { IDataPointFunction } from "../../../types/DataPoint/DataPointFunction";
import { useDefaultMutateResponseHandlers } from "../../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminDataPointFunctionMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } = useDefaultMutateResponseHandlers(
    "Data Point Function",
  );

  const createDataPointFunction = useMutation({
    mutationFn: (dataPointFunction) =>
      post("/v1/superadmin/datapoint/function", dataPointFunction),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "functions"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "functions"]);
    },
  });

  const updateDataPointFunction = useMutation({
    mutationFn: (dataPointFunction: IDataPointFunction) =>
      put<IDataPointFunction>(
        `/v1/superadmin/datapoint/function/${dataPointFunction.id}`,
        dataPointFunction,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "functions"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "functions"]);
    },
  });

  const delDataPointFunction = useMutation({
    mutationFn: (dataPointFunction: IDataPointFunction) =>
      del(`/v1/superadmin/datapoint/function/${dataPointFunction.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "functions"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "functions"]);
    },
  });

  return {
    createDataPointFunction,
    updateDataPointFunction,
    delDataPointFunction,
  };
}
