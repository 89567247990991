import useUser from "../../data/user/useUser";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";

export function GeneralTab() {
  const { user } = useUser();

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-2xl font-semibold leading-7 text-primary">
          Profile
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
          View your name, email address, and telephone numbers.
        </p>
        {user.isLoading ? (
          <SectionLoading />
        ) : (
          <dl className="mt-6 space-y-6 divide-y divide-gray-300 border-t border-gray-400 text-sm leading-6 dark:divide-gray-600 dark:border-gray-500">
            <div className="pt-6 sm:flex sm:items-center">
              <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                Full Name
              </dt>
              <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 dark:text-gray-200">{`${user.data?.forename} ${user.data?.surname}`}</div>
                {/* <Button size="sm" className="font-semibold">Update</Button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex sm:items-center">
              <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                Email Address
              </dt>
              <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 dark:text-gray-200">{`${user.data?.email}`}</div>
                {/* <Button size="sm" className="font-semibold">Update</Button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex sm:items-center">
              <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                Mobile/Cellular Telephone Number
              </dt>
              <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div
                  className={
                    user.data?.mobile
                      ? `text-gray-900 dark:text-gray-200`
                      : `italic text-gray-700 dark:text-gray-400`
                  }
                >
                  {`${user.data?.mobile ? user.data?.mobile : "not provided"}`}
                </div>
                {/* <Button size="sm" className="font-semibold">Update</Button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex sm:items-center">
              <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                Home/Work Telephone Number
              </dt>
              <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div
                  className={
                    user.data?.telephone
                      ? `text-gray-900 dark:text-gray-200`
                      : `italic text-gray-700 dark:text-gray-400`
                  }
                >
                  {`${
                    user.data?.telephone ? user.data?.telephone : "not provided"
                  }`}
                </div>
                {/* <Button size="sm" className="font-semibold">Update</Button> */}
              </dd>
            </div>
          </dl>
        )}
      </div>
    </div>
  );
}
