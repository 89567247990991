import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";

import { IHardwareType } from "../../types/Hardware/Hardware";
import { Toggle } from "../base/Toggle";
import useHardwareTypeMutations from "../../data/hardware/useHardwareTypeMutations";

export interface CreateEditHardwareTypeProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  hardwareType?: IHardwareType;
}

export function CreateEditHardwareType({
  open,
  onClose,
  hardwareType,
}: CreateEditHardwareTypeProps) {
  const { register, reset, handleSubmit } = useForm();
  const { hardwareTypes } = useHardwareTypes();
  const { createHardwareType, updateHardwareType } = useHardwareTypeMutations();
  const [checked, setChecked] = useState(true);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createHardwareType.mutate({ ...data, disabled: checked });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateHardwareType.mutate({
      ...hardwareType,
      ...data,
      disabled: checked,
    });
    handleClose();
  };

  useEffect(() => {
    if (hardwareType) {
      setChecked(hardwareType.disabled);
      reset({
        name: hardwareType.name,
      });
    } else {
      setChecked(true);
      reset({});
    }
  }, [reset, hardwareType]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${hardwareType ? "Edit" : "Create"} Hardware Type`}
      description={`Use this form to ${
        hardwareType ? "edit" : "create"
      } a hardware types`}
    >
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(hardwareType ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <TextField {...register("name")} label="Name" />

          <div className="mt-6 flex sm:col-span-2 justify-between">
            <div className="space-x-4">
              <Button type="submit">
                {hardwareType ? "Update" : "Create"}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <Toggle label="Disabled" checked={checked} onChange={setChecked} />
          </div>
        </form>
      )}
    </Modal>
  );
}
