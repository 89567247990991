import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import date from "date-and-time";

import { Card } from "../../components/base/Card";

import { usePageTitle } from "../../contexts/PageTitleContext";
import { PaperClipIcon } from "@heroicons/react/20/solid";

export function ErrorPage() {
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle("Error: UH (5300)");
  }, [setPageTitle]);

  return (
    <>
      <Card title="">
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-black dark:text-white">
              Error Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-700 dark:text-gray-400">
              This error information has been extrapolated from the manufacturer
              datasheets for your convenience.
            </p>
          </div>
          <div className="mt-6 border-t border-white/10 dark:border-black/10">
            <dl className="divide-y divide-black/10 dark:divide-white/10">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Hardware
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  Mitsubishi Electric Ecodan FTC5
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Code
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  UH (5300)
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Title
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  Current sensor error or input current error
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>
                    • Abnormal if current sensor detects –1.0A to 1.0A during
                    compressor operation. (This error is ignored in case of test
                    run mode.)
                  </p>
                  <p>
                    • Abnormal if 40A (W85V, W112V) of input current is detected
                    or 37A (W85V, W112V) or more of input current is detected
                    for 10 seconds continuously.
                  </p>
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Possible Causes
                </dt>
                <dd className="mt-2 text-sm text-black dark:text-white sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-black/10 dark:divide-white/10 rounded-md border border-black/20 dark:border-white/20">
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        1.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Disconnection of compressor wiring
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        2.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Defective circuit of current sensor on outdoor power
                            circuit board
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        3.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Decrease of power supply voltage
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        4.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Leakage or shortage of refrigerant
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Actions
                </dt>
                <dd className="mt-2 text-sm text-black dark:text-white sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-black/10 dark:divide-white/10 rounded-md border border-black/20 dark:border-white/20">
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        1.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Correct the wiring (U•V•W phase) to compressor.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        2.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Replace outdoor power circuit board.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        3.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Check the facility of power supply.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        4.
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">
                            Check leakage of refrigerant.
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-black dark:text-white">
                  Useful Resources
                </dt>
                <dd className="mt-2 text-sm text-black dark:text-white sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-black/10 dark:divide-white/10 rounded-md border border-black/20 dark:border-white/20">
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="font-medium">Service Manual</span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href="https://library.mitsubishielectric.co.uk/pdf/book/PUHZ-W85-112VAA-BS__Service_Manual__OCH681"
                            target="_blank"
                            className="font-medium text-indigo-400 hover:text-indigo-300"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Card>
    </>
  );
}
