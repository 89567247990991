import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Alert } from "../base/Alert";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Form } from "../base/Form/Form";
import { Modal } from "../../layouts/Modal";
import { FormErrors } from "../base/Form/FormErrors";
import { FormSelect } from "../base/Form/FormSelect";
import { fingotiCDNURL, pdOrgId } from "../../config";
import { ICountry } from "../../types/Country/Country";
import { FormTextField } from "../base/Form/FormTextField";
import { useUserContext } from "../../contexts/UserContext";
import { useCustomerMutations } from "../../data/customer/useCustomerMutations";
import {
  ICustomer,
  ICustomerUpdateCreateFormFields,
} from "../../types/Customer/Customer";

export interface CreateEditCustomerProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  customer?: ICustomer;
  installer?: boolean;
}

export function CreateEditCustomer({
  open,
  onClose,
  customer,
  installer = false,
}: CreateEditCustomerProps) {
  const { createCustomer, createInstaller, updateCustomer } =
    useCustomerMutations();
  const { organisationId } = useUserContext();
  const [invite, setInvite] = useState(false);
  const [error, setError] = useState(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const getCountries = async () => {
    try {
      let res = await fetch(
        `${fingotiCDNURL}/static/metadata/countries_v1.0.json`,
      );
      let json = await res.json();

      setCountries(json.countries);
    } catch (e) {
      setError(true);
    } finally {
      setCountriesLoading(false);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleCreate = (data: ICustomerUpdateCreateFormFields) => {
    let nameParts = data.organisationName.split(" ");
    let generatedHandle = "";
    let rn = Math.floor(Math.random() * (999 - 1))
      .toString()
      .padStart(3, "0");

    nameParts.forEach((part: string) => {
      let partSubstr = part.substring(0, 4);
      generatedHandle = generatedHandle + partSubstr;
    });

    generatedHandle = generatedHandle + rn;

    installer
      ? createInstaller.mutate({
          ...data,
          addressLine1: data.line1,
          addressLine2: data.line2,
          organisationHandle: generatedHandle,
          partnerId: pdOrgId,
        })
      : createCustomer.mutate({
          ...data,
          addressLine1: data.line1,
          addressLine2: data.line2,
          organisationHandle: generatedHandle,
          partnerId: organisationId,
        });

    handleClose();
  };

  const handleUpdate = (data: ICustomerUpdateCreateFormFields) => {
    updateCustomer.mutate({
      ...data,
      addressLine1: data.line1,
      addressLine2: data.line2,
    });

    handleClose();
  };

  const getPostcodeRegex = (code: string) => {
    let country = countries.find((c) => c.code === code);

    return RegExp(country?.regex ?? "");
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${customer ? "Amend" : "Add"} ${
        installer ? "Installer" : "Customer"
      }`}
      description={`${
        customer ? "Amend" : "Add"
      } the name and address details of a ${
        installer ? "installer" : "customer"
      }.`}
    >
      {!error ? (
        <>
          <Form<ICustomerUpdateCreateFormFields>
            defaultValues={{
              organisationName: customer?.organisationName,
              line1: customer?.address.line1,
              line2: customer?.address.line2,
              city: customer?.address.city,
              county: customer?.address.county,
              country: customer?.address.country,
              postcode: customer?.address.postcode,
            }}
            onSubmit={customer ? handleUpdate : handleCreate}
            className="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-6 sm:gap-y-4"
          >
            {({ register, watch }) => (
              <>
                <div className="sm:col-span-2">
                  <FormErrors />
                </div>
                <input
                  type="hidden"
                  {...register("id", { value: customer?.id })}
                />
                <FormTextField
                  {...register("organisationName", {
                    required: true,
                  })}
                  label={installer ? "Company Name" : "Full Name"}
                />
                <FormTextField
                  {...register("line1", {
                    required: true,
                  })}
                  label="Address Line 1"
                />
                <FormTextField {...register("line2")} label="Address Line 2" />
                <FormTextField
                  {...register("city", {
                    required: true,
                  })}
                  label="City"
                />
                <FormTextField
                  {...register("county", {
                    required: true,
                  })}
                  label="County/State/Province"
                />
                <FormTextField
                  {...register("postcode", {
                    required: true,
                    pattern: getPostcodeRegex(watch("country")),
                  })}
                  label="Postal/ZIP Code"
                />
                <FormSelect
                  disabled={countriesLoading}
                  {...register("country", {
                    required: true,
                  })}
                  label="Country"
                >
                  {countries.map((c) => (
                    <option value={c.code}>{c.country}</option>
                  ))}
                </FormSelect>
                {!customer && (
                  <>
                    <div className="flex items-center">
                      <Toggle
                        label={`Invite ${installer ? "Installer" : "Customer"}`}
                        className="mt-4"
                        checked={invite}
                        onChange={setInvite}
                      />
                    </div>
                    {invite && (
                      <>
                        <div className="sm:col-span-2 sm:mt-4">
                          <span className="font-medium text-lg">
                            Customer Details
                          </span>
                        </div>
                        <FormTextField
                          {...register("customerForename", {
                            required: invite,
                          })}
                          label="Forename"
                        />
                        <FormTextField
                          {...register("customerSurname", { required: invite })}
                          label="Surname"
                        />
                        <FormTextField
                          {...register("customerEmail", { required: invite })}
                          label="Email"
                        />
                      </>
                    )}
                  </>
                )}
                <div className="mt-6 sm:col-span-2 space-x-4">
                  <Button type="submit">
                    {customer ? "Update" : "Create"}
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <Alert
          severity="error"
          title="Error getting information"
          message="We were unable to retrieve information, please try again"
        />
      )}
    </Modal>
  );
}
