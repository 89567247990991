import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import { IUser } from "../../types/User/User";

export default function useOrganisationUsersMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("User");

  const createUser = useMutation({
    mutationFn: (user) => post("/v1/organisation/users", user),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["organisation", "users"]);
      queryClient.invalidateQueries(["organisation", "invites"]);
    },
  });

  const updateUser = useMutation({
    mutationFn: (user: IUser) =>
      put<IUser>(`/v1/organisation/users/${user.id}`, user),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => queryClient.invalidateQueries(["organisation", "users"]),
  });

  const delUser = useMutation({
    mutationFn: (user: IUser) => del(`/v1/organisation/users/${user.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => queryClient.invalidateQueries(["organisation", "users"]),
  });

  return {
    createUser,
    updateUser,
    delUser,
  };
}
