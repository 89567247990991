import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";
import { IDataPointTransformation } from "../../../types/DataPoint/DataPointTransformation";
import { useDefaultMutateResponseHandlers } from "../../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminDataPointTransformationMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } = useDefaultMutateResponseHandlers(
    "Data Point Transformation",
  );

  const createDataPointTransformation = useMutation({
    mutationFn: (dataPointTransformation) =>
      post("/v1/superadmin/datapoint/transformation", dataPointTransformation),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries([
        "superadmin",
        "datapoint",
        "transformations",
      ]);
    },
  });

  const updateDataPointTransformation = useMutation({
    mutationFn: (dataPointTransformation: IDataPointTransformation) =>
      put<IDataPointTransformation>(
        `/v1/superadmin/datapoint/transformation/${dataPointTransformation.id}`,
        dataPointTransformation,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries([
        "superadmin",
        "datapoint",
        "transformations",
      ]);
    },
  });

  const delDataPointTransformation = useMutation({
    mutationFn: (dataPointTransformation: IDataPointTransformation) =>
      del(
        `/v1/superadmin/datapoint/transformation/${dataPointTransformation.id}`,
      ),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries([
        "superadmin",
        "datapoint",
        "transformations",
      ]);
    },
  });

  return {
    createDataPointTransformation,
    updateDataPointTransformation,
    delDataPointTransformation,
  };
}
