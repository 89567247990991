import clsx from "clsx";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoadingWheel } from "../base/LoadingWheel";
import { useFreeTierNotification } from "../../hooks/useFreeTierNotification";

interface ActionSendButtonProps {
  onClick: VoidFunction;
  pendingAction: boolean;
  disabled?: boolean;
  paidTier?: boolean;
}

export function ActionSendButton({
  onClick,
  pendingAction,
  disabled = false,
  paidTier = false,
}: ActionSendButtonProps) {
  const showFreeTierMessage = useFreeTierNotification();

  return (
    <div
      onClick={
        pendingAction || disabled
          ? () => {}
          : !paidTier
            ? showFreeTierMessage
            : onClick
      }
      className={clsx(
        "w-full h-14 md:py-0 md:h-full md:w-full flex items-center justify-center bg-primary/60 rounded-sm text-white select-none hover:cursor-pointer hover:bg-primary/80",
        (pendingAction || disabled || !paidTier) &&
          "hover:cursor-default !bg-gray-600 !hover:bg-gray-600",
        disabled && "!text-gray-400",
      )}
    >
      {pendingAction ? (
        <LoadingWheel />
      ) : (
        <FontAwesomeIcon icon={faCheck} fontSize={24} />
      )}
    </div>
  );
}
