import { Dispatch, SetStateAction, useEffect, useState } from "react";

import date from "date-and-time";

import { Alert } from "../base/Alert";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Select } from "../base/Select";
import { Form } from "../base/Form/Form";
import { Modal } from "../../layouts/Modal";
import { IDevice } from "../../types/Device/Device";
import { FormModelSelect } from "./FormModelSelect";
import { FormSelect } from "../base/Form/FormSelect";
import { FormToggle } from "../base/Form/FormToggle";
import useDevices from "../../data/device/useDevices";
import { SectionLoading } from "../shared/SectionLoading";
import useHardware from "../../data/hardware/useHardware";
import useUsedSeats from "../../data/billing/useUsedSeats";
import { FormTextField } from "../base/Form/FormTextField";
import { ComponentCalibration } from "./ComponentCalibration";
import useSubscription from "../../data/billing/useSubscription";
import { FormManufacturerSelect } from "./FormManufacturerSelect";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import useHardwareMutations from "../../data/hardware/useHardwareMutations";
import { ConfirmDefaultSerialSettings } from "./ConfirmDefaultSerialSettings";
import { ConfirmSubscriptionUpdate } from "../shared/ConfirmSubscriptionChangeModal";
import {
  isValidCTOffsetValue,
  isValidScaleValue,
  isValidSigned16BitInteger,
  isValidVMOffsetValue,
} from "../../utils/validationFunctions";
import {
  BaudRateOptions,
  DataBitsOptions,
  IHardware,
  IHardwareCreateUpdateFormFields,
  ParityOptions,
  StopBitsOptions,
} from "../../types/Hardware/Hardware";
import { useUserContext } from "../../contexts/UserContext";
import clsx from "clsx";
import { pdOrgId } from "../../config";
import { UseFormSetValue } from "react-hook-form";

export interface CreateEditHardwareProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  hardware?: IHardware;
}

export function CreateEditHardware({
  open,
  onClose,
  hardware,
}: CreateEditHardwareProps) {
  const { partnerId } = useUserContext();
  const { hardware: allHardware } = useHardware();
  const { devices: allDevices } = useDevices();
  const { createHardware, updateHardware } = useHardwareMutations();
  const { hardwareTypes } = useHardwareTypes();
  const { subscription } = useSubscription();
  const { usedSeats } = useUsedSeats();
  const [subscriptionDisabledError, setSubscriptionDisabledError] =
    useState(false);
  const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);
  const [defaultSerial, setDefaultSerial] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [subscriptionTier, setSubscriptionTier] = useState<number>(1);
  const [chosenTier, setChosenTier] = useState<number | null>(null);
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const [confirmDefaultSerialOpen, setConfirmDefaultSerialOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const currentOpts = [5, 10, 20, 30, 50, 80, 100];
  const resolutionOpts = [500, 750, 1000, 2000];
  const frequencyOpts = [50, 60];

  const handleClose = () => {
    onClose(false);
    usedSeats.refetch();
    subscription.refetch();
    setTimeout(() => {
      setDefaultSerial(true);
      setSubscriptionId(null);
      setSubscriptionTier(1);
      setChosenTier(null);
    }, 200);
  };

  const handleCreate = (data: IHardwareCreateUpdateFormFields) => {
    createHardware.mutate({
      ...data,
      measuredVoltage: parseInt(data.measuredVoltage),
      deviceId: data.deviceId !== "0" ? data.deviceId : undefined,
      installedDate: data.installedDate !== "" ? data.installedDate : undefined,
      serviceDue: data.serviceDue !== "" ? data.serviceDue : undefined,
      hardwareModelId: data.hardwareModelId,
      hardwareManufacturerId: data.hardwareManufacturerId,
      hardwareTypeId: data.hardwareTypeId,
      useDefaultSerialSettings: defaultSerial,
      address: parseInt(data.address),
      subscriptionId: subscriptionId ? subscriptionId : undefined,
      tier: subscriptionTier ? subscriptionTier : 1,
      serialSettings: defaultSerial
        ? undefined
        : {
            baudRate: parseInt(data.serialSettings.baudRate),
            dataBits: parseInt(data.serialSettings.dataBits),
            parity: parseInt(data.serialSettings.parity),
            stopBits: parseInt(data.serialSettings.stopBits),
          },
      heatPumpSettings: {
        assumedFlowRate: data.heatPumpSettings.assumedFlowRate
          ? parseFloat(data.heatPumpSettings.assumedFlowRate)
          : 0,
      },
      peripheralSettings: {
        ctClamp: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          current: parseInt(data.peripheralSettings.ctClamp.current),
          resolution: parseInt(data.peripheralSettings.ctClamp.resolution),
          scale: parseFloat(data.peripheralSettings.ctClamp.scale) * 10,
          offset: parseInt(data.peripheralSettings.ctClamp.offset),
        },
        voltmeter: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          frequency: parseInt(data.peripheralSettings.voltmeter.frequency),
          scale: parseFloat(data.peripheralSettings.voltmeter.scale) * 10,
          offset: parseInt(data.peripheralSettings.voltmeter.offset),
        },
      },
    });
    handleClose();
  };

  const handleUpdate = (data: IHardwareCreateUpdateFormFields) => {
    updateHardware.mutate({
      ...hardware,
      ...data,
      measuredVoltage: parseInt(data.measuredVoltage),
      deviceId: data.deviceId !== "0" ? data.deviceId : undefined,
      hardwareModelId: data.hardwareModelId,
      hardwareManufacturerId: data.hardwareManufacturerId,
      hardwareTypeId: data.hardwareTypeId,
      address: parseInt(data.address),
      subscriptionId: subscriptionId ? subscriptionId : undefined,
      tier: subscriptionTier!,
      serialSettings: {
        baudRate: parseInt(data.serialSettings.baudRate),
        dataBits: parseInt(data.serialSettings.dataBits),
        parity: parseInt(data.serialSettings.parity),
        stopBits: parseInt(data.serialSettings.stopBits),
      },
      heatPumpSettings: {
        assumedFlowRate: parseFloat(data.heatPumpSettings.assumedFlowRate!),
      },
      peripheralSettings: {
        ctClamp: {
          enabled: data.peripheralSettings.ctClamp.enabled,
          current: parseInt(data.peripheralSettings.ctClamp.current),
          resolution: parseInt(data.peripheralSettings.ctClamp.resolution),
          scale: parseFloat(data.peripheralSettings.ctClamp.scale) * 10,
          offset: parseFloat(data.peripheralSettings.ctClamp.offset) * 100,
        },
        voltmeter: {
          enabled: data.peripheralSettings.voltmeter.enabled,
          frequency: parseInt(data.peripheralSettings.voltmeter.frequency),
          scale: parseFloat(data.peripheralSettings.voltmeter.scale) * 10,
          offset: parseFloat(data.peripheralSettings.voltmeter.offset) * 10,
        },
      },
    });
    handleClose();
  };

  const handleDefaultSerialToggle = (checked: boolean) => {
    if (!checked) {
      setConfirmDefaultSerialOpen(true);
    } else {
      setDefaultSerial(checked);
    }
  };

  const handleSeatChange = (e: any) => {
    let chosenTier = parseInt(e.target.value);
    let _usedSeats = 0;
    let _subSeats = 0;
    let requireCheck = true;

    switch (chosenTier) {
      case 2:
        _usedSeats = usedSeats.data![0].standard;
        _subSeats = subscription.data![0].seats.standard;
        break;
      case 1:
      default:
        setSubscriptionId(null);
        requireCheck = false;
        break;
    }

    if (requireCheck) {
      if (_usedSeats === _subSeats || _usedSeats > _subSeats) {
        setSubscriptionUpdateOpen(true);
        setChosenTier(chosenTier);
      } else {
        setSubscriptionId(subscription.data![0].id);
        setSubscriptionTier(chosenTier);
      }
    } else {
      setSubscriptionId(null);
      setSubscriptionTier(chosenTier);
    }
  };

  const handleSubscriptionUpdateCancel = () => {
    setSubscriptionUpdateOpen(false);
    setSubscriptionId(null);
    setSubscriptionTier(hardware?.tier ? hardware.tier : 1);
  };

  const handleSubscriptionUpdateConfirm = () => {
    setSubscriptionUpdateOpen(false);
    setSubscriptionId(subscription.data![0].id);
    setSubscriptionTier(chosenTier!);
  };

  const handleApplyDefaultCTCalib = (
    setValue: UseFormSetValue<IHardwareCreateUpdateFormFields>,
  ) => {
    setValue("peripheralSettings.ctClamp", {
      enabled: true,
      offset: "-0.12",
      scale: "140.7",
      resolution: "1000",
      current: "30",
    });
  };

  const handleApplyDefaultVMCalib = (
    setValue: UseFormSetValue<IHardwareCreateUpdateFormFields>,
  ) => {
    setValue("peripheralSettings.voltmeter", {
      enabled: true,
      offset: "0",
      scale: "95.3",
      frequency: "50",
    });
  };

  useEffect(() => {
    if (hardware) {
      setDefaultSerial(true);
      setSubscriptionId(
        hardware.subscriptionId ? hardware.subscriptionId : null,
      );
      setSubscriptionTier(hardware.tier);
    } else {
      setDefaultSerial(true);
      setSubscriptionId(null);
      setSubscriptionTier(1);
    }
  }, [hardware]);

  useEffect(() => {
    if (
      !allDevices.isLoading &&
      !allHardware.isLoading &&
      allDevices.data &&
      allHardware.data
    ) {
      let currentDevice;

      let filteredDevices = allDevices.data.filter(
        (device) =>
          !allHardware.data.some((hardware) => hardware.deviceId === device.id),
      );

      if (hardware) {
        currentDevice = allDevices.data.find(
          (device) => device.id === hardware?.deviceId,
        );
        currentDevice && filteredDevices.push(currentDevice);
      }

      setDevices(filteredDevices);
      setLoading(false);
    }
  }, [
    hardware,
    allDevices.isLoading,
    allHardware.isLoading,
    allDevices.data,
    allHardware.data,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${hardware ? "Manage" : "Install"} Device`}
      description={`${hardware ? "Manage" : "Install"} an Atmo device.`}
    >
      {hardwareTypes.isLoading ||
      allHardware.isLoading ||
      subscription.isLoading ||
      usedSeats.isLoading ||
      loading ? (
        <SectionLoading />
      ) : !subscription.isError && !allHardware.isError ? (
        <>
          {subscription.data !== undefined &&
            subscription.data[0] !== undefined && (
              <ConfirmSubscriptionUpdate
                tier="standard"
                currentSeats={subscription.data![0].seats.standard}
                open={subscriptionUpdateOpen}
                onCancel={handleSubscriptionUpdateCancel}
                onConfirm={handleSubscriptionUpdateConfirm}
              />
            )}
          <ConfirmDefaultSerialSettings
            open={confirmDefaultSerialOpen}
            onCancel={() => setConfirmDefaultSerialOpen(false)}
            onConfirm={() => {
              setDefaultSerial(false);
              setConfirmDefaultSerialOpen(false);
            }}
          />
          <Form<IHardwareCreateUpdateFormFields>
            defaultValues={
              hardware
                ? {
                    name: hardware.name,
                    reference: hardware.reference,
                    installedDate:
                      hardware.serviceDue &&
                      date.format(
                        new Date(hardware.installedDate),
                        "YYYY-MM-DD",
                      ),
                    serviceDue:
                      hardware.serviceDue &&
                      date.format(new Date(hardware.serviceDue), "YYYY-MM-DD"),
                    deviceId: hardware.deviceId ? hardware.deviceId : "0",
                    hardwareModelId: hardware.hardwareModelId,
                    hardwareManufacturerId: hardware.hardwareManufacturerId,
                    hardwareTypeId: hardware.hardwareTypeId,
                    measuredVoltage: String(hardware.measuredVoltage),
                    address: String(hardware.address),
                    heatPumpSettings: {
                      assumedFlowRate: hardware.heatPumpSettings
                        ? String(hardware.heatPumpSettings.assumedFlowRate)
                        : "0",
                    },
                    serialSettings: {
                      baudRate: String(hardware.serialSettings.baudRate),
                      dataBits: String(hardware.serialSettings.dataBits),
                      parity: String(hardware.serialSettings.parity),
                      stopBits: String(hardware.serialSettings.stopBits),
                    },
                    peripheralSettings: {
                      ctClamp: {
                        current: String(
                          hardware.peripheralSettings.ctClamp?.current,
                        ),
                        resolution: String(
                          hardware.peripheralSettings.ctClamp?.resolution,
                        ),
                        enabled: hardware.peripheralSettings.ctClamp?.enabled,
                        offset: hardware.peripheralSettings.ctClamp?.offset
                          ? (
                              hardware.peripheralSettings.ctClamp?.offset / 100
                            ).toFixed(2)
                          : "0.00",
                        scale: hardware.peripheralSettings.ctClamp?.scale
                          ? (
                              hardware.peripheralSettings.ctClamp?.scale / 10
                            ).toFixed(1)
                          : "0.0",
                      },
                      voltmeter: {
                        frequency: String(
                          hardware.peripheralSettings.voltmeter?.frequency,
                        ),
                        enabled: hardware.peripheralSettings.voltmeter?.enabled,
                        offset: hardware.peripheralSettings.voltmeter?.offset
                          ? (
                              hardware.peripheralSettings.voltmeter?.offset / 10
                            ).toFixed(1)
                          : "0.0",
                        scale: hardware.peripheralSettings.voltmeter?.scale
                          ? (
                              hardware.peripheralSettings.voltmeter?.scale / 10
                            ).toFixed(1)
                          : "0.0",
                      },
                    },
                  }
                : {
                    installedDate: date.format(new Date(), "YYYY-MM-DD"),
                    serviceDue: date.format(
                      date.addYears(new Date(), 1),
                      "YYYY-MM-DD",
                    ),
                    measuredVoltage: "230",
                    address: "1",
                    heatPumpSettings: {
                      assumedFlowRate: "0",
                    },
                    serialSettings: {
                      baudRate: "0",
                      dataBits: "0",
                      parity: "0",
                      stopBits: "0",
                    },
                    peripheralSettings: {
                      ctClamp: {
                        enabled: false,
                        offset: "-0.12",
                        scale: "140.7",
                        resolution: "1000",
                        current: "30",
                      },
                      voltmeter: {
                        enabled: false,
                        offset: "0",
                        scale: "95.3",
                        frequency: "50",
                      },
                    },
                  }
            }
            onSubmit={hardware ? handleUpdate : handleCreate}
            className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
          >
            {({ register, watch, setValue, control }) => (
              <>
                <FormTextField
                  {...register("name", { required: true })}
                  label="Device Name"
                />
                <FormTextField
                  {...register("reference")}
                  label="Unique Reference/Identifier"
                />
                <FormTextField
                  {...register("installedDate", {
                    required: true,
                    onChange: (e) => {
                      setValue(
                        "serviceDue",
                        date.format(
                          date.addYears(
                            date.parse(e.target.value, "YYYY-MM-DD"),
                            1,
                          ),
                          "YYYY-MM-DD",
                        ),
                      );
                    },
                  })}
                  label="Heat Pump Installation Date"
                  type="date"
                  disabled={hardware !== undefined}
                />
                <FormTextField
                  {...register("serviceDue", {
                    validate: {
                      notLessThanStart: (serviceDue, formVals) =>
                        serviceDue > formVals.installedDate
                          ? true
                          : "Service Due must be ahead of install date",
                    },
                  })}
                  label="Heat Pump Service Due Date"
                  type="date"
                />
                <FormSelect
                  {...register("deviceId", {
                    required: true,
                  })}
                  label="Atmo Device"
                >
                  {devices!.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.id}
                    </option>
                  ))}
                  <option value="0">No Device</option>
                </FormSelect>
                <fieldset disabled={hardware !== undefined}>
                  <FormSelect
                    {...register("hardwareTypeId", {
                      required: true,
                    })}
                    label="Type of Renewable Technology"
                  >
                    <option disabled hidden value={undefined}>
                      Please Select
                    </option>
                    {hardwareTypes.data?.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </FormSelect>
                </fieldset>
                <fieldset
                  disabled={hardware !== undefined || !watch("hardwareTypeId")}
                >
                  <FormManufacturerSelect
                    {...register("hardwareManufacturerId", {
                      required: true,
                    })}
                    type={watch("hardwareTypeId")}
                  />
                </fieldset>
                <fieldset
                  disabled={
                    hardware !== undefined || !watch("hardwareManufacturerId")
                  }
                >
                  <FormModelSelect
                    {...register("hardwareModelId", {
                      required: true,
                    })}
                    manufacturer={watch("hardwareManufacturerId")}
                  />
                </fieldset>
                {subscription.data !== undefined &&
                subscription.data[0] !== undefined ? (
                  <div
                    className="relative"
                    onMouseEnter={
                      watch("deviceId") === "0"
                        ? () => setSubscriptionDisabledError(true)
                        : () => {}
                    }
                    onMouseLeave={
                      watch("deviceId") === "0"
                        ? () => setSubscriptionDisabledError(false)
                        : () => {}
                    }
                  >
                    <Select
                      value={subscriptionTier}
                      onChange={(e) => handleSeatChange(e)}
                      label="Subscription Tier"
                      disabled={watch("deviceId") === "0"}
                    >
                      <option value={1}>Free</option>
                      <option value={2}>Standard</option>
                    </Select>
                    <div
                      className={clsx(
                        subscriptionDisabledError ? "block " : "hidden ",
                        "absolute z-20 left-6 mt-2 top-16 px-3 py-2 rounded-md border border-red-500 bg-red-300/90 dark:border-red-700 dark:bg-red-600/90",
                      )}
                    >
                      You must choose an Atmo Device first
                    </div>
                  </div>
                ) : (
                  <>
                    <Select
                      disabled
                      onChange={(e) => handleSeatChange(e)}
                      label="Subscription Tier"
                      color="secondary"
                    >
                      <option value={1}>Free</option>
                      <option value={2}>Standard</option>
                    </Select>
                    {partnerId && partnerId !== pdOrgId ? (
                      <Alert
                        title="Installer managed billing"
                        message="You are unable to make any subscription changes, please contact your installer"
                      />
                    ) : (
                      <Alert
                        severity="error"
                        message="No subscription found, please see the billing section to resolve this"
                      />
                    )}
                  </>
                )}{" "}
                {watch("hardwareModelId") && (!defaultSerial || hardware) ? (
                  <>
                    <FormTextField
                      {...register("address")}
                      label="Modbus Address"
                    />
                    <div />
                  </>
                ) : (
                  <div />
                )}
                {watch("hardwareModelId") && (
                  <div
                    className={`flex flex-col space-y-4 items-start sm:space-y-0 sm:space-x-8 ${
                      defaultSerial ? " sm:my-2 " : " sm:mb-2 sm:-mt-2 "
                    }sm:flex-row sm:col-span-2`}
                  >
                    <FormToggle<IHardwareCreateUpdateFormFields>
                      control={control}
                      name="peripheralSettings.ctClamp.enabled"
                      toggleProps={{
                        label: "Current Transformer",
                        labelRight: true,
                      }}
                    />

                    <FormToggle<IHardwareCreateUpdateFormFields>
                      control={control}
                      name="peripheralSettings.voltmeter.enabled"
                      toggleProps={{
                        label: "Voltage Meter",
                        labelRight: true,
                      }}
                    />

                    {!hardware ? (
                      <Toggle
                        checked={defaultSerial}
                        onChange={(val) => handleDefaultSerialToggle(val)}
                        label="Default Serial Settings"
                        labelRight
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
                {watch("hardwareTypeId") ===
                  "aa6bfc6a-66a4-4113-af9a-1d0bdec5b8ae" && (
                  <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
                    <p className="text-base mb-2 font-medium">
                      Heat Pump Settings
                    </p>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                      <FormTextField
                        {...register("heatPumpSettings.assumedFlowRate", {
                          min: 0,
                          max: 500,
                          validate: {
                            isNumber: (value: any) => !isNaN(value),
                          },
                        })}
                        label="Assumed Flow Rate (L/min)"
                      />
                    </div>
                  </div>
                )}
                {watch("peripheralSettings.ctClamp.enabled") && (
                  <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
                    <div className="flex items-center justify-between mb-4">
                      <p className="text-base font-medium">
                        Current Transformer Calibration
                      </p>
                      <Button
                        onClick={() => handleApplyDefaultCTCalib(setValue)}
                        size="sm"
                      >
                        Apply Default Calibration
                      </Button>
                    </div>
                    <ComponentCalibration
                      offsetLabel="Offset (A)"
                      offsetRegisterFn={register(
                        "peripheralSettings.ctClamp.offset",
                        {
                          validate: {
                            isValid: (v) =>
                              isValidCTOffsetValue(v)
                                ? true
                                : "Offset must be between -327.68A and 327.67A",
                          },
                        },
                      )}
                      scaleRegisterFn={register(
                        "peripheralSettings.ctClamp.scale",
                        {
                          validate: {
                            isValid: (v) =>
                              isValidScaleValue(v)
                                ? true
                                : "Scale must be between 0.1% and 6553.5%",
                          },
                        },
                      )}
                      classes="grid grid-cols-2 sm:grid-cols-4 gap-4"
                      extraConfigComponent={
                        <>
                          <FormSelect
                            {...register("peripheralSettings.ctClamp.current")}
                            label="Rating (A)"
                          >
                            {currentOpts.map((opt) => (
                              <option value={opt}>{opt}</option>
                            ))}
                          </FormSelect>

                          <FormSelect
                            {...register(
                              "peripheralSettings.ctClamp.resolution",
                            )}
                            label="Resolution (mV)"
                          >
                            {resolutionOpts.map((opt) => (
                              <option value={opt}>{opt}</option>
                            ))}
                          </FormSelect>
                        </>
                      }
                    />
                  </div>
                )}
                {watch("hardwareModelId") && (
                  <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
                    <div className="flex items-center justify-between mb-4">
                      <p className="text-base font-medium">
                        {!watch("peripheralSettings.voltmeter.enabled")
                          ? "Voltage"
                          : "Voltage Meter Calibration"}
                      </p>
                      {watch("peripheralSettings.voltmeter.enabled") && (
                        <Button
                          onClick={() => handleApplyDefaultVMCalib(setValue)}
                          size="sm"
                        >
                          Apply Default Calibration
                        </Button>
                      )}
                    </div>
                    {!watch("peripheralSettings.voltmeter.enabled") ? (
                      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                        <FormTextField
                          {...register("measuredVoltage")}
                          label="Measured Voltage"
                        />
                      </div>
                    ) : (
                      <ComponentCalibration
                        offsetLabel="Offset (V)"
                        offsetRegisterFn={register(
                          "peripheralSettings.voltmeter.offset",
                          {
                            validate: {
                              isValid: (v) =>
                                isValidVMOffsetValue(v)
                                  ? true
                                  : "Offset must be between -3276.8V and 3276.7V",
                            },
                          },
                        )}
                        scaleRegisterFn={register(
                          "peripheralSettings.voltmeter.scale",
                          {
                            validate: {
                              isValid: (v) =>
                                isValidScaleValue(v)
                                  ? true
                                  : "Scale must be between 0.1% and 6553.5%",
                            },
                          },
                        )}
                        classes="grid grid-cols-2 sm:grid-cols-4 gap-4"
                        extraConfigComponent={
                          <>
                            <FormSelect
                              {...register(
                                "peripheralSettings.voltmeter.frequency",
                              )}
                              label="Frequency (Hz)"
                            >
                              {frequencyOpts.map((opt) => (
                                <option value={opt}>{opt}</option>
                              ))}
                            </FormSelect>
                          </>
                        }
                      />
                    )}
                  </div>
                )}
                {watch("hardwareModelId") && (!defaultSerial || hardware) && (
                  <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
                    <p className="text-base mb-2 font-medium">
                      Serial Settings
                    </p>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6 sm:gap-y-4">
                      <FormSelect
                        {...register("serialSettings.baudRate")}
                        label="Baud Rate"
                      >
                        <option disabled hidden value={0}>
                          Please Select
                        </option>
                        {BaudRateOptions.map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                      </FormSelect>

                      <FormSelect
                        {...register("serialSettings.dataBits")}
                        label="Data Bits"
                      >
                        <option disabled hidden value={0}>
                          Please Select
                        </option>
                        {DataBitsOptions.map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                      </FormSelect>

                      <FormSelect
                        {...register("serialSettings.parity")}
                        label="Parity"
                      >
                        {ParityOptions.map((x, i) => (
                          <option key={i} value={x.value}>
                            {x.key}
                          </option>
                        ))}
                      </FormSelect>

                      <FormSelect
                        {...register("serialSettings.stopBits")}
                        label="Stop Bits"
                      >
                        <option disabled hidden value={0}>
                          Please Select
                        </option>
                        {StopBitsOptions.map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                      </FormSelect>
                    </div>
                  </div>
                )}
                <div className="mt-6 sm:col-span-2 space-x-4">
                  <Button type="submit">
                    {hardware ? "Update" : "Create"}
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <Alert
          severity="error"
          title="Error getting billing information"
          message="We were unable to retrieve billing information, please try again"
        />
      )}
    </Modal>
  );
}
