import { DateTime, Duration } from "luxon";

import {
  IGraphTimespan,
  IPredefinedDateRange,
  IPredefinedTimespan,
} from "../types/system/GraphTimespan";
import GranularityOption, {
  GranularityCondition,
} from "../types/system/GranularityOptions";

const dt = DateTime.now();
const year = dt.year;

const seasons = {
  winter: {
    start: DateTime.fromObject({
      year: dt.month >= 9 ? year : year - 1,
      month: 9,
      day: 15,
    }),
    end: DateTime.fromObject({
      year: dt.month >= 9 ? year + 1 : year,
      month: 5,
      day: 14,
    }),
  },
  summer: {
    start: DateTime.fromObject({
      year: dt.month >= 5 ? year : year - 1,
      month: 5,
      day: 15,
    }),
    end: DateTime.fromObject({
      year: dt.month >= 5 ? year : year - 1,
      month: 9,
      day: 14,
    }),
  },
};

const granularityConditions: GranularityCondition[] = [
  { maxHours: 1, granularities: ["5 Seconds"] },
  {
    minHours: 1,
    maxHours: 24,
    granularities: ["Hourly"],
  },
  {
    minHours: 24,
    maxHours: 7 * 24,
    granularities: ["Hourly", "Daily"],
  },
  {
    minHours: 7 * 24,
    maxHours: 28 * 24,
    granularities: ["Hourly", "Daily", "Weekly"],
  },
  {
    minHours: 28 * 24,
    maxHours: 3 * 30 * 24,
    granularities: ["Hourly", "Daily", "Weekly", "Monthly"],
  },
  {
    minHours: 3 * 30 * 24,
    maxHours: 6 * 30 * 24,
    granularities: ["Daily", "Weekly", "Monthly"],
  },
  {
    minHours: 6 * 30 * 24,
    maxHours: Duration.fromObject({ years: 1 }).as("hours"),
    granularities: ["Daily", "Weekly", "Monthly"],
  },
  {
    minHours: Duration.fromObject({ years: 1 }).as("hours"),
    maxHours: 2 * Duration.fromObject({ years: 1 }).as("hours"),
    granularities: ["Daily", "Weekly", "Monthly", "Yearly"],
  },
  {
    minHours: 2 * Duration.fromObject({ years: 1 }).as("hours"),
    granularities: ["Weekly", "Monthly", "Yearly"],
  },
];

export const predefinedTimespans: IPredefinedTimespan[] = [
  { label: "1 Hour", duration: Duration.fromObject({ hours: 1 }) },
  { label: "24 Hours", duration: Duration.fromObject({ hours: 24 }) },
  { label: "7 Days", duration: Duration.fromObject({ days: 7 }) },
  { label: "28 Days", duration: Duration.fromObject({ days: 28 }) },
  { label: "3 Months", duration: Duration.fromObject({ months: 3 }) },
  { label: "6 Months", duration: Duration.fromObject({ months: 6 }) },
  { label: "1 Year", duration: Duration.fromObject({ years: 1 }) },
  { label: "2 Years", duration: Duration.fromObject({ years: 2 }) },
];

export const predefinedDateRanges: IPredefinedDateRange[] = [
  {
    label: "Summer season",
    startDate: seasons.summer.start,
    endDate: seasons.summer.end,
  },
  {
    label: "Winter season",
    startDate: seasons.winter.start,
    endDate: seasons.winter.end,
  },
];

export const normalizeDateTime = (dateTime: DateTime) =>
  dateTime.set({ second: 0, millisecond: 0 });

export const isInFuture = (date: DateTime) => date > DateTime.now();

export const getTimespanValues = (duration: Duration): IGraphTimespan => {
  // const now = normalizeDateTime(
  //   DateTime.fromFormat("24/04/2024 21:16:24", "dd/MM/yyyy HH:mm:ss")
  // );
  const now = normalizeDateTime(DateTime.now());

  return {
    startDate: now.minus(duration),
    endDate: now,
  };
};

export const getGranularities = (duration: Duration): GranularityOption[] => {
  const hours = duration.as("hours");

  for (const condition of granularityConditions) {
    if (
      (condition.minHours === undefined || hours > condition.minHours) &&
      (condition.maxHours === undefined || hours <= condition.maxHours)
    ) {
      return condition.granularities;
    }
  }

  // Default case if no conditions match
  return [];
};
