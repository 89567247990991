import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IOrganisationTariff } from "../../types/Organisation/Organisation";
import { organisationTariffQueryKeys } from "../QueryKeys";

export default function useOrganisationTariffs() {
  const { get } = useApiHelper();

  const tariffs = useQuery({
    queryKey: organisationTariffQueryKeys.all(),
    queryFn: () =>
      get<IDefaultResponseWithData<IOrganisationTariff>>(
        "/v1/organisation/tariffs",
      ).then((res) => res.data.data),
  });

  return {
    tariffs,
  };
}
