import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IUser } from "../../types/User/User";

export default function useOrganisationInvites() {
  const { get } = useApiHelper();

  const invites = useQuery({
    queryKey: ["organisation", "invites"],
    queryFn: () =>
      get<IDefaultResponseWithData<IUser>>("/v1/organisation/invites").then(
        (res) => res.data.data,
      ),
  });

  return {
    invites,
  };
}
