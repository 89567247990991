import { useQuery } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../../types/system/DefaultResponse";
import { IDataPointTransformation } from "../../../types/DataPoint/DataPointTransformation";

export default function useSuperAdminDataPointTransformations(
  dataPointProfileId?: string,
) {
  const { get } = useApiHelper();

  const dataPointTransformations = useQuery({
    queryKey: [
      "superadmin",
      "datapoint",
      "transformations",
      dataPointProfileId,
    ],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPointTransformation>>(
        `/v1/superadmin/datapoint/transformation${dataPointProfileId ? `/${dataPointProfileId}` : ""}`,
      ).then((res) => res.data.data),
  });

  return {
    dataPointTransformations,
  };
}
