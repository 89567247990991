import React from "react";

import { LoadingWheel } from "../base/LoadingWheel";
import { Select } from "../base/Select";

import useHardwareManufacturers from "../../data/hardware/useHardwareManufacturers";

export interface ManufacturerSelectProps {
  type: string;
}

export const ManufacturerSelect = React.forwardRef<
  any,
  ManufacturerSelectProps
>(({ type, ...rest }, ref) => {
  const { hardwareManufacturers } = useHardwareManufacturers(type);

  if (hardwareManufacturers.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingWheel size="sm" />
      </div>
    );
  } else {
    return (
      <Select disabled={!type} ref={ref} label="Manufacturer" {...rest}>
        <option disabled selected hidden></option>
        {hardwareManufacturers.data?.map((type) => (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        ))}
      </Select>
    );
  }
});
