import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { DataPointSelect } from "./DataPointSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import { IDataPointProfile } from "../../types/DataPoint/DataPointProfile";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import useSuperAdminDataPointProfileMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointProfileMutation";
import { useNavigate } from "react-router";
import { DataPointProfileSelect } from "./DataPointProfileSelect";

interface IDuplicateDataPointProfile extends IDataPointProfile {
  dataPointProfileId: string;
  sourceHardwareModelId: string;
}

export function DuplicateDataPointProfile() {
  const navigate = useNavigate();
  const { hardwareTypes } = useHardwareTypes();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const { createDataPointProfile } = useSuperAdminDataPointProfileMutations();
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IDuplicateDataPointProfile>();
  const [duplicateProfile, setDuplicateProfile] = useState(true);
  const [duplicateTransformations, setDuplicateTransformations] =
    useState(true);
  const [duplicateOptions, setDuplicateOptions] = useState(true);

  const handleDuplicate = (data: any) => {
    createDataPointProfile.mutate(
      {
        ...data,
        duplicateDataProfile: duplicateProfile,
        duplicateDataOptions: duplicateOptions,
        duplicateDataTransformations: duplicateTransformations,
      },
      {
        onSuccess: (res) => {
          navigate(`../${res.data.data[0].id}`);
        },
      },
    );
  };

  useEffect(() => {
    if (globalHardwareModelFilter) {
      reset({
        hardwareModel: globalHardwareModelFilter,
        hardwareModelId: globalHardwareModelFilter.id,
        sourceHardwareModelId: globalHardwareModelFilter.id,
        length: 2,
      });
    } else {
      reset({ length: 2 });
    }
  }, [reset, globalHardwareModelFilter]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(handleDuplicate)}
        className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
      >
        <Select
          {...register("hardwareModel.hardwareManufacturer.hardwareTypeId", {
            value: "",
          })}
          label="Type"
        >
          <option disabled hidden value="">
            Please Select
          </option>
          {hardwareTypes.data?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </Select>
        {watch("hardwareModel.hardwareManufacturer.hardwareTypeId") && (
          <ManufacturerSelect
            {...register("hardwareModel.hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("dataPointId");
              },
            })}
            type={watch("hardwareModel.hardwareManufacturer.hardwareTypeId")}
          />
        )}
        <div className="col-span-2 h-12" />
        {watch("hardwareModel.hardwareManufacturerId") && (
          <ModelSelect
            {...register("sourceHardwareModelId", {
              onChange: () => {
                resetField("dataPointProfileId");
              },
            })}
            manufacturer={watch("hardwareModel.hardwareManufacturerId")}
            label="Source Model"
          />
        )}
        {watch("sourceHardwareModelId") && (
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("dataPointId");
              },
            })}
            manufacturer={watch("hardwareModel.hardwareManufacturerId")}
            label="Destination Model"
          />
        )}
        {watch("hardwareModelId") && (
          <DataPointProfileSelect
            {...register("dataPointProfileId")}
            sourceModel={watch("sourceHardwareModelId")}
            destinationModel={watch("hardwareModelId")}
            label="Source Data Point Profile"
          />
        )}
        {watch("dataPointProfileId") && (
          <DataPointSelect
            {...register("dataPointId")}
            type={watch("hardwareModel.hardwareManufacturer.hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={undefined}
            label="Destination Data Point"
          />
        )}

        {watch("dataPointId") && (
          <div className="pt-2 flex flex-col space-y-2">
            <Toggle
              checked={duplicateProfile}
              onChange={setDuplicateProfile}
              label="Duplicate Profile"
            />
            <Toggle
              checked={duplicateOptions}
              onChange={setDuplicateOptions}
              label="Duplicate Options"
            />
            <Toggle
              checked={duplicateTransformations}
              onChange={setDuplicateTransformations}
              label="Duplicate Transformations"
            />
          </div>
        )}
        <div className="mt-6 sm:col-span-2 space-x-4">
          <Button type="submit">Duplicate</Button>
        </div>
      </form>
    </div>
  );
}
