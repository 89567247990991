import useSuperAdminActions from "../data/action/useSuperAdminActions";

const useActionLookup = () => {
  const { actions } = useSuperAdminActions();

  const lookup = (actionId: string) =>
    actions.data?.find((a) => a.id === actionId);

  return {
    isLoading: actions.isLoading,
    lookup,
  };
};

export const useActionLookups = () => {
  const { isLoading: actionLoading, lookup: actionLookup } = useActionLookup();

  const isLoading = actionLoading;

  return {
    isLoading,
    actionLookup,
  };
};
