import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";

import useSuperAdminDataPointFunctionMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointFunctionMutation";
import { IDataPointFunction } from "../../types/DataPoint/DataPointFunction";

export interface CreateEditDataPointFunctionProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  dataPointFunction?: IDataPointFunction;
}

export function CreateEditDataPointFunction({
  open,
  onClose,
  dataPointFunction,
}: CreateEditDataPointFunctionProps) {
  const { register, reset, handleSubmit } = useForm<IDataPointFunction>();
  const { createDataPointFunction, updateDataPointFunction } =
    useSuperAdminDataPointFunctionMutations();

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createDataPointFunction.mutate({
      ...data,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateDataPointFunction.mutate({
      ...dataPointFunction,
      ...data,
    });
    handleClose();
  };

  useEffect(() => {
    if (dataPointFunction) {
      reset({
        name: dataPointFunction.name,
        url: dataPointFunction.url,
      });
    } else {
      reset({});
    }
  }, [reset, dataPointFunction]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${dataPointFunction ? "Edit" : "Create"} Data Point Function`}
      description={`Use this form to ${
        dataPointFunction ? "edit" : "create"
      } a data point function`}
    >
      <form
        onSubmit={handleSubmit(dataPointFunction ? handleUpdate : handleCreate)}
        className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
      >
        <TextField {...register("name")} label="Name" />
        <div className="col-span-2">
          <TextField {...register("url")} label="URL" disabled />
        </div>
        <div className="mt-6 sm:col-span-2 space-x-4">
          <Button type="submit">
            {dataPointFunction ? "Update" : "Create"}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
