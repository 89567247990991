interface BillingStatProps {
  value: number;
  label: string;
}

export function BillingStat({ value, label }: BillingStatProps) {
  return (
    <div className="flex items-center px-3 pb-2 pt-3 rounded text-gray-800 bg-gray-200/30 border-gray-400/70 border dark:bg-gray-700/30 dark:border-gray-600/70 dark:text-white">
      <div className="flex items-baseline space-x-4 ">
        <h4 className="text-4xl font-bold base">{value}</h4>
        <p className="text-sm">{label}</p>
      </div>
    </div>
  );
}
