import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IUserConfiguration } from "../../types/UserConfiguration/UserConfiguration";
import { userQueryKeys } from "../QueryKeys";

export default function useUserConfigurationsMutations() {
  const queryClient = useQueryClient();
  const { put, post } = useApiHelper();

  const createConfiguration = useMutation({
    mutationFn: (config: IUserConfiguration) =>
      post<IUserConfiguration, IDefaultResponseWithData<IUserConfiguration>>(
        "/v1/user/configurations",
        config,
      ).then((res) => res.data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.configurations(1));
    },
  });

  const updateConfiguration = useMutation({
    mutationFn: (config: IUserConfiguration) =>
      put<IUserConfiguration, IDefaultResponseWithData<IUserConfiguration>>(
        `/v1/user/configurations/${config.id}`,
        config,
      ).then((res) => res.data.data),
  });

  return {
    createConfiguration,
    updateConfiguration,
  };
}
