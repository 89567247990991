import { IConfiguration } from "../Configuration/Configuration";
import { IBaseModel } from "../system/BaseModel";

export enum ConfigurationType {
  none = 0,
  statistic = 1,
  dashboard = 2,
}

export interface IUserConfiguration
  extends Omit<IBaseModel, "id" | "createdOn" | "createdBy"> {
  id?: string;
  type: ConfigurationType;
  ownerId: string;
  configuration: IConfiguration[];
}
