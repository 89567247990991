import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IHardwareManufacturer } from "../../types/Hardware/Hardware";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useHardwareManufacturers(type?: string) {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } = useDefaultMutateResponseHandlers(
    "Hardware Manufacturer",
  );

  const createHardwareManufacturer = useMutation({
    mutationFn: (hardwareManufacturer) =>
      post("/v1/superadmin/hardware/manufacturers", hardwareManufacturer),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "manufacturers"]);
      queryClient.invalidateQueries([
        "superadmin",
        "hardware",
        "manufacturers",
      ]);
    },
  });

  const updateHardwareManufacturer = useMutation({
    mutationFn: (hardwareManufacturer: IHardwareManufacturer) =>
      put<IHardwareManufacturer>(
        `/v1/superadmin/hardware/manufacturers/${hardwareManufacturer.id}`,
        hardwareManufacturer,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "manufacturers"]);
      queryClient.invalidateQueries([
        "superadmin",
        "hardware",
        "manufacturers",
      ]);
    },
  });

  const delHardwareManufacturer = useMutation({
    mutationFn: (hardwareManufacturer: IHardwareManufacturer) =>
      del(`/v1/superadmin/hardware/manufacturers/${hardwareManufacturer.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["hardware", "manufacturers"]);
      queryClient.invalidateQueries([
        "superadmin",
        "hardware",
        "manufacturers",
      ]);
    },
  });

  return {
    createHardwareManufacturer,
    updateHardwareManufacturer,
    delHardwareManufacturer,
  };
}
