import clsx from "clsx";

export interface PillProps {
  label: string;
  colour: string | undefined;
  className?: string;
}

export function Pill({ label, colour, className }: PillProps) {
  return (
    <span
      style={{ background: `#${colour}` }}
      className={clsx(
        "text-xs text-center font-semibold inline-block py-1 px-2 rounded text-white uppercase last:mr-0 mr-2",
        className,
      )}
    >
      {label}
    </span>
  );
}
