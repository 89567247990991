import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { organisationTariffQueryKeys } from "../QueryKeys";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import { IOrganisationTariff } from "../../types/Organisation/Organisation";

export default function useOrganisationTariffsMutations() {
  const queryClient = useQueryClient();
  const { put } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Tariff");

  const updateTariffs = useMutation({
    mutationKey: organisationTariffQueryKeys.update(),
    mutationFn: (tariffs: IOrganisationTariff) =>
      put<IOrganisationTariff>(`/v1/organisation/tariffs`, tariffs),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () =>
      queryClient.invalidateQueries(organisationTariffQueryKeys.all()),
  });

  return {
    updateTariffs,
  };
}
