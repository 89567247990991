import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { FullscreenLoading } from "./FullscreenLoading";
import { useUserContext } from "../../contexts/UserContext";

type Role =
  | "customer"
  | "installer"
  | "superadmin"
  | "installerActingAsCustomer";

interface AuthGuardProps {
  component: JSX.Element;
  requiredRole: Role | Role[];
}

export default function AuthGuard({ component, requiredRole }: AuthGuardProps) {
  const navigate = useNavigate();
  const { loading, isLoggedIn, isInstaller, installerId, isSuperAdmin } =
    useUserContext();
  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    if (!loading) {
      let userRoles: string[] = [];
      // setUserRoles([]);
      isLoggedIn && userRoles.push("customer");
      // setUserRoles((currentUserRoles) => [...currentUserRoles, "customer"]);
      isInstaller && userRoles.push("installer");
      // setUserRoles((currentUserRoles) => [...currentUserRoles, "installer"]);
      isSuperAdmin && userRoles.push("superadmin");
      // setUserRoles((currentUserRoles) => [...currentUserRoles, "superadmin"]);
      installerId && userRoles.push("installerActingAsCustomer");
      // setUserRoles((currentUserRoles) => [
      //   ...currentUserRoles,
      //   "installerActingAsCustomer",
      // ]);

      const normalisedRequiredRoles = Array.isArray(requiredRole)
        ? requiredRole
        : [requiredRole];

      const hasPermission = normalisedRequiredRoles.some((reqRole) =>
        userRoles.includes(reqRole),
      );

      if (!hasPermission) {
        navigate(-1);
      }
    }
  }, [
    loading,
    isLoggedIn,
    isInstaller,
    installerId,
    isSuperAdmin,
    navigate,
    requiredRole,
  ]);

  if (loading) {
    return <FullscreenLoading />;
  } else {
    return component;
  }
}
