import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IDataPointFunction } from "../../types/DataPoint/DataPointFunction";

export default function useDataPointFunctions() {
  const { get } = useApiHelper();

  const dataPointFunctions = useQuery({
    queryKey: ["reading", "functions"],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPointFunction>>(
        "/v1/reading/functions",
      ).then((res) => res.data.data),
  });

  return {
    dataPointFunctions,
  };
}
