// @ts-nocheck
// Ignore - attributes doesn't exist on type
// TODO: add to type and do additional checks
import { useFormContext } from "react-hook-form";
import { Alert } from "../Alert";
import { getErrorTypeText } from "./getErrorTypeText";

export function FormErrors() {
  const {
    formState: { errors },
  } = useFormContext();

  console.log(errors);

  const errorKeys = Object.keys(errors);

  const getErrorText = (errorKey: string) => {
    if (errors[errorKey]) {
      if (errors[errorKey]?.message) {
        return errors[errorKey]?.message as string;
      } else {
        return `${
          errors[errorKey]?.ref!.attributes["aria-label"]["value"]
        } is ${getErrorTypeText(errors[errorKey]?.type as string)}`;
      }
    } else {
      return `is ${getErrorTypeText(errors[errorKey]?.type as string)}`;
    }
  };

  return errorKeys.length > 0 ? (
    <Alert
      severity="error"
      title="Validation Error"
      message={errorKeys.map((key) => getErrorText(key))}
    />
  ) : (
    <></>
  );
}
