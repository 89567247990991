import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import { IUser } from "../../types/User/User";

export default function useOrganisationInvitesMutations() {
  const queryClient = useQueryClient();
  const { put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("User");

  const updateInvite = useMutation({
    mutationFn: (user: IUser) =>
      put<IUser>(`/v1/organisation/invites/${user.id}`, user),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => queryClient.invalidateQueries(["organisation", "invites"]),
  });

  const delInvite = useMutation({
    mutationFn: (user: IUser) => del(`/v1/organisation/invites/${user.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => queryClient.invalidateQueries(["organisation", "invites"]),
  });

  return {
    updateInvite,
    delInvite,
  };
}
