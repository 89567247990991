import { useEffect } from "react";
import clsx from "clsx";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import {
  BanknotesIcon,
  CreditCardIcon,
  FingerPrintIcon,
  SwatchIcon,
  UserCircleIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../components/base/Card";

import { usePageTitle } from "../contexts/PageTitleContext";
import { useUserContext } from "../contexts/UserContext";
import { pdOrgId } from "../config";

const baseSecondaryNavigation = [
  { name: "General", href: "", icon: UserCircleIcon, end: true },
  { name: "Tariffs", href: "tariffs", icon: BanknotesIcon },
  { name: "Security", href: "security", icon: FingerPrintIcon },
  { name: "Users", href: "users", icon: UsersIcon },
  // Hidden as notifications don't exist yet
  // { name: "Notifications", href: "notifications", icon: BellIcon },
  { name: "Billing", href: "billing", icon: CreditCardIcon },
];

export function SettingsLayout() {
  const { setPageTitle } = usePageTitle();
  const { isInstaller, partnerId, installerId } = useUserContext();

  const getNavItems = () => {
    let secondaryNav = baseSecondaryNavigation;

    if (partnerId && partnerId !== installerId) {
      secondaryNav = baseSecondaryNavigation.filter(
        (item) => item.href !== "billing",
      );
    }

    if (isInstaller) {
      return [
        ...secondaryNav,
        { name: "Branding", href: "branding", icon: SwatchIcon },
      ];
    } else if (partnerId && partnerId !== pdOrgId) {
      return [
        ...secondaryNav,
        {
          name: "Installer Access",
          href: "installer",
          icon: WrenchScrewdriverIcon,
        },
      ];
    } else {
      return secondaryNav;
    }
  };

  useEffect(() => {
    setPageTitle("Settings");
  }, [setPageTitle]);

  return (
    <>
      <Card>
        <div className="lg:flex lg:gap-x-16 lg:p-4">
          <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                {getNavItems().map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      end={item.end}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? "bg-primary text-primaryContrast"
                            : "text-gray-400 hover:text-primaryContrast hover:bg-primary ",
                          "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
                        )
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={clsx(
                              isActive
                                ? "text-primaryContrast"
                                : "text-gray-400 group-hover:text-primaryContrast",
                              "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </>
                      )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </aside>

          <main className="p-4 sm:px-6 lg:flex-auto lg:px-0">
            <Outlet />
          </main>
        </div>
      </Card>
    </>
  );
}
