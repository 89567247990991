import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";

import { Toggle } from "../base/Toggle";
import { IJobCategory } from "../../types/JobCategory/JobCategory";
import useJobCategoriesMutations from "../../data/job/useJobCategoriesMutations";
import useJobCategories from "../../data/job/useJobCategories";
import { RgbColorPicker, RgbaColorPicker } from "react-colorful";
import { CategoryPill } from "./CategoryPill";

export interface CreateEditJobCategoryProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  jobCategory?: IJobCategory;
}

export function CreateEditJobCategory({
  open,
  onClose,
  jobCategory,
}: CreateEditJobCategoryProps) {
  const { register, reset, handleSubmit, watch } = useForm();
  const { jobCategories } = useJobCategories();
  const { createJobCategory, updateJobCategory } = useJobCategoriesMutations();
  const [color, setColor] = useState({ r: 0, g: 0, b: 0 });

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createJobCategory.mutate({ ...data, rgb: [color.r, color.g, color.b] });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateJobCategory.mutate({
      ...jobCategory,
      ...data,
      rgb: [color.r, color.g, color.b],
    });
    handleClose();
  };

  useEffect(() => {
    if (jobCategory) {
      reset({
        name: jobCategory.name,
        colour: jobCategory.colour,
      });
      setColor({
        r: jobCategory?.rgb[0],
        g: jobCategory?.rgb[1],
        b: jobCategory?.rgb[2],
      });
    } else {
      reset({});
    }
  }, [reset, jobCategory]);

  const name = watch("name");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${jobCategory ? "Edit" : "Create"} Job Category`}
      description={`Use this form to ${
        jobCategory ? "edit" : "create"
      } a job category`}
    >
      {jobCategories.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(jobCategory ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-6 sm:gap-y-4"
        >
          <div>
            <TextField {...register("name")} label="Name" />
            <div className="mt-4 sm:mt-10">
              <label className="text-sm font-medium opacity-80">Preview</label>
              {name ? (
                <div className="sm:w-1/2">
                  <CategoryPill
                    category={{
                      installerId: "",
                      name: name,
                      colour: 0,
                      id: "",
                      createdBy: "",
                      createdOn: "",
                      rgb: [color.r, color.g, color.b],
                    }}
                  />
                </div>
              ) : (
                <div className="opacity-75 text-medium text-sm italic">
                  Start typing a name to see a preview
                </div>
              )}
            </div>
          </div>
          <div>
            <label className="text-sm font-medium opacity-80">Colour</label>
            <div className="flex justify-center">
              <RgbColorPicker color={color} onChange={setColor} />
            </div>
          </div>
          <div className="mt-6 flex sm:col-span-2 space-x-4">
            <Button type="submit">{jobCategory ? "Update" : "Create"}</Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
