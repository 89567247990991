import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IInstallerBrandingGetResponse } from "../../types/responses/installer/branding/InstallerBrandingGet";

export default function useBranding() {
  const { get } = useApiHelper();

  const branding = useQuery({
    queryKey: ["branding"],
    queryFn: () =>
      get<IDefaultResponseWithData<IInstallerBrandingGetResponse>>(
        "/v1/brand",
      ).then((res) => res.data.data),
  });

  return {
    branding,
  };
}
