import React, { useState } from "react";

import { LoadingWheel } from "../base/LoadingWheel";

import useHardwareManufacturers from "../../data/hardware/useHardwareManufacturers";
import { FormSelect } from "../base/Form/FormSelect";
import clsx from "clsx";

export interface FormManufacturerSelectProps {
  type: string;
}

export const FormManufacturerSelect = React.forwardRef<
  any,
  FormManufacturerSelectProps
>(({ type, ...rest }, ref) => {
  const { hardwareManufacturers } = useHardwareManufacturers(type);
  const [open, setOpen] = useState(false);

  if (hardwareManufacturers.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingWheel size="sm" />
      </div>
    );
  } else {
    return (
      <div
        className="relative"
        onMouseEnter={!type ? () => setOpen(true) : () => {}}
        onMouseLeave={!type ? () => setOpen(false) : () => {}}
      >
        <FormSelect disabled={!type} ref={ref} label="Manufacturer" {...rest}>
          <option disabled selected hidden></option>
          {hardwareManufacturers.data?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </FormSelect>
        <div
          className={clsx(
            open ? "block " : "hidden ",
            "absolute z-20 left-6 mt-2 top-16 px-3 py-2 rounded-md border border-red-500 bg-red-300/90 dark:border-red-700 dark:bg-red-600/90",
          )}
        >
          You must choose a Type of Renewable Technology first
        </div>
      </div>
    );
  }
});
