import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { Button } from "../../../components/base/Button";
import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { IDataPointTransformation } from "../../../types/DataPoint/DataPointTransformation";
import useSuperAdminDataPointTransformationMutations from "../../../data/datapoint/superadmin/useSuperAdminDataPointTransformationMutation";
import { CreateEditDataPointTransformation } from "../../../components/DataPoint/CreateEditDataPointTransformation";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import { LoadingWheel } from "../../../components/base/LoadingWheel";
import { useDataPointLookups } from "../../../hooks/useDataPointLookups";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";

const colHelper = createColumnHelper<IDataPointTransformation>();

export function DataPointTransformationsPage() {
  const { delDataPointTransformation, updateDataPointTransformation } =
    useSuperAdminDataPointTransformationMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editDataPointTransformation, setEditDataPointTransformation] =
    useState<IDataPointTransformation | undefined>(undefined);
  const [deleteDataPointTransformation, setDeleteDataPointTransformation] =
    useState<IDataPointTransformation | undefined>(undefined);
  const {
    isLoading: isHardwareLookupLoading,
    manufacturerLookup,
    modelLookup,
    typeLookup,
  } = useSuperAdminHardwareLookups();
  const {
    isLoading: isDataPointLookupLoading,
    dataPointLookup,
    functionsLookup,
  } = useDataPointLookups(globalHardwareModelFilter?.id);

  const handleDeleteDataPointTransformation = (
    dataPointTransformation: IDataPointTransformation,
  ) => {
    setDeleteDataPointTransformation(dataPointTransformation);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteDataPointTransformation = () => {
    delDataPointTransformation.mutate(deleteDataPointTransformation!);
    setDeleteOpen(false);
  };

  const handleRestoreDataPointTransformation = (
    dataPointTransformation: IDataPointTransformation,
  ) => {
    updateDataPointTransformation.mutate({
      ...dataPointTransformation,
      restore: true,
    });
  };

  const handleEditDataPointTransformation = (
    dataPointTransformation: IDataPointTransformation,
  ) => {
    setEditDataPointTransformation(dataPointTransformation);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editDataPointTransformation &&
      setTimeout(() => setEditDataPointTransformation(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" },
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => dataPointLookup(row.dataPointId)?.name, {
      header: "Data Point",
    }),
    colHelper.accessor((row) => functionsLookup(row.functionId)?.name, {
      header: "Function",
    }),
    colHelper.accessor("arguments", {
      header: "Arguments",
      cell: (info) =>
        info.getValue() === null ? (
          <span className="opacity-70 dark:text-white italic">null</span>
        ) : (
          info.getValue()
        ),
    }),
    colHelper.accessor("order", { header: "Order" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() =>
                handleRestoreDataPointTransformation(info.row.original)
              }
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleEditDataPointTransformation(info.row.original)
                }
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleDeleteDataPointTransformation(info.row.original)
                }
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditDataPointTransformation
        open={editOpen}
        onClose={handleCreateEditClose}
        dataPointTransformation={editDataPointTransformation}
      />
      <ConfirmDelete
        itemName={"Data Point Transformation"}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteDataPointTransformation()}
      />
      <Card title="Data Point Transformations">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>
            Add Data Point Transformation
          </Button>
        </div>
        {isHardwareLookupLoading || isDataPointLookupLoading ? (
          <LoadingWheel />
        ) : (
          <div>
            <Table<IDataPointTransformation>
              url="/superadmin/datapoint/transformation"
              queryKey={["superadmin", "datapoint", "transformations"]}
              columns={cols}
              predefinedFilterFields={
                globalHardwareModelFilter
                  ? [
                      {
                        field: "hardwareModelId",
                        value: globalHardwareModelFilter.id,
                        fieldLabel: "Hardware Model",
                        valueLabel: globalHardwareModelFilter.name,
                      },
                    ]
                  : undefined
              }
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
