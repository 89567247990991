import { useState } from "react";

import { Button } from "../base/Button";
import getAddress from "../../utils/getAddress";
import { SectionLoading } from "../shared/SectionLoading";
import { UnlinkInstaller } from "./Installer/UnlinkInstaller";
import useOrganisationInstaller from "../../data/organisationInstaller/useOrganisationInstaller";
import { Alert } from "../base/Alert";
import { useNavigate } from "react-router";
import { useUserContext } from "../../contexts/UserContext";

export function InstallerTab() {
  const navigate = useNavigate();
  const { reload } = useUserContext();
  const { installer } = useOrganisationInstaller();
  const [unlinkOpen, setUnlinkOpen] = useState(false);

  const handleUnlinkSuccess = () => {
    setUnlinkOpen(false);
    navigate("..");
    reload();
  };

  return (
    <>
      <UnlinkInstaller
        installerName={installer.data?.[0].organisationName!}
        open={unlinkOpen}
        onCancel={() => setUnlinkOpen(false)}
        onConfirm={handleUnlinkSuccess}
      />
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-2xl font-semibold leading-7 text-primary">
            Installer Access
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
            View installer's who have access to your devices.
          </p>
          {installer.isLoading ? (
            <SectionLoading />
          ) : installer.isError ? (
            <div className="my-6">
              <Alert
                severity="error"
                title="An error has occurred"
                message="Looks like we've run into a problem, please try again, if the issue persists please contact support"
              />
            </div>
          ) : (
            <dl className="mt-6 space-y-6 divide-y divide-gray-300 border-t border-gray-400 text-sm leading-6 dark:divide-gray-600 dark:border-gray-500">
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Installer Name
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200">
                    <p>{installer.data?.[0].organisationName}</p>
                  </div>
                </dd>
              </div>
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Installer Contact
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200">
                    <p>
                      {installer.data?.[0].contact.forename}{" "}
                      {installer.data?.[0].contact.surname}
                    </p>
                    <p>{installer.data?.[0].contact.email}</p>
                    <p>{installer.data?.[0].contact.telephone}</p>
                  </div>
                </dd>
              </div>
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Installer Address
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200">
                    {getAddress(installer.data?.[0].address)
                      .split(",")
                      .map((ln) => (
                        <p>{ln}</p>
                      ))}
                  </div>
                </dd>
              </div>
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Remove Access
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200">
                    <Button onClick={() => setUnlinkOpen(true)}>
                      Unlink Installer
                    </Button>
                  </div>
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    </>
  );
}
