export default function useQSBuilder() {
  function _handleArray(key: string, data: string[]) {
    let arrStr = "";

    data.forEach((el, i) => {
      arrStr = arrStr + `${i !== 0 ? `&${key}=${el}` : `${el}`}`;
    });

    return arrStr;
  }

  function buildQS(input: any) {
    if (input !== undefined) {
      let qs = "";
      let keys = Object.keys(input);

      keys.forEach((k: string, i) => {
        let normalised = Array.isArray(input[k])
          ? _handleArray(k, input[k])
          : input[k];
        qs = qs + `${i === 0 ? "?" : "&"}${k}=${normalised}`;
      });

      return qs;
    } else {
      return "";
    }
  }

  return buildQS;
}
