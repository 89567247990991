import { Dispatch, SetStateAction, useState } from "react";

import { Modal } from "../../../layouts/Modal";
import useApiHelper from "../../../hooks/useApiHelper";
import { authAPIURL } from "../../../config";
import { Alert } from "../../base/Alert";
import { TextField } from "../../base/TextField";
import { SectionLoading } from "../../shared/SectionLoading";
import { Button } from "../../base/Button";
import { useNotification } from "../../../hooks/useNotifications";
import useUser from "../../../data/user/useUser";

interface MFASetupProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

export function MFADisable({ open, onClose }: MFASetupProps) {
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
  });
  const { user } = useUser();
  const addNotification = useNotification();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otpIncorrect, setOtpIncorrect] = useState(false);

  const verifyPassword = async () => {
    setLoading(true);
    try {
      const verifyResponse = await post("/v1/mfa/disable", {
        password: password,
      });

      if (verifyResponse.status === 200) {
        user.refetch();
        addNotification(
          "success",
          "Successfully disabled multi-factor authentication",
        );
        onClose(true);
      } else {
        throw Error("Error disabling MFA");
      }
    } catch (e: any) {
      if (e.response.status && e.response.status === 400) {
        setOtpIncorrect(true);
      } else {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title="Disable Multi-factor" open={open} onClose={onClose}>
      {loading ? (
        <SectionLoading />
      ) : error ? (
        <div className="mt-6">
          <Alert
            severity="error"
            title="Error"
            message="Failed to get multi-factor activation details, please try again"
          />
        </div>
      ) : (
        <div>
          <p>Please enter your password to confirm</p>
          {otpIncorrect && (
            <Alert
              className="mt-6"
              severity="warn"
              title="Verification Failed"
              message="Password incorrect, please try again"
            />
          )}
          <div className="mt-4 mx-auto w-64 space-y-2">
            <TextField
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </div>
          <div className="mt-6 flex justify-between">
            <Button variant="outlined" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button onClick={verifyPassword}>Disable</Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
