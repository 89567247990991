import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";
// import useHardwareTypes from "../../data/hardware/useHardwareTypes";

// import { Toggle } from "../base/Toggle";
import { IAction } from "../../types/Action/Action";
import useSuperAdminActionMutations from "../../data/action/useSuperAdminActionMutations";
import useSuperAdminActions from "../../data/action/useSuperAdminActions";
import { Toggle } from "../base/Toggle";

export interface CreateEditActionProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  action?: IAction;
}

export function CreateEditAction({
  open,
  onClose,
  action,
}: CreateEditActionProps) {
  const { register, reset, handleSubmit } = useForm();
  const { actions } = useSuperAdminActions();
  const { createAction, updateAction } = useSuperAdminActionMutations();
  const [customerEnable, setCustomerEnable] = useState(true);
  const [installerEnable, setInstallerEnable] = useState(true);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createAction.mutate({
      ...data,
      customerEnable,
      installerEnable,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateAction.mutate({
      ...action,
      ...data,
      customerEnable,
      installerEnable,
    });
    handleClose();
  };

  useEffect(() => {
    if (action) {
      reset({
        name: action.name,
      });
      setCustomerEnable(action.customerEnable);
      setInstallerEnable(action.installerEnable);
    } else {
      reset({});
    }
  }, [reset, action]);

  // const name = watch("name");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${action ? "Edit" : "Create"} Action`}
      description={`Use this form to ${action ? "edit" : "create"} an action`}
    >
      {actions.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(action ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-6 sm:gap-y-4"
        >
          <div>
            <TextField {...register("name")} label="Name" />
          </div>
          <div className="pt-2 flex flex-col space-y-2">
            <Toggle
              label="Visable to Customer"
              checked={customerEnable}
              onChange={setCustomerEnable}
            />
            <Toggle
              label="Visable to Installer"
              checked={installerEnable}
              onChange={setInstallerEnable}
            />
          </div>
          <div className="mt-6 flex sm:col-span-2 space-x-4">
            <Button type="submit">{action ? "Update" : "Create"}</Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
