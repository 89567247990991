import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { actionTransformationQueryKeys } from "../QueryKeys";
import { IActionTransformation } from "../../types/Action/ActionTransformation";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminActionTransformationMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } = useDefaultMutateResponseHandlers(
    "Action Transformation",
  );

  const createActionTransformation = useMutation({
    mutationKey: actionTransformationQueryKeys.create(),
    mutationFn: (actionTransformation) =>
      post("/v1/superadmin/action/transformation", actionTransformation),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () =>
      queryClient.invalidateQueries(
        actionTransformationQueryKeys.superAdminAll(),
      ),
  });

  const updateActionTransformation = useMutation({
    mutationKey: actionTransformationQueryKeys.update(),
    mutationFn: (actionTransformation: IActionTransformation) =>
      put<IActionTransformation>(
        `/v1/superadmin/action/transformation/${actionTransformation.id}`,
        actionTransformation,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () =>
      queryClient.invalidateQueries(
        actionTransformationQueryKeys.superAdminAll(),
      ),
  });

  const delActionTransformation = useMutation({
    mutationKey: actionTransformationQueryKeys.delete(),
    mutationFn: (actionTransformation: IActionTransformation) =>
      del(`/v1/superadmin/action/transformation/${actionTransformation.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () =>
      queryClient.invalidateQueries(
        actionTransformationQueryKeys.superAdminAll(),
      ),
  });

  return {
    createActionTransformation,
    updateActionTransformation,
    delActionTransformation,
  };
}
