import { useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IReading } from "../../types/DataPoint/Reading";
import { IGraphTimespan } from "../../types/system/GraphTimespan";
import GranularityOption, {
  GranularityIndex,
} from "../../types/system/GranularityOptions";

export interface IGraphDataRequest {
  hardwareId: string;
  timespan: IGraphTimespan;
  granularity: GranularityOption;
  datapoints: string[];
}

export interface IGraphDataResponse {
  datapointId: string;
  data: IReading[];
}

interface IGraphDataQueryResponse {
  datapointId: string;
  data: IReading[];
}

interface IGraphDataQueryKeyRequest {
  hardwareId: string;
  timespan: IGraphTimespan;
  granularity: GranularityOption;
  datapoint: string;
}

export function useGraphData() {
  const QueryClient = useQueryClient();
  const { get } = useApiHelper();

  const _generateQueryKey = ({
    hardwareId,
    timespan,
    granularity,
    datapoint,
  }: IGraphDataQueryKeyRequest) => [
    "graph",
    hardwareId,
    timespan,
    granularity,
    datapoint,
  ];

  const getGraphData = (
    dataRequest: IGraphDataRequest,
  ): Promise<IGraphDataResponse[]> => {
    let queryPromises: Promise<IGraphDataQueryResponse>[] = [];

    dataRequest.datapoints.forEach((datapoint) => {
      queryPromises.push(
        QueryClient.fetchQuery({
          queryKey: _generateQueryKey({
            hardwareId: dataRequest.hardwareId,
            timespan: dataRequest.timespan,
            granularity: dataRequest.granularity,
            datapoint,
          }),
          queryFn: () =>
            get<IGraphDataQueryResponse>(
              `/v1/reading/${
                dataRequest.hardwareId
              }/history?start=${dataRequest.timespan.startDate.toUTC()}&end=${dataRequest.timespan.endDate.toUTC()}&granularity=${
                GranularityIndex[dataRequest.granularity]
              }&datapoint=${datapoint}`,
            ).then((value) => ({
              data: value.data.data,
              datapointId: datapoint,
            })),
          staleTime: Infinity,
        }),
      );
    });

    return Promise.all(queryPromises).then(
      (results: IGraphDataQueryResponse[]) => {
        let data: IGraphDataResponse[] = [];

        results.forEach((r) => {
          data.push({
            datapointId: r.datapointId,
            data: r.data,
          });
        });

        return data;
      },
    );
  };

  return {
    getGraphData,
  };
}
