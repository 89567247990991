import { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button } from "../../components/base/Button";
import { TextField } from "../../components/base/TextField";
import { Alert, AlertProps } from "../../components/base/Alert";
import { SectionLoading } from "../../components/shared/SectionLoading";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IInvite } from "../../types/Invite/Invite";

export function Register() {
  const { get, post } = useApiHelper({ addToken: false });
  const { register, reset, handleSubmit, setValue } = useForm();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [noToken, setNoToken] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<AlertProps | undefined>();

  const handleRegister = async (data: any) => {
    setValue("email", data.email.toLowerCase());

    setLoading(true);

    let generatedHandle = "";
    let rn = Math.floor(Math.random() * (999 - 1))
      .toString()
      .padStart(3, "0");

    [data.forename, data.surname].forEach((part: string) => {
      let partSubstr = part.substring(0, 4);
      generatedHandle = generatedHandle + partSubstr;
    });

    generatedHandle = generatedHandle + rn;

    try {
      await post(`/v1/user/invite?token=${token}`, {
        ...data,
        forename: data.forename.trim(),
        surname: data.surname.trim(),
        email: data.email.toLowerCase(),
        userHandle: generatedHandle,
        accept: true,
      });
      setSuccess(true);
    } catch (err: any) {
      setError({ severity: "error", message: err.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const processInvite = async () => {
      const getInvite = async (token: string) => {
        try {
          const invite = await get<IDefaultResponseWithData<IInvite>>(
            `/v1/user/invite?token=${token}`,
          );

          if (invite.data) {
            const inviteData = invite.data.data[0];
            setIsInvite(true);
            reset({
              forename: inviteData.forename,
              surname: inviteData.surname,
              email: inviteData.email,
            });
          }
        } catch (err: any) {
          setError({ severity: "error", message: err.message });
        } finally {
          setLoading(false);
        }
      };

      if (searchParams.has("token")) {
        const token = searchParams.get("token")!;
        setToken(token);
        getInvite(token);
      } else {
        setNoToken(true);
        setLoading(false);
      }
    };

    processInvite();
  }, [searchParams]);

  return (
    <div className="w-10/12 xl:w-8/12 mx-auto">
      {loading ? (
        <SectionLoading />
      ) : success ? (
        <>
          <Alert
            className="mb-6"
            severity="success"
            title="Registration Successful!"
            message="Your account has been created and you can now log in"
          />
          <NavLink to="/login">
            <Button fullWidth>Login</Button>
          </NavLink>
        </>
      ) : noToken ? (
        <Alert
          severity="warn"
          title="Invite Required"
          message="To join Planet Devices you must receive an invitation."
        />
      ) : (
        <>
          <div className="mb-6 text-4xl font-medium dark:text-white">
            Register
          </div>
          {error && <Alert {...error} className="mb-6" />}
          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="space-y-2">
              <div className="sm:grid sm:grid-cols-2 sm:gap-x-4">
                <TextField
                  {...register("forename")}
                  fullWidth
                  placeholder="Forename"
                  label="Forename"
                />
                <TextField
                  {...register("surname")}
                  fullWidth
                  placeholder="Surname"
                  label="Surname"
                />
              </div>
              <TextField
                {...register("mobile")}
                fullWidth
                placeholder="Mobile"
                label="Mobile"
              />
              <TextField
                {...register("telephone")}
                fullWidth
                placeholder="Telephone"
                label="Telephone"
              />
              <TextField
                {...register("email")}
                fullWidth
                placeholder="Email"
                label="Email"
                type="email"
                disabled={isInvite}
              />
              <TextField
                {...register("password")}
                fullWidth
                type="password"
                placeholder="Password"
                label="Password"
              />
              <p className="text-gray-400 leading-tight text-xs">
                Passwords must be at least six characters long, have at least
                one uppercase letter, one number, and one symbol.
              </p>
              <TextField
                {...register("confirmPassword")}
                fullWidth
                type="password"
                placeholder="Confirm Password"
                label="Confirm Password"
              />
            </div>
            <Button fullWidth type="submit" className="mt-6">
              Register
            </Button>
          </form>
        </>
      )}
    </div>
  );
}
