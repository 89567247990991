export default function getAddress(obj: any) {
  let address = "";

  if (obj.line1) {
    address += `${obj.line1}, `;
  }

  if (obj.line2) {
    address += `${obj.line2}, `;
  }

  if (obj.city) {
    address += `${obj.city}, `;
  }

  if (obj.county) {
    address += `${obj.county}, `;
  }

  if (obj.country) {
    address += `${obj.country}, `;
  }

  if (obj.postcode) {
    address += `${obj.postcode}`;
  }

  return address;
}
