import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { IAction } from "../../../types/Action/Action";
import { Button } from "../../../components/base/Button";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { CreateEditAction } from "../../../components/Action/CreateEditAction";
import useSuperAdminActionMutations from "../../../data/action/useSuperAdminActionMutations";
import { actionQueryKeys } from "../../../data/QueryKeys";

const colHelper = createColumnHelper<IAction>();

export function ActionPage() {
  const { delAction, updateAction } = useSuperAdminActionMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editAction, setEditAction] = useState<IAction | undefined>(undefined);
  const [deleteAction, setDeleteAction] = useState<IAction | undefined>(
    undefined,
  );

  const handleDeleteAction = (action: IAction) => {
    setDeleteAction(action);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteAction = () => {
    delAction.mutate(deleteAction!);
    setDeleteOpen(false);
  };

  const handleRestoreAction = (action: IAction) => {
    updateAction.mutate({ ...action, restore: true });
  };

  const handleEditAction = (action: IAction) => {
    setEditAction(action);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editAction && setTimeout(() => setEditAction(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor("id", { header: "Id" }),
    colHelper.accessor("name", { header: "Name" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreAction(info.row.original)}
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditAction(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteAction(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditAction
        open={editOpen}
        onClose={handleCreateEditClose}
        action={editAction}
      />
      <ConfirmDelete
        itemName={deleteAction?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteAction()}
      />
      <Card title="Action">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Action</Button>
        </div>
        <div>
          <Table<IAction>
            url="/superadmin/action"
            queryKey={actionQueryKeys.superAdminAll()}
            columns={cols}
            enableShowDeleted
          />
        </div>
      </Card>
    </>
  );
}
