import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";

import { IHardwareManufacturer } from "../../types/Hardware/Hardware";
import useHardwareManufacturers from "../../data/hardware/useHardwareManufacturers";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { Select } from "../base/Select";
import { Toggle } from "../base/Toggle";
import useHardwareManufacturerMutations from "../../data/hardware/useHardwareManufacturerMutations";

export interface CreateEditHardwareManufacturerProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  hardwareManufacturer?: IHardwareManufacturer;
}

export function CreateEditHardwareManufacturer({
  open,
  onClose,
  hardwareManufacturer,
}: CreateEditHardwareManufacturerProps) {
  const { register, reset, handleSubmit } = useForm();
  const { hardwareTypes } = useHardwareTypes();
  const { hardwareManufacturers } = useHardwareManufacturers();
  const { createHardwareManufacturer, updateHardwareManufacturer } =
    useHardwareManufacturerMutations();
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createHardwareManufacturer.mutate({ ...data, disabled });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateHardwareManufacturer.mutate({
      ...hardwareManufacturer,
      ...data,
      disabled: disabled,
    });
    handleClose();
  };

  useEffect(() => {
    if (hardwareManufacturer) {
      setDisabled(hardwareManufacturer.disabled);
      reset({
        name: hardwareManufacturer.name,
        hardwareTypeId: hardwareManufacturer.hardwareTypeId,
      });
    } else {
      setDisabled(true);
      reset({});
    }
  }, [reset, hardwareManufacturer]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${
        hardwareManufacturer ? "Edit" : "Create"
      } Hardware Manufacturer`}
      description={`Use this form to ${
        hardwareManufacturer ? "edit" : "create"
      } a hardware manufacturer`}
    >
      {hardwareManufacturers.isLoading && hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(
            hardwareManufacturer ? handleUpdate : handleCreate,
          )}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select {...register("hardwareTypeId", { value: "" })} label="Type">
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <TextField {...register("name")} label="Name" />
          <div className="mt-6 flex sm:col-span-2 justify-between">
            <div className="space-x-4">
              <Button type="submit">
                {hardwareManufacturer ? "Update" : "Create"}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <Toggle
              label="Disabled"
              checked={disabled}
              onChange={setDisabled}
            />
          </div>
        </form>
      )}
    </Modal>
  );
}
