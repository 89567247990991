import { Alert } from "../base/Alert";
import { Button } from "../base/Button";
import useApiHelper from "../../hooks/useApiHelper";
import { BillingStat } from "./Billing/BillingStat";
import { SectionLoading } from "../shared/SectionLoading";
import useUsedSeats from "../../data/billing/useUsedSeats";
import { useUserContext } from "../../contexts/UserContext";
import useSubscription from "../../data/billing/useSubscription";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { pdOrgId, stripePricingTable, stripePublicKey } from "../../config";

export function BillingTab() {
  const { organisationId, installerName, installerId, partnerId } =
    useUserContext();
  const { subscription } = useSubscription();
  const { usedSeats } = useUsedSeats();
  const { get } = useApiHelper();

  const handleManage = () => {
    get<IDefaultResponseWithData<string>>("/v1/billing/session").then((res) => {
      window.location.replace(res.data!.data[0]);
    });
  };

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-2xl font-semibold leading-7 text-primary">
          Billing
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
          View and manage your active subscriptions.
        </p>

        <div className="my-6 border-t border-gray-400 dark:border-gray-500">
          {subscription.isLoading || usedSeats.isLoading ? (
            <SectionLoading />
          ) : subscription.isError || usedSeats.isError ? (
            <Alert
              severity="error"
              title="Error getting billing information"
              message="We were unable to retrieve billing information, please try again"
            />
          ) : subscription.data![0] && usedSeats.data![0] ? (
            <div className="mt-6">
              {installerId && (
                <Alert
                  className="mb-6"
                  severity="info"
                  message={`Viewing billing information for ${installerName}`}
                />
              )}
              <div className="grid grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-4">
                <BillingStat
                  value={subscription.data![0].seats.standard}
                  label="Standard Subscriptions"
                />
              </div>
              <div className="mt-4">
                <Button onClick={handleManage}>Manage Subscription</Button>
              </div>
            </div>
          ) : partnerId && partnerId !== pdOrgId ? (
            <div className="mt-6">
              <Alert
                title="Installer managed billing"
                message="Billing for your account is managed by an installer. Please see the 'Installer Access' tab for information regarding the installer."
              />
            </div>
          ) : (
            <div className="mt-6">
              <script
                async
                src="https://js.stripe.com/v3/pricing-table.js"
              ></script>
              <stripe-pricing-table
                pricing-table-id={stripePricingTable}
                publishable-key={stripePublicKey}
                client-reference-id={organisationId}
              ></stripe-pricing-table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
