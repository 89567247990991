import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { TextArea } from "../base/TextArea";
import { TextField } from "../base/TextField";
import { DataPointSelect } from "./DataPointSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import { CreateEditDataPointOption } from "./CreateEditDataPointOption";
import { IDataPointOption } from "../../types/DataPoint/DataPointOption";
import { IDataPointProfile } from "../../types/DataPoint/DataPointProfile";
import { IDataPointTransformation } from "../../types/DataPoint/DataPointTransformation";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import useSuperAdminDataPointOptions from "../../data/datapoint/superadmin/useSuperAdminDataPointOptions";
import useSuperAdminDataPointOptionMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointOptionMutation";
import useSuperAdminDataPointTransformations from "../../data/datapoint/superadmin/useSuperAdminDataPointTransformations";
import useSuperAdminDataPointProfileMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointProfileMutation";

function OptionRow({
  index,
  option,
}: {
  index: number;
  option: IDataPointOption;
}) {
  const { updateDataPointOption } = useSuperAdminDataPointOptionMutations();
  const [editRow, setEditRow] = useState(false);
  const { register, getValues } = useForm();

  const handleUpdate = () => {
    updateDataPointOption.mutate(
      {
        ...option,
        value: getValues("value"),
        label: getValues("label"),
      },
      {
        onSuccess: () => setEditRow(false),
      },
    );
  };

  return (
    <tr className={`${index % 2 !== 0 && "bg-primary/10"}`}>
      {editRow ? (
        <>
          <td width="25%" className="whitespace-nowrap px-3 py-4 text-sm">
            <TextField {...register("value", { value: option.value })} />
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm">
            <TextField {...register("label", { value: option.label })} />
          </td>
          <td width="10%" className="whitespace-nowrap px-3 py-4 text-sm">
            <div className="flex items-center space-x-4">
              <CheckCircleIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={handleUpdate}
              />
              <XCircleIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => setEditRow(false)}
              />
            </div>
          </td>
        </>
      ) : (
        <>
          <td width="25%" className="whitespace-nowrap px-3 py-4 text-sm">
            {option.value}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm">
            {option.label}
          </td>
          <td width="10%" className="whitespace-nowrap px-3 py-4 text-sm">
            <div className="flex items-center space-x-4">
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => setEditRow(true)}
              />
            </div>
          </td>
        </>
      )}
    </tr>
  );
}

function TransformationRow({
  index,
  transformation,
}: {
  index: number;
  transformation: IDataPointTransformation;
}) {
  return (
    <tr className={`${index % 2 !== 0 && "bg-primary/10"}`}>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {transformation.function.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {!transformation.arguments || transformation.arguments === "" ? (
          <span className="opacity-70 dark:text-white italic">none</span>
        ) : (
          transformation.arguments
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <div className="flex items-center space-x-4">
          <ArrowSmallUpIcon className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer" />
          <ArrowSmallDownIcon className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer" />
        </div>
      </td>
    </tr>
  );
}

export interface EditDataPointProfileProps {
  dataPointProfile: IDataPointProfile;
}

export function EditDataPointProfile({
  dataPointProfile,
}: EditDataPointProfileProps) {
  const navigate = useNavigate();
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IDataPointProfile>();
  const { hardwareTypes } = useHardwareTypes();
  const { updateDataPointProfile } = useSuperAdminDataPointProfileMutations();
  const { dataPointTransformations } = useSuperAdminDataPointTransformations(
    dataPointProfile.id,
  );
  const { dataPointOptions } = useSuperAdminDataPointOptions(
    dataPointProfile.id,
  );
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [readOnly, setReadOnly] = useState(false);
  const [addOptionOpen, setAddOptionOpen] = useState(false);
  const [addTransfromationOpen, setAddTransformationOpen] = useState(false);

  const handleUpdate = (data: any) => {
    updateDataPointProfile.mutate(
      {
        ...dataPointProfile,
        ...data,
        bit: data.bit !== "" ? data.bit : null,
        readOnly: readOnly,
      },
      {
        onSuccess: () => navigate("../"),
      },
    );
  };

  useEffect(() => {
    if (dataPointProfile) {
      setReadOnly(dataPointProfile.readOnly);
      reset({
        hardwareModelId: dataPointProfile.hardwareModelId,
        dataPointId: dataPointProfile.dataPointId,
        index: dataPointProfile.index,
        length: dataPointProfile.length,
        bit: dataPointProfile.bit,
        registerName: dataPointProfile.registerName,
        registerAddress: dataPointProfile.registerAddress,
        registerDetails: dataPointProfile.registerDetails,
        minimumValue: dataPointProfile.minimumValue,
        maximumValue: dataPointProfile.maximumValue,
        precision: dataPointProfile.precision,
        notes: dataPointProfile.notes,
        hardwareModel: dataPointProfile.hardwareModel,
      });
    } else if (globalHardwareModelFilter) {
      setReadOnly(false);
      reset({
        hardwareModel: globalHardwareModelFilter,
        hardwareModelId: globalHardwareModelFilter.id,
        length: 2,
      });
    } else {
      setReadOnly(false);
      reset({ length: 2 });
    }
  }, [
    reset,
    dataPointProfile,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <>
      {watch("dataPointId") && watch("hardwareModel") && (
        <CreateEditDataPointOption
          open={addOptionOpen}
          onClose={() => setAddOptionOpen(false)}
          prefill={{
            hardwareModel: watch("hardwareModel"),
            dataPointId: watch("dataPointId"),
          }}
        />
      )}
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(handleUpdate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareModel.hardwareManufacturer.hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareModel.hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("dataPointId");
              },
            })}
            type={watch("hardwareModel.hardwareManufacturer.hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("dataPointId");
              },
            })}
            manufacturer={watch("hardwareModel.hardwareManufacturerId")}
          />
          <DataPointSelect
            {...register("dataPointId")}
            type={watch("hardwareModel.hardwareManufacturer.hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={dataPointProfile?.dataPointId}
            filterUnused
          />
          <TextField {...register("index")} label="Index" />
          <TextField {...register("length")} label="Length" />
          <TextField {...register("bit")} label="Bit" />
          <div className="flex items-center">
            <Toggle
              className="mt-5"
              label="Read Only"
              checked={readOnly}
              onChange={setReadOnly}
            />
          </div>
          <TextField {...register("precision")} label="Precision" />
          <TextField {...register("minimumValue")} label="Minimum Value" />
          <TextField {...register("maximumValue")} label="Maximum Value" />
          <TextField {...register("registerName")} label="Register Name" />
          <TextField
            {...register("registerAddress")}
            label="Register Address"
          />
          <div className="col-span-2">
            <TextArea
              {...register("registerDetails")}
              label="Register Details"
              rows={2}
            />
          </div>
          <div className="col-span-2">
            <TextArea {...register("notes")} label="Notes" rows={4} />
          </div>
          <div className="mt-6 col-span-2">
            <p className="text-2xl">Transformations</p>
            {dataPointTransformations.isLoading ? (
              <SectionLoading />
            ) : (
              <table className="mt-4 min-w-full table border border-primary/40 divide-y divide-primary dark:border-primary">
                <thead className="bg-primary/40">
                  <tr>
                    <th className="px-3 py-3 text-left text-sm font-semibold">
                      Name
                    </th>
                    <th className="px-3 py-3 text-left text-sm font-semibold">
                      Arguments
                    </th>
                    <th className="px-3 py-3 text-left text-sm font-semibold">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary/40">
                  {dataPointTransformations.data
                    ?.sort((a, b) => a.order - b.order)
                    .map((transformation, i) => (
                      <TransformationRow
                        index={i}
                        key={transformation.id}
                        transformation={transformation}
                      />
                    ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="mt-6 col-span-2">
            <p className="text-2xl">Options</p>
            {dataPointOptions.isLoading ? (
              <SectionLoading />
            ) : (
              <>
                <table className="mt-4 min-w-full table border border-primary/40 divide-y divide-primary dark:border-primary">
                  <thead className="bg-primary/40">
                    <tr>
                      <th className="px-3 py-3 text-left text-sm font-semibold">
                        Value
                      </th>
                      <th className="px-3 py-3 text-left text-sm font-semibold">
                        Label
                      </th>
                      <th className="px-3 py-3 text-left text-sm font-semibold">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-primary/40">
                    {dataPointOptions.data?.map((option, i) => (
                      <OptionRow index={i} key={option.id} option={option} />
                    ))}
                  </tbody>
                </table>
                <div className="mt-2 space-x-4">
                  <Button size="sm" onClick={() => setAddOptionOpen(true)}>
                    Add Option
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">Update</Button>
          </div>
        </form>
      )}
    </>
  );
}
