import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  CheckIcon,
  FunnelIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Pill } from "../../../components/base/Pill";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { useNotification } from "../../../hooks/useNotifications";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import useHardwareModelsMutations from "../../../data/hardware/useHardwareModelMutations";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import { CreateEditHardwareModel } from "../../../components/Hardware/CreateEditHardwareModel";
import {
  HardwareModelClassification,
  HardwareModelClassificationColours,
  IHardwareManufacturer,
  IHardwareModel,
} from "../../../types/Hardware/Hardware";
import { GlobalHardwareModelFilterBanner } from "../../../components/shared/GlobalHardwareModelFilterBanner";
import { LoadingWheel } from "../../../components/base/LoadingWheel";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";

const colHelper = createColumnHelper<IHardwareModel>();

export function HardwareModelsPage() {
  const addNotification = useNotification();
  const { delHardwareModel, updateHardwareModel } =
    useHardwareModelsMutations();
  const { setGlobalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editHardwareModel, setEditHardwareModel] = useState<
    IHardwareModel | undefined
  >(undefined);
  const [deleteHardwareModel, setDeleteHardwareModel] = useState<
    IHardwareModel | undefined
  >(undefined);
  const { isLoading: isTypeLoading, typeLookup } =
    useSuperAdminHardwareLookups();
  const { isLoading: isHardwareLoading, manufacturerLookup } =
    useSuperAdminHardwareLookups();

  const handleDeleteHardwareModel = (hardwareModel: IHardwareModel) => {
    setDeleteHardwareModel(hardwareModel);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteHardwareModel = () => {
    delHardwareModel.mutate(deleteHardwareModel!);
    setDeleteOpen(false);
  };

  const handleRestoreHardwareModel = (hardwareModel: IHardwareModel) => {
    updateHardwareModel.mutate({ ...hardwareModel, restore: true });
  };

  const handleEditHardwareModel = (hardwareModel: IHardwareModel) => {
    setEditHardwareModel(hardwareModel);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editHardwareModel && setTimeout(() => setEditHardwareModel(undefined), 200);
    setEditOpen(false);
  };

  const handleSetModelFilter = (hardwareModel: IHardwareModel) => {
    hardwareModel.hardwareManufacturer = manufacturerLookup(
      hardwareModel.hardwareManufacturerId,
    ) as IHardwareManufacturer;
    setGlobalHardwareModelFilter({ type: "set", payload: hardwareModel });
    addNotification(
      "success",
      `Global Filter set to ${hardwareModel.hardwareManufacturer.name} ${hardwareModel.name}`,
    );
  };

  const cols = [
    colHelper.display({
      header: "Status",
      cell: (info) =>
        info.row.original.disabled ? (
          <XMarkIcon className="w-5 h-5 opacity-70" />
        ) : (
          <CheckIcon className="w-5 h-5 opacity-70" />
        ),
    }),
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" },
    ),
    colHelper.accessor("name", { header: "Model" }),
    colHelper.display({
      header: "Classification",
      cell: (info) => (
        <>
          {info.row.original.classification !== 0 ? (
            <Pill
              className="min-w-[80px]"
              label={
                HardwareModelClassification[info.row.original.classification]
              }
              colour={
                HardwareModelClassificationColours[
                  info.row.original.classification
                ]
              }
            />
          ) : (
            ""
          )}
          {info.row.original.partial ? (
            <Pill className="min-w-[80px]" label="Partial" colour="C6C6C6" />
          ) : (
            ""
          )}
        </>
      ),
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreHardwareModel(info.row.original)}
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditHardwareModel(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteHardwareModel(info.row.original)}
              />
              <FunnelIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleSetModelFilter(info.row.original)}
                title={`Set global hardware filter to ${info.row.original.name}`}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditHardwareModel
        open={editOpen}
        onClose={handleCreateEditClose}
        hardwareModel={editHardwareModel}
      />
      <ConfirmDelete
        itemName={deleteHardwareModel?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteHardwareModel()}
      />
      <Card title="Hardware Models">
        <div className="my-6">
          <GlobalHardwareModelFilterBanner />
        </div>
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Model</Button>
        </div>
        <div>
          {isTypeLoading || isHardwareLoading ? (
            <LoadingWheel />
          ) : (
            <Table<IHardwareModel>
              url="/superadmin/hardware/models"
              queryKey={["superadmin", "hardware", "models"]}
              columns={cols}
              enableShowDeleted
            />
          )}
        </div>
      </Card>
    </>
  );
}
