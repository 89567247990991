import { useQuery } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";

import { IDataPoint } from "../../../types/DataPoint/DataPoint";
import { IDefaultResponseWithData } from "../../../types/system/DefaultResponse";

export default function useSuperAdminDataPoints(hardwareType?: string) {
  const { get } = useApiHelper();

  const dataPoints = useQuery({
    queryKey: ["superadmin", "datapoints", hardwareType],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPoint>>(
        `/v1/superadmin/datapoint/${hardwareType}`,
      ).then((res) => res.data.data),
  });

  return {
    dataPoints,
  };
}
