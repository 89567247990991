import React, { useEffect, useState } from "react";

import { Select } from "../base/Select";
import { LoadingWheel } from "../base/LoadingWheel";

import useSuperAdminDataPoints from "../../data/datapoint/superadmin/useSuperAdminDataPoints";
import useSuperAdminDataPointProfiles from "../../data/datapoint/superadmin/useSuperAdminDataPointProfiles";

import { IDataPoint } from "../../types/DataPoint/DataPoint";

export interface DataPointSelectProps {
  type: string;
  model: string;
  selected?: string;
  filterForActionProfiles?: boolean;
  filterUnused?: boolean;
  filterUsed?: boolean;
  label?: string;
}

export const DataPointSelect = React.forwardRef<any, DataPointSelectProps>(
  (
    {
      type,
      model,
      selected,
      filterForActionProfiles,
      filterUnused,
      filterUsed,
      label = "Data Point",
      ...rest
    },
    ref,
  ) => {
    const { dataPoints: allDataPoints } = useSuperAdminDataPoints(type);
    const { dataPointProfiles } = useSuperAdminDataPointProfiles(model);
    const [dataPoints, setDataPoints] = useState<IDataPoint[] | undefined>(
      undefined,
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (
        !allDataPoints.isLoading &&
        !dataPointProfiles.isLoading &&
        allDataPoints.data &&
        dataPointProfiles.data
      ) {
        let currentDataPoint;
        let filteredDataPoints;

        if (filterForActionProfiles) {
          // Filter where datapoint profile exists & datapoint profile isn't readonly
          filteredDataPoints = allDataPoints.data.filter((dataPoint) =>
            dataPointProfiles.data?.some(
              (decodeProfile) =>
                decodeProfile.dataPointId === dataPoint.id &&
                !decodeProfile.readOnly,
            ),
          );
        } else if (filterUnused) {
          filteredDataPoints = allDataPoints.data.filter(
            (dataPoint) =>
              !dataPointProfiles.data?.some(
                (decodeProfile) => decodeProfile.dataPointId === dataPoint.id,
              ),
          );
        } else if (filterUsed) {
          filteredDataPoints = allDataPoints.data.filter((dataPoint) =>
            dataPointProfiles.data?.some(
              (decodeProfile) => decodeProfile.dataPointId === dataPoint.id,
            ),
          );
        } else {
          filteredDataPoints = allDataPoints.data;
        }

        if (selected) {
          currentDataPoint = allDataPoints.data.find(
            (dataPoint) => dataPoint.id === selected,
          );

          currentDataPoint && filteredDataPoints.push(currentDataPoint);
        }

        filteredDataPoints.sort((a, b) => a.name.localeCompare(b.name));

        setDataPoints(filteredDataPoints);
        setLoading(false);
      }
    }, [
      type,
      model,
      selected,
      filterUnused,
      filterUsed,
      allDataPoints.isLoading,
      allDataPoints.data,
      dataPointProfiles.isLoading,
      dataPointProfiles.data,
      filterForActionProfiles,
    ]);

    if (allDataPoints.isLoading || dataPointProfiles.isLoading || loading) {
      return (
        <div className="flex items-center justify-center">
          <LoadingWheel size="sm" />
        </div>
      );
    } else {
      return (
        <Select disabled={!type || !model} ref={ref} label={label} {...rest}>
          <option disabled hidden selected>
            Please Select
          </option>
          <option value={""}>None</option>
          {dataPoints?.map((dataPoint) => (
            <option key={dataPoint.id} value={dataPoint.id}>
              {dataPoint.name}{" "}
              {dataPoint.unit !== "" ? `(${dataPoint.unit})` : ""}
            </option>
          ))}
        </Select>
      );
    }
  },
);
