import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";

import useApiHelper from "../../hooks/useApiHelper";
import { Button } from "../../components/base/Button";
import { applicationId, authAPIURL } from "../../config";
import { TextField } from "../../components/base/TextField";
import { useUserContext } from "../../contexts/UserContext";
import { Alert, AlertProps } from "../../components/base/Alert";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { SSOPasswordResetFormFields } from "../../types/requests/sso/SSOPasswordResetRequest";

export function ForgottenPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: false,
    addToken: false,
  });
  const { loading, isLoggedIn } = useUserContext();
  const { register, handleSubmit } = useForm<SSOPasswordResetFormFields>({
    values: {
      email: location.state && location.state.email ? location.state.email : "",
    },
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<AlertProps | undefined>();
  const [success, setSuccess] = useState(false);

  const handleResetRequest = async (
    resetDetails: SSOPasswordResetFormFields,
  ) => {
    setSubmitting(true);

    try {
      let resetResponse = await post("/v1/sso/password/request", {
        ...resetDetails,
        applicationId: applicationId,
      });

      if (resetResponse.status === 200) {
        setSuccess(true);
      } else {
        throw Error("An unknown error has occurred");
      }
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError({ severity: "error", message: err.response.data.message });
      } else {
        setError({
          severity: "error",
          message: "An unknown error has occurred",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!loading && isLoggedIn) {
      navigate("/");
    }
  }, [loading, isLoggedIn, navigate]);

  return (
    <div className="w-10/12 xl:w-8/12 mx-auto">
      {submitting ? (
        <SectionLoading />
      ) : (
        <>
          <div className="mb-6 text-4xl font-medium dark:text-white">
            Password Reset
          </div>
          {error && <Alert {...error} className="mb-6" />}
          {success && (
            <Alert
              className="mb-6"
              severity="success"
              title="Reset Request Submitted!"
              message="Your request has been submitted! If we find a match to our records you will receive an email containing instructions to reset your password shortly"
            />
          )}
          {!success ? (
            <form onSubmit={handleSubmit(handleResetRequest)}>
              <div className="space-y-2">
                <TextField
                  {...register("email")}
                  fullWidth
                  placeholder="Email"
                />
              </div>
              <div className="space-y-4 mt-6">
                <Button fullWidth type="submit">
                  Request Reset
                </Button>
              </div>
            </form>
          ) : (
            <Button fullWidth onClick={() => navigate("/login")}>
              Back to Login
            </Button>
          )}
        </>
      )}
    </div>
  );
}
