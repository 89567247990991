import { Dispatch, SetStateAction, useEffect } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Modal } from "../../layouts/Modal";
import { TextArea } from "../base/TextArea";
import { ActionSelect } from "./ActionSelect";
import { TextField } from "../base/TextField";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import { IActionProfile, InputType } from "../../types/Action/ActionProfile";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import useSuperAdminActionProfileMutations from "../../data/action/useSuperAdminActionProfileMutation";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import { GlobalHardwareModelFilterBanner } from "../shared/GlobalHardwareModelFilterBanner";
import { DataPointSelect } from "../DataPoint/DataPointSelect";

export interface CreateEditActionProfileProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  actionProfile?: IActionProfile;
}

export function CreateEditActionProfile({
  open,
  onClose,
  actionProfile,
}: CreateEditActionProfileProps) {
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IActionProfile>();
  const { hardwareTypes } = useHardwareTypes();
  const { createActionProfile, updateActionProfile } =
    useSuperAdminActionProfileMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createActionProfile.mutate({
      ...data,
      dataPointId: data.dataPointId === "" ? null : data.dataPointId,
      bit: data.bit !== "" ? data.bit : null,
      inputType: parseInt(data.inputType),
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateActionProfile.mutate({
      ...actionProfile,
      ...data,
      dataPointId: data.dataPointId === "" ? null : data.dataPointId,
      bit: data.bit !== "" ? data.bit : null,
      inputType: parseInt(data.inputType),
    });
    handleClose();
  };

  const inputTypes = [
    ...Object.values(InputType).filter((ct) => isNaN(Number(ct))),
  ];

  useEffect(() => {
    if (actionProfile) {
      reset({
        hardwareTypeId: actionProfile.hardwareTypeId,
        hardwareManufacturerId: actionProfile.hardwareManufacturerId,
        hardwareModelId: actionProfile.hardwareModelId,
        actionId: actionProfile.actionId,
        dataPointId: actionProfile.dataPointId,
        hardwareModel: actionProfile.hardwareModel,
        command: actionProfile.command,
        responseLength: actionProfile.responseLength,
        responseTimeout: actionProfile.responseTimeout,
        inputType: actionProfile.inputType,
        step: actionProfile.step,
      });
    } else if (globalHardwareModelFilter) {
      reset({
        hardwareModelId: globalHardwareModelFilter.id,
        hardwareManufacturerId:
          globalHardwareModelFilter.hardwareManufacturerId,
        hardwareTypeId: globalHardwareModelFilter.hardwareTypeId,
        command: "AT+WSAO={{a}},{{v}}",
        responseTimeout: 0,
        responseLength: 0,
      });
    } else {
      reset({
        command: "AT+WSAO={{a}},{{v}}",
        responseTimeout: 0,
        responseLength: 0,
      });
    }
  }, [
    reset,
    open,
    actionProfile,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${actionProfile ? "Edit" : "Create"} Action Profile`}
      description={`Use this form to ${
        actionProfile ? "edit" : "create"
      } an action profile`}
    >
      <GlobalHardwareModelFilterBanner />
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(actionProfile ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("actionId");
              },
            })}
            type={watch("hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("actionId");
              },
            })}
            manufacturer={watch("hardwareManufacturerId")}
          />
          <ActionSelect
            {...register("actionId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={actionProfile?.actionId}
            filter
          />
          <DataPointSelect
            {...register("dataPointId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={actionProfile?.dataPointId}
            filterForActionProfiles
          />
          <div className="col-span-2">
            <TextArea {...register("command")} label="Command" rows={4} />
          </div>
          <TextField {...register("responseLength")} label="Response Length" />
          <TextField
            {...register("responseTimeout")}
            label="Response Timeout"
          />
          <Select label="Input Type" {...register("inputType")}>
            {inputTypes.map((type, i) => (
              <option key={type} value={i}>
                {String(type)}
              </option>
            ))}
          </Select>
          <TextField {...register("step")} label="Step" step="any" />
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">{actionProfile ? "Update" : "Create"}</Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
