import { useState } from "react";

import { AxiosError } from "axios";

import { Alert } from "../base/Alert";
import { Button } from "../base/Button";
import { Modal } from "../../layouts/Modal";
import { ICustomer } from "../../types/Customer/Customer";
import { SectionLoading } from "../shared/SectionLoading";
import { IDefaultResponse } from "../../types/system/DefaultResponse";
import { useCustomerMutations } from "../../data/customer/useCustomerMutations";

export interface ConfirmDeleteProps {
  customer?: ICustomer;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function UnlinkCustomer({
  customer,
  open,
  onCancel,
  onConfirm,
}: ConfirmDeleteProps) {
  const { deleteCustomer } = useCustomerMutations();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      setSubmitting(false);
      setError(undefined);
    }, 200);
  };

  const handleConfirm = () => {
    setSubmitting(true);

    deleteCustomer.mutate(
      { ...customer! },
      {
        onSuccess: () => onConfirm(),
        onError: (err) =>
          setError(
            (err as AxiosError<IDefaultResponse>).response?.data?.message,
          ),
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Confirm Unlink"
      description={`Are you sure you want to unlink ${customer?.organisationName}?`}
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      {submitting ? (
        <SectionLoading />
      ) : error !== undefined ? (
        <div className="text-left">
          <Alert severity="error" title="Unlink Error" message={error} />
        </div>
      ) : (
        <>
          <div className="my-6 mb-10 flex flex-col items-center justify-center space-y-4">
            <p>
              When unlinking a customer you relinquish all visibility and
              control of the customers devices. The devices will also be removed
              from your subscription if they are currently assigned a tier.
            </p>
          </div>
        </>
      )}
      <div className="mt-6 space-x-4">
        <Button disabled={error !== undefined} onClick={() => handleConfirm()}>
          Confirm
        </Button>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
