import { useState } from "react";

import { FieldName } from "react-hook-form";

import { Button } from "../../base/Button";
import { Form } from "../../base/Form/Form";
import { LoadingWheel } from "../../base/LoadingWheel";
import { FormTextField } from "../../base/Form/FormTextField";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { IOrganisationTariff } from "../../../types/Organisation/Organisation";
import useOrganisationTariffsMutations from "../../../data/organisation/useOrganisationTariffsMutations";

interface IOrganisationTariffFormFields extends Partial<IOrganisationTariff> {}

interface TariffUpdateProps extends ModalControlProps {
  fieldName: FieldName<IOrganisationTariff>;
  currentTariffs?: IOrganisationTariff;
}

export function TariffUpdate({
  open,
  onClose,
  fieldName,
  currentTariffs,
}: TariffUpdateProps) {
  const { updateTariffs } = useOrganisationTariffsMutations();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data: IOrganisationTariffFormFields) => {
    setSubmitting(true);
    updateTariffs.mutate(
      { ...currentTariffs, ...data },
      {
        onSettled: () => {
          onClose(true);
          setSubmitting(false);
        },
      },
    );
  };

  return (
    <Modal title="Update Tariff" open={open} onClose={onClose}>
      {submitting ? (
        <div className="py-8 flex justify-center">
          <LoadingWheel />
        </div>
      ) : (
        <Form<IOrganisationTariffFormFields>
          defaultValues={{
            [fieldName]: currentTariffs ? currentTariffs[fieldName] : 0,
          }}
          onSubmit={onSubmit}
          className="mt-4 grid grid-cols-2"
        >
          {({ register }) => (
            <>
              <FormTextField
                {...register(fieldName, { required: true })}
                label="Price £/kWh"
              />
              <div className="mt-6 sm:col-span-2 space-x-4">
                <Button type="submit">Update</Button>
                <Button variant="outlined" onClick={() => onClose(true)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
