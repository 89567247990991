import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { actionProfileQueryKeys } from "../QueryKeys";
import { IActionProfile } from "../../types/Action/ActionProfile";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminActionProfileMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Action Profile");

  const createActionProfile = useMutation({
    mutationKey: actionProfileQueryKeys.create(),
    mutationFn: (actionProfile) =>
      post("/v1/superadmin/action/profile", actionProfile),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () =>
      queryClient.invalidateQueries(actionProfileQueryKeys.superAdminAll()),
  });

  const updateActionProfile = useMutation({
    mutationKey: actionProfileQueryKeys.update(),
    mutationFn: (actionProfile: IActionProfile) =>
      put<IActionProfile>(
        `/v1/superadmin/action/profile/${actionProfile.id}`,
        actionProfile,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () =>
      queryClient.invalidateQueries(actionProfileQueryKeys.superAdminAll()),
  });

  const delActionProfile = useMutation({
    mutationKey: actionProfileQueryKeys.delete(),
    mutationFn: (actionProfile: IActionProfile) =>
      del(`/v1/superadmin/action/profile/${actionProfile.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () =>
      queryClient.invalidateQueries(actionProfileQueryKeys.superAdminAll()),
  });

  return {
    createActionProfile,
    updateActionProfile,
    delActionProfile,
  };
}
