import useSuperAdminHardwareManufacturers from "../data/hardware/useSuperAdminHardwareManufacturers";
import useSuperAdminHardwareModels from "../data/hardware/useSuperAdminHardwareModels";
import useSuperAdminHardwareTypes from "../data/hardware/useSuperAdminHardwareTypes";

const useSuperAdminHardwareTypeLookup = () => {
  const { hardwareTypes } = useSuperAdminHardwareTypes();

  const lookup = (typeId: string) =>
    hardwareTypes.data?.find((m) => m.id === typeId);

  return {
    isLoading: hardwareTypes.isLoading,
    lookup,
  };
};

const useSuperAdminHardwareManufacturerLookup = () => {
  const { hardwareManufacturers } = useSuperAdminHardwareManufacturers();

  const lookup = (manufacturerId: string) =>
    hardwareManufacturers.data?.find((m) => m.id === manufacturerId);

  return {
    isLoading: hardwareManufacturers.isLoading,
    lookup,
  };
};

const useSuperAdminHardwareModelLookup = () => {
  const { hardwareModels } = useSuperAdminHardwareModels();

  const lookup = (modelId: string) =>
    hardwareModels.data?.find((m) => m.id === modelId);

  return {
    isLoading: hardwareModels.isLoading,
    lookup,
  };
};

export const useSuperAdminHardwareLookups = () => {
  const { isLoading: typesLoading, lookup: typeLookup } =
    useSuperAdminHardwareTypeLookup();

  const { isLoading: manufacturersLoading, lookup: manufacturerLookup } =
    useSuperAdminHardwareManufacturerLookup();

  const { isLoading: modelsLoading, lookup: modelLookup } =
    useSuperAdminHardwareModelLookup();

  const isLoading = manufacturersLoading || typesLoading || modelsLoading;

  return {
    isLoading,
    typeLookup,
    manufacturerLookup,
    modelLookup,
  };
};
