import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IAction } from "../../types/Action/Action";
import { actionQueryKeys } from "../QueryKeys";

export default function useSuperAdminActions() {
  const { get } = useApiHelper();

  const actions = useQuery({
    queryKey: actionQueryKeys.superAdminAll(),
    queryFn: () =>
      get<IDefaultResponseWithData<IAction>>("/v1/superadmin/action").then(
        (res) => res.data.data,
      ),
  });

  return {
    actions,
  };
}
