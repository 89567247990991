import { useMutation } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IBranding } from "../../types/Branding/IBranding";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export function useBrandingMutations() {
  const { put } = useApiHelper();
  const {
    handleSuccess: handleBrandingSuccess,
    handleError: handleBrandingError,
  } = useDefaultMutateResponseHandlers("Branding");
  const { handleSuccess: handleLogoSuccess, handleError: handleLogoError } =
    useDefaultMutateResponseHandlers("Logo");

  const updateBranding = useMutation({
    mutationFn: (branding: Omit<IBranding, "logo">) =>
      put("/v1/brand", branding),
    onError: (error) => handleBrandingError(error, "update"),
    onSuccess: (data) => handleBrandingSuccess(data, "update"),
  });

  const updateLogo = useMutation({
    mutationFn: (logo: FormData) =>
      put("/v1/brand/logo", logo, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    onError: (error) => handleLogoError(error, "update"),
    onSuccess: (data) => handleLogoSuccess(data, "update"),
  });

  return {
    updateBranding,
    updateLogo,
  };
}
