import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useReducer,
} from "react";

import { IHardwareModel } from "../types/Hardware/Hardware";
import { Outlet } from "react-router";

interface GlobalHardwareModelFilterContextValue {
  hardware: IHardwareModel | undefined;
  dispatch: Dispatch<GlobalHardwareModelFilterContextActions>;
}

const GlobalHardwareModelFilterContext =
  createContext<GlobalHardwareModelFilterContextValue>({
    hardware: undefined,
    dispatch: () => {},
  });

interface GlobalHardwareModelFilterContextProviderProps {
  children: ReactElement | ReactElement[];
}

function globalHardwareModelFilterReducer(
  state: IHardwareModel | undefined,
  action: GlobalHardwareModelFilterContextActions,
): IHardwareModel | undefined {
  switch (action.type) {
    case "set":
      return action.payload;
    case "reset":
      return undefined;
    default:
      console.error("Unhandled action passed to globalHardwareFilterReducer");
      return state;
  }
}

type GlobalHardwareModelFilterContextActions =
  | {
      type: "set";
      payload: IHardwareModel | undefined;
    }
  | {
      type: "reset";
    };

export function GlobalHardwareModelFilterProvider() {
  const [hardware, dispatch] = useReducer(
    globalHardwareModelFilterReducer,
    undefined,
  );

  return (
    <GlobalHardwareModelFilterContext.Provider value={{ hardware, dispatch }}>
      <Outlet />
    </GlobalHardwareModelFilterContext.Provider>
  );
}

export function useGlobalHardwareModelFilter() {
  const globalHardwareModelCtx = useContext(GlobalHardwareModelFilterContext);

  if (!globalHardwareModelCtx) {
    throw Error(
      "useGlobalHardwareFilter() must be used within a GlobalHardwareFilterProvider",
    );
  }

  return {
    globalHardwareModelFilter: globalHardwareModelCtx.hardware,
    setGlobalHardwareModelFilter: globalHardwareModelCtx.dispatch,
  };
}
