import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";

import ReactCrop, { Crop, PixelCrop } from "react-image-crop";

import { Button } from "../../base/Button";
import { Modal } from "../../../layouts/Modal";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { useTheme } from "../../../contexts/ThemeContext";
import { SectionLoading } from "../../shared/SectionLoading";
import { useBrandingMutations } from "../../../data/branding/useBrandingMutations";

import "react-image-crop/dist/ReactCrop.css";

interface LogoCropProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

function readFile(file: File) {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export function LogoCrop({ open, onClose }: LogoCropProps) {
  const {
    theme: { logo },
    setTheme,
  } = useTheme();
  const { updateLogo } = useBrandingMutations();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>();
  const [imageFileName, setImageFileName] = useState<string>();
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    setTimeout(() => {
      setImageSrc(undefined);
      setCrop(undefined);
      setCompletedCrop(undefined);
    }, 200);
    onClose(false);
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const fileName = e.currentTarget.files[0].name;
      const imageDataUrl = await readFile(e.currentTarget.files[0]);

      setImageFileName(fileName);
      setImageSrc(imageDataUrl!);
    }
  };

  const onCropClick = () => {
    setUploading(true);

    if (!previewCanvasRef.current) {
      setUploading(false);
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        setUploading(false);
        throw new Error("Failed to create blob");
      }

      const formData = new FormData();

      formData.append("logo", blob, "image.png");

      updateLogo.mutate(formData, {
        onSuccess: () => {
          setTheme({ type: "logo", payload: logo + `?rid=${Date.now()}` });
          setUploading(false);
          handleClose();
        },
        onError: () => setUploading(false),
      });
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Modal title="Upload Logo" open={open} onClose={onClose}>
      {uploading ? (
        <SectionLoading />
      ) : (
        <>
          <div className="my-8 space-x-4 flex items-center">
            <label className="rounded px-3 py-2 text-sm bg-primary/80 text-primaryContrast border border-primary/80 hover:bg-primary hover:border-primary active:border-primary/90 active:bg-primary/90">
              Upload File
              <input
                className="hidden"
                type="file"
                onChange={onFileChange}
                accept="image/*"
              />
            </label>
            <p>{imageFileName}</p>
          </div>
          {imageSrc && (
            <div className="m-4 mt-8 flex justify-center">
              <ReactCrop
                minHeight={50}
                minWidth={125}
                className="mx-auto"
                crop={crop}
                onComplete={(c) => setCompletedCrop(c)}
                onChange={(c) => setCrop(c)}
                style={{
                  maxHeight: "28rem",
                }}
              >
                <img ref={imgRef} src={imageSrc as string} alt="" />
              </ReactCrop>
            </div>
          )}
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
          <div className="mt-6 sm:col-span-2 space-x-4">
            {completedCrop && <Button onClick={onCropClick}>Upload</Button>}
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
