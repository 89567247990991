import { useEffect, useState } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { CreateEditUser } from "../User/CreateEditUser";

import { Pill } from "../base/Pill";
import { Button } from "../base/Button";

import useOrganisation from "../../data/organisation/useOrganisation";
import useOrganisationUsers from "../../data/organisationUsers/useOrganisationUsers";
import useOrganisationInvites from "../../data/organisationUsers/useOrganisationInvites";
import useOrganisationUsersMutations from "../../data/organisationUsers/useOrganisationUsersMutations";
import useOrganisationInvitesMutations from "../../data/organisationUsers/useOrganisationInvitesMutations copy";

import { Avatar } from "../shared/Avatar";
import { ConfirmDelete } from "../shared/ConfirmDelete";
import { SectionLoading } from "../shared/SectionLoading";

import { IUser } from "../../types/User/User";
import { IOrganisation } from "../../types/Organisation/Organisation";
import { Alert } from "../base/Alert";

interface UserRowProps {
  user: IUser;
  organisation?: IOrganisation;
  onDelete: (user: IUser) => void;
  onEdit: (user: IUser) => void;
}

function UserRow({ user, organisation, onDelete, onEdit }: UserRowProps) {
  return (
    <div
      key={user.id}
      className="relative flex items-center space-x-3 px-6 py-5"
    >
      <div className="flex-shrink-0">
        <Avatar {...user} />
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
          {user.forename} {user.surname}
        </p>
        <p className="truncate text-sm text-gray-500">{user.email}</p>
      </div>
      <div>
        {organisation && organisation.adminRole === user.roleId ? (
          <Pill label={"admin"} colour={"dc2626"} />
        ) : (
          <Pill label={"read only"} colour={"15803d"} />
        )}
      </div>
      <div className="block">
        <PencilIcon
          className="w-6 h-6 opacity-70 hover:opacity-100 hover:cursor-pointer"
          onClick={() => onEdit(user)}
        />
      </div>
      <div className="block">
        <TrashIcon
          className="w-6 h-6 opacity-70 hover:opacity-100 hover:cursor-pointer"
          onClick={() => onDelete(user)}
        />
      </div>
    </div>
  );
}

export function UsersTab() {
  const { users } = useOrganisationUsers();
  const { invites } = useOrganisationInvites();
  const { delInvite } = useOrganisationInvitesMutations();
  const { delUser } = useOrganisationUsersMutations();
  const { organisation } = useOrganisation();
  const [isInvite, setIsInvite] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editUser, setEditUser] = useState<IUser | undefined>(undefined);
  const [deleteUser, setDeleteUser] = useState<IUser | undefined>(undefined);

  const handleDeleteUser = (user: IUser) => {
    setIsInvite(false);
    setDeleteUser(user);
    setDeleteOpen(true);
  };

  const handleDeleteInvite = (user: IUser) => {
    setIsInvite(true);
    setDeleteUser(user);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteUser = () => {
    if (isInvite) {
      delInvite.mutate(deleteUser!);
    } else {
      delUser.mutate(deleteUser!);
    }
    setDeleteOpen(false);
  };

  const handleEditUser = (user: IUser) => {
    setIsInvite(false);
    setEditUser(user);
    setEditOpen(true);
  };

  const handleEditInvite = (user: IUser) => {
    setIsInvite(true);
    setEditUser(user);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editUser && setTimeout(() => setEditUser(undefined), 200);
    setEditOpen(false);
  };

  useEffect(() => {}, [
    users.isLoading,
    users.data,
    invites.isLoading,
    invites.data,
  ]);

  return (
    <>
      <CreateEditUser
        open={editOpen}
        user={editUser}
        invite={isInvite}
        onClose={handleCreateEditClose}
      />
      <ConfirmDelete
        itemName={`${deleteUser?.forename} ${deleteUser?.surname}`}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteUser()}
      />
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-2xl font-semibold leading-7 text-primary">
            Users
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
            Add new users, or edit existing users.
          </p>
          <div className="mt-6 py-4 space-y-6 border-t border-gray-400 dark:border-gray-500 ">
            <Alert
              severity="info"
              message="Admins (unlike Read-Only users) are able to invite Users to the Organisation, in addition to using Actions."
            />
            <div>
              <Button onClick={() => setEditOpen(true)}>Add User</Button>
            </div>
            {users.isLoading ? (
              <SectionLoading />
            ) : (
              <ul className="rounded border divide-y bg-gray-100 divide-gray-400 border-gray-400 dark:bg-gray-700 dark:divide-gray-600 dark:border-gray-600">
                {users.data?.map((user) => (
                  <UserRow
                    user={user}
                    organisation={organisation.data}
                    onDelete={handleDeleteUser}
                    onEdit={handleEditUser}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>

        {!invites.isLoading && invites.data && invites.data.length > 0 ? (
          <div>
            <h2 className="text-2xl font-semibold leading-7 text-primary">
              Pending
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
              Users that have been invited but are currently pending
            </p>
            <div className="mt-6 py-4 space-y-6 border-t border-gray-400 dark:border-gray-500 ">
              <ul className="rounded border divide-y bg-gray-100 divide-gray-400 border-gray-400 dark:bg-gray-700 dark:divide-gray-600 dark:border-gray-600">
                {invites.data?.map((invite) => (
                  <UserRow
                    user={invite}
                    organisation={organisation.data}
                    onDelete={handleDeleteInvite}
                    onEdit={handleEditInvite}
                  />
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
