import { Dispatch } from "react";
import { LiveReadingsViewAs } from "../../pages/LiveView";
import { Select } from "../base/Select";

interface ViewAsSelectionProps {
  viewAsOptions: Partial<Record<LiveReadingsViewAs, string>>[];
  viewAs: LiveReadingsViewAs;
  setViewAs: Dispatch<LiveReadingsViewAs>;
}

export function ViewAsSelection({
  viewAsOptions,
  viewAs,
  setViewAs,
}: ViewAsSelectionProps) {
  if (viewAsOptions.length > 0) {
    return (
      <div className="relative">
        <div className="w-56 flex flex-row justify-end md:justify-start items-center space-x-4">
          <p>View As</p>
          <Select
            value={viewAs}
            onChange={(e) =>
              setViewAs(e.currentTarget.value as LiveReadingsViewAs)
            }
          >
            {viewAsOptions.map((vo) => {
              let key = Object.keys(vo)[0] as LiveReadingsViewAs;

              return (
                <option key={key} value={key}>
                  {vo[key]}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
