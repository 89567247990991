import { useMutation, useQueryClient } from "react-query";

import { actionQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IAction } from "../../types/Action/Action";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminActionMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware Type");

  const createAction = useMutation({
    mutationKey: actionQueryKeys.create(),
    mutationFn: (action) => post("/v1/superadmin/action", action),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(actionQueryKeys.superAdminAll());
    },
  });

  const updateAction = useMutation({
    mutationKey: actionQueryKeys.update(),
    mutationFn: (action: IAction) =>
      put<IAction>(`/v1/superadmin/action/${action.id}`, action),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(actionQueryKeys.superAdminAll());
    },
  });

  const delAction = useMutation({
    mutationKey: actionQueryKeys.delete(),
    mutationFn: (action: IAction) => del(`/v1/superadmin/action/${action.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(actionQueryKeys.superAdminAll());
    },
  });

  return {
    createAction,
    updateAction,
    delAction,
  };
}
