import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";

import { useDefaultMutateResponseHandlers } from "../../../hooks/useDefaultMutateResponeHandlers";
import { IDataPointProfile } from "../../../types/DataPoint/DataPointProfile";

export default function useSuperAdminDataPointProfileMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Data Point Profile");

  const createDataPointProfile = useMutation({
    mutationFn: (dataPointProfile) =>
      post("/v1/superadmin/datapoint/profile", dataPointProfile),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () =>
      queryClient.invalidateQueries(["superadmin", "datapoint", "profiles"]),
  });

  const updateDataPointProfile = useMutation({
    mutationFn: (dataPointProfile: IDataPointProfile) =>
      put<IDataPointProfile>(
        `/v1/superadmin/datapoint/profile/${dataPointProfile.id}`,
        dataPointProfile,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () =>
      queryClient.invalidateQueries(["superadmin", "datapoint", "profiles"]),
  });

  const delDataPointProfile = useMutation({
    mutationFn: (dataPointProfile: IDataPointProfile) =>
      del(`/v1/superadmin/datapoint/profile/${dataPointProfile.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () =>
      queryClient.invalidateQueries(["superadmin", "datapoint", "profiles"]),
  });

  return {
    createDataPointProfile,
    updateDataPointProfile,
    delDataPointProfile,
  };
}
