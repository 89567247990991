import { useNavigate } from "react-router";
import { Button } from "../../components/base/Button";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <main className="h-screen w-screen grid min-h-full place-items-center bg-backdrop px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-3xl font-semibold text-primary">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-black dark:text-white sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-400">
            Sorry, we couldn't find the page you're looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button onClick={() => navigate("/")}>Go back home</Button>
          </div>
        </div>
      </main>
    </>
  );
}
