export default function RGBtoHEX(
  r: number | string,
  g: number | string,
  b: number | string,
): string {
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexR = componentToHex(parseInt(r as string));
  const hexG = componentToHex(parseInt(g as string));
  const hexB = componentToHex(parseInt(b as string));

  return "#" + hexR + hexG + hexB;
}
