import React, { useEffect } from "react";

import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./App";
import { isLocal } from "./config";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { ErrorFallback } from "./ErrorFallback";

function getSentryEnv() {
  if (isLocal) {
    return "LOCAL";
  } else {
    return process.env.REACT_APP_RUN_ENV;
  }
}

Sentry.init({
  dsn: "https://ff95791565cff62d837c90051fc52a2c@o4505409293058048.ingest.us.sentry.io/4507106891661312",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Set env for better filtering
  environment: getSentryEnv(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
