import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Button } from "../base/Button";
import { Select } from "../base/Select";
import { Toggle } from "../base/Toggle";
import { Modal } from "../../layouts/Modal";
import { ModelSelect } from "./ModelSelect";
import { TextField } from "../base/TextField";
import { SectionLoading } from "../shared/SectionLoading";
import { ManufacturerSelect } from "./ManufacturerSelect";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import useHardwareModels from "../../data/hardware/useHardwareModel";
import useHardwareModelsMutations from "../../data/hardware/useHardwareModelMutations";
import {
  BaudRateOptions,
  DataBitsOptions,
  HardwareModelClassification,
  HardwareModelEndianness,
  IHardwareModel,
  ParityOptions,
  StopBitsOptions,
} from "../../types/Hardware/Hardware";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

export interface CreateEditHardwareModelProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  hardwareModel?: IHardwareModel;
}

export function CreateEditHardwareModel({
  open,
  onClose,
  hardwareModel,
}: CreateEditHardwareModelProps) {
  const { register, reset, handleSubmit, watch, resetField } = useForm();
  const { hardwareTypes } = useHardwareTypes();
  const { hardwareModels } = useHardwareModels();
  const { createHardwareModel, updateHardwareModel } =
    useHardwareModelsMutations();
  const [checked, setChecked] = useState(true);
  const [partial, setPartial] = useState(false);
  const [actionProfiles, setActionProfiles] = useState(true);
  const [actionTransformations, setActionTransformations] = useState(true);
  const [dataProfiles, setDataProfiles] = useState(true);
  const [dataOptions, setDataOptions] = useState(true);
  const [dataTransformations, setDataTransformations] = useState(true);

  const hardwareModelClassifications = [
    ...Object.values(HardwareModelClassification).filter((ct) =>
      isNaN(Number(ct)),
    ),
  ];

  const hardwareModelEndiannesses = [
    ...Object.values(HardwareModelEndianness)
      .filter((e) => isNaN(Number(e)))
      .map((endianess, i) => ({ label: endianess, value: i })),
  ];

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createHardwareModel.mutate({
      ...data,
      serialSettings: {
        ...data.serialSettings,
        parity: parseInt(data.serialSettings.parity),
      },
      classification: parseInt(data.classification),
      disabled: checked,
      partial: partial,
      hardwareModelId:
        data.hardwareModelId !== "" ? data.hardwareModelId : null,
      duplicateActionProfiles: actionProfiles,
      duplicateActionTransformations: actionTransformations,
      duplicateDataProfiles: dataProfiles,
      duplicateDataOptions: dataOptions,
      duplicateDataTransformations: dataTransformations,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateHardwareModel.mutate({
      ...hardwareModel,
      ...data,
      serialSettings: {
        ...data.serialSettings,
        parity: parseInt(data.serialSettings.parity),
      },
      classification: parseInt(data.classification),
      endianness: parseInt(data.endianness),
      disabled: checked,
      partial: partial,
      hardwareModelId: null,
    });
    handleClose();
  };

  const handleCopyID = async () =>
    await navigator.clipboard.writeText(hardwareModel?.id ?? "");

  useEffect(() => {
    if (hardwareModel) {
      setChecked(hardwareModel.disabled);
      setPartial(hardwareModel.partial);
      reset({
        name: hardwareModel.name,
        hardwareTypeId: hardwareModel.hardwareTypeId,
        hardwareManufacturerId: hardwareModel.hardwareManufacturerId,
        classification: hardwareModel.classification,
        serialSettings: {
          baudRate: hardwareModel.serialSettings.baudRate,
          dataBits: hardwareModel.serialSettings.dataBits,
          parity: hardwareModel.serialSettings.parity,
          stopBits: hardwareModel.serialSettings.stopBits,
        },
        address: hardwareModel.address,
        endianness: hardwareModel.endianness,
      });
    } else {
      setChecked(true);
      setPartial(false);
      reset({ address: 1 });
    }
  }, [reset, hardwareModel]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${hardwareModel ? "Edit" : "Create"} Hardware Model`}
      description={`Use this form to ${
        hardwareModel ? "edit" : "create"
      } a hardware model`}
    >
      {hardwareModels.isLoading && hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(hardwareModel ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          {hardwareModel && (
            <div className="grid grid-cols-8 gap-x-2 items-end">
              <div className="col-span-7">
                <TextField
                  fullWidth
                  label="ID"
                  disabled
                  value={hardwareModel?.id}
                />
              </div>
              <Button onClick={handleCopyID} className="h-[42px]">
                <ClipboardDocumentIcon />
              </Button>
            </div>
          )}
          <Select {...register("hardwareTypeId", { value: "" })} label="Type">
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          {watch("hardwareTypeId") && (
            <ManufacturerSelect
              {...register("hardwareManufacturerId")}
              type={watch("hardwareTypeId")}
            />
          )}
          {watch("hardwareManufacturerId") && (
            <>
              <TextField {...register("name")} label="Name" />
              <Select {...register("classification")} label="Classification">
                {hardwareModelClassifications.map((c, i) => (
                  <option key={c} value={i}>
                    {c}
                  </option>
                ))}
              </Select>

              <Select
                {...register("serialSettings.baudRate", { value: 9600 })}
                label="Baud Rate"
              >
                <option disabled hidden value={0}>
                  Please Select
                </option>
                {BaudRateOptions.map((x, i) => (
                  <option key={i} value={x}>
                    {x}
                  </option>
                ))}
              </Select>

              <Select
                {...register("serialSettings.dataBits", { value: 8 })}
                label="Data Bits"
              >
                <option disabled hidden value={0}>
                  Please Select
                </option>
                {DataBitsOptions.map((x, i) => (
                  <option key={i} value={x}>
                    {x}
                  </option>
                ))}
              </Select>

              <Select
                {...register("serialSettings.parity", { value: "0" })}
                label="Parity"
              >
                {ParityOptions.map(
                  (x: { key: string; value: number }, i: number) => (
                    <option key={i} value={x.value}>
                      {x.key}
                    </option>
                  ),
                )}
              </Select>

              <Select
                {...register("serialSettings.stopBits", { value: 1 })}
                label="Stop Bits"
              >
                <option disabled hidden value={0}>
                  Please Select
                </option>
                {StopBitsOptions.map((x, i) => (
                  <option key={i} value={x}>
                    {x}
                  </option>
                ))}
              </Select>

              <Select
                {...register("endianness", { value: 0 })}
                label="Endianness"
              >
                {hardwareModelEndiannesses.map((x, i) => (
                  <option key={i} value={x.value}>
                    {`${x.label
                      .toString()
                      .substring(0, 3)
                      .toLocaleUpperCase()} ${x.label
                      .toString()
                      .substring(3, 6)
                      .toUpperCase()}`}
                  </option>
                ))}
              </Select>

              <TextField {...register("address")} label="Address" />

              {hardwareModel === undefined && (
                <ModelSelect
                  {...register("hardwareModelId")}
                  manufacturer={watch("hardwareManufacturerId")}
                  label="Duplicate From"
                />
              )}
            </>
          )}
          {watch("hardwareModelId") && (
            <div className="col-span-2 inline-flex mt-4">
              <Toggle
                className="mr-2"
                label="Action Profiles"
                checked={actionProfiles}
                onChange={setActionProfiles}
              />
              <Toggle
                className="mr-2"
                label="Action Transformations"
                checked={actionTransformations}
                onChange={setActionTransformations}
              />
              <Toggle
                className="mr-2"
                label="Data Profiles"
                checked={dataProfiles}
                onChange={setDataProfiles}
              />
              <Toggle
                className="mr-2"
                label="Data Options"
                checked={dataOptions}
                onChange={setDataOptions}
              />
              <Toggle
                className="mr-2"
                label="Data Transformations"
                checked={dataTransformations}
                onChange={setDataTransformations}
              />
            </div>
          )}
          <div className="mt-6 flex sm:col-span-2 justify-between">
            <div className="space-x-4">
              <Button type="submit">
                {hardwareModel ? "Update" : "Create"}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div className="space-x-4 flex">
              <Toggle label="Partial" checked={partial} onChange={setPartial} />
              <Toggle
                label="Disabled"
                checked={checked}
                onChange={setChecked}
              />
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
}
