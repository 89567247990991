import { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router";

import { createColumnHelper } from "@tanstack/react-table";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

import { authAPIURL } from "../../config";
import { Card } from "../../components/base/Card";
import { Table } from "../../components/base/Table";
import useApiHelper from "../../hooks/useApiHelper";
import { Button } from "../../components/base/Button";
import { ICustomer } from "../../types/Customer/Customer";
import { useUserContext } from "../../contexts/UserContext";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { CreateEditCustomer } from "../../components/Customer/CreateEditCustomer";
import { useQueryClient } from "react-query";

const colHelper = createColumnHelper<ICustomer>();

export function InstallersPage() {
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    options: { credentials: "include" },
    headers: { Authorization: "" },
  });
  const queryClient = useQueryClient();
  const { setUser } = useUserContext();
  const [editOpen, setEditOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState<ICustomer | undefined>(
    undefined,
  );

  useEffect(() => {
    setPageTitle("Installers");
  }, [setPageTitle]);

  const handleCreateEditClose = () => {
    editCustomer && setTimeout(() => setEditCustomer(undefined), 200);
    setEditOpen(false);
  };

  const handleLoginAsInstaller = async (customerId: string) => {
    try {
      let tokenResponse = await post(
        "/v1/sso/token",
        {
          organisationId: customerId,
          type: "portal",
        },
        {
          withCredentials: true,
        },
      );

      let decodedToken = jwtDecode<{ oid: string }>(tokenResponse.data.token!);

      setUser({ type: "loading", payload: true });
      setUser({
        type: "superAdminActAs",
        payload: {
          token: tokenResponse.data.token!,
          organisationId: decodedToken.oid,
        },
      });
      queryClient.clear();
      navigate("/");
    } catch (err) {
      console.error("err", err);
    } finally {
      setUser({ type: "loading", payload: false });
    }
  };

  const cols = [
    colHelper.accessor("id", { header: "ID" }),
    colHelper.accessor("organisationNumber", { header: "Organisation Number" }),
    colHelper.accessor("organisationName", { header: "Organisation Name" }),
    colHelper.accessor("disabled", {
      header: "Disabled",
      cell: (info) => (info.getValue() ? "Yes" : "No"),
    }),
    colHelper.display({
      header: "Login As",
      cell: (info) => (
        <ArrowRightOnRectangleIcon
          onClick={() => handleLoginAsInstaller(info.row.original.id)}
          className="w-6 h-6  text-gray-700 dark:text-gray-300 hover:cursor-pointer hover:text-black dark:hover:text-white"
        />
      ),
    }),
  ];

  return (
    <>
      <CreateEditCustomer
        open={editOpen}
        onClose={handleCreateEditClose}
        customer={editCustomer}
        installer
      />
      <Card title="All Installers">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Installer</Button>
        </div>
        <div>
          <Table<ICustomer>
            url="/superadmin/installer"
            queryKey={["superadmin", "installers"]}
            columns={cols}
            filterFields={[
              { field: "organisationNumber", fieldLabel: "Customer Number" },
              { field: "organisationName", fieldLabel: "Customer Name" },
            ]}
          />
        </div>
      </Card>
    </>
  );
}
