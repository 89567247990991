import { useState } from "react";

import { Button } from "../base/Button";
import { Modal } from "../../layouts/Modal";
import { TextField } from "../base/TextField";

export interface ConfirmDeleteProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (password?: string) => void;
}

export function ConfirmPassword({
  open,
  onCancel,
  onConfirm,
}: ConfirmDeleteProps) {
  const [password, setPassword] = useState<string>();

  const handleClose = () => {
    setTimeout(() => setPassword(undefined), 200);
  };

  const handleConfirm = (password?: string) => {
    onConfirm(password);
    handleClose();
  };

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Confirm Password"
      description="Please provide your password to confirm this action"
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      <div className="w-full my-6 mb-10 text-left flex justify-center">
        <TextField
          label="Password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          type="password"
          value={password}
        />
      </div>
      <div className="mt-6 space-x-4">
        <Button onClick={() => handleConfirm(password)}>Confirm</Button>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
