import React, { useEffect, useState } from "react";

import { LoadingWheel } from "../base/LoadingWheel";
import { Select } from "../base/Select";

import useSuperAdminActions from "../../data/action/useSuperAdminActions";
import useSuperAdminActionProfiles from "../../data/action/useSuperAdminActionProfiles";
import { IAction } from "../../types/Action/Action";

export interface ActionSelectProps {
  type: string;
  model: string;
  filter?: boolean;
  selected: string | undefined;
}

export const ActionSelect = React.forwardRef<any, ActionSelectProps>(
  ({ type, model, filter, selected, ...rest }, ref) => {
    const { actions: allActions } = useSuperAdminActions();
    const { actionProfiles } = useSuperAdminActionProfiles(model);
    const [actions, setActions] = useState<IAction[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (
        !allActions.isLoading &&
        !actionProfiles.isLoading &&
        allActions.data &&
        actionProfiles.data
      ) {
        let currentAction;
        let filteredActions;

        if (filter) {
          filteredActions = allActions.data.filter(
            (action) =>
              !actionProfiles.data?.some(
                (actionProfile) => actionProfile.actionId === action.id,
              ),
          );

          if (selected) {
            currentAction = allActions.data.find(
              (action) => action.id === selected,
            );
            currentAction && filteredActions.push(currentAction);
          }
        } else {
          filteredActions = allActions.data;
        }

        setActions(filteredActions);
        setLoading(false);
      }
    }, [
      type,
      model,
      filter,
      selected,
      allActions.isLoading,
      allActions.data,
      actionProfiles.isLoading,
      actionProfiles.data,
    ]);

    if (allActions.isLoading || actionProfiles.isLoading || loading) {
      return (
        <div className="flex items-center justify-center">
          <LoadingWheel size="sm" />
        </div>
      );
    } else {
      return (
        <Select disabled={!type || !model} ref={ref} label="Action" {...rest}>
          <option disabled selected hidden></option>
          {actions?.map((action) => (
            <option key={action.id} value={action.id}>
              {action.name}
            </option>
          ))}
        </Select>
      );
    }
  },
);
