import React, { useEffect, useState } from "react";

import { LoadingWheel } from "../base/LoadingWheel";
import { Select } from "../base/Select";

import useSuperAdminDataPointProfiles from "../../data/datapoint/superadmin/useSuperAdminDataPointProfiles";
import { IDataPointProfile } from "../../types/DataPoint/DataPointProfile";

export interface DataPointProfileSelectProps {
  sourceModel: string;
  destinationModel: string;
  label?: string;
}

export const DataPointProfileSelect = React.forwardRef<
  any,
  DataPointProfileSelectProps
>(
  (
    { sourceModel, destinationModel, label = "Data Point Profile", ...rest },
    ref,
  ) => {
    const { dataPointProfiles: sourceDataPointProfiles } =
      useSuperAdminDataPointProfiles(sourceModel);
    const { dataPointProfiles: destDataPointProfiles } =
      useSuperAdminDataPointProfiles(destinationModel);
    const [dataPointProfiles, setDataPointProfiles] = useState<
      IDataPointProfile[] | undefined
    >(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (
        !sourceDataPointProfiles.isLoading &&
        !destDataPointProfiles.isLoading &&
        sourceDataPointProfiles.data &&
        sourceDataPointProfiles.data
      ) {
        let filteredDataPointProfiles;

        if (sourceModel !== destinationModel) {
          filteredDataPointProfiles = sourceDataPointProfiles.data?.filter(
            (sdpp) =>
              !destDataPointProfiles.data?.some(
                (ddpp) => sdpp.dataPointId === ddpp.dataPointId,
              ),
          );
        }

        filteredDataPointProfiles = sourceDataPointProfiles.data;

        setDataPointProfiles(filteredDataPointProfiles);
        setLoading(false);
      }
    }, [
      sourceModel,
      destinationModel,
      sourceDataPointProfiles.isLoading,
      destDataPointProfiles.isLoading,
      sourceDataPointProfiles.data,
      destDataPointProfiles.data,
    ]);

    if (loading) {
      return (
        <div className="flex items-center justify-center">
          <LoadingWheel size="sm" />
        </div>
      );
    } else {
      return (
        <Select ref={ref} label={label} {...rest}>
          <option disabled hidden selected>
            Please Select
          </option>
          {dataPointProfiles?.map((dataPointProfile) => (
            <option key={dataPointProfile.id} value={dataPointProfile.id}>
              {dataPointProfile.dataPoint.name}{" "}
              {dataPointProfile.dataPoint.unit !== ""
                ? `(${dataPointProfile.dataPoint.unit})`
                : ""}
            </option>
          ))}
        </Select>
      );
    }
  },
);
