import { useEffect, useState } from "react";

import clsx from "clsx";
import { RgbColorPicker } from "react-colorful";

import { Button } from "../base/Button";
import RGBtoHEX from "../../utils/RGBtoHEX";
import HEXtoRGB from "../../utils/HEXtoRGB";
import { TextField } from "../base/TextField";
import { LoadingWheel } from "../base/LoadingWheel";
import useBranding from "../../data/branding/useBranding";
import { ColorValue, useTheme } from "../../contexts/ThemeContext";
import { useBrandingMutations } from "../../data/branding/useBrandingMutations";
import { LogoCrop } from "./Branding/LogoCrop";

export function BrandingTab() {
  const { theme, setTheme } = useTheme();
  const { branding } = useBranding();
  const { updateBranding } = useBrandingMutations();
  const [currentTab, setCurrentTab] = useState(0);
  const [logoCropOpen, setLogoCropOpen] = useState(false);
  const [brandName, setBrandName] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [lightPrimary, setLightPrimary] = useState<ColorValue | undefined>(
    undefined,
  );
  const [lightSecondary, setLightSecondary] = useState<ColorValue | undefined>(
    undefined,
  );
  const [darkPrimary, setDarkPrimary] = useState<ColorValue | undefined>(
    undefined,
  );
  const [darkSecondary, setDarkSecondary] = useState<ColorValue | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(true);

  const previewTheme = () => {
    setTheme({
      type: "colors",
      payload: {
        light: { primary: lightPrimary!, secondary: lightSecondary! },
        dark: { primary: darkPrimary!, secondary: darkSecondary! },
      },
    });
  };

  const handleUpdate = () => {
    updateBranding.mutate({
      name: brandName,
      domain: domain,
      lightPrimaryColour: [lightPrimary!.r, lightPrimary!.g, lightPrimary!.b],
      lightSecondaryColour: [
        lightSecondary!.r,
        lightSecondary!.g,
        lightSecondary!.b,
      ],
      darkPrimaryColour: [darkPrimary!.r, darkPrimary!.g, darkPrimary!.b],
      darkSecondaryColour: [
        darkSecondary!.r,
        darkSecondary!.g,
        darkSecondary!.b,
      ],
    });
    previewTheme();
  };

  const logoHatchingStyles = {
    backgroundColor: "#e5e5f7",
    opacity: 0.8,
    backgroundImage:
      "repeating-linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), repeating-linear-gradient(45deg, #ccc 25%, #fff 25%, #fff 75%, #ccc 75%, #ccc)",
    backgroundPosition: "0 0, 10px 10px",
    backgroundSize: "20px 20px",
  };

  useEffect(() => {
    if (!branding.isLoading && branding.data) {
      const d = branding.data[0];
      setLightPrimary({
        r: d.lightPrimaryColour[0],
        g: d.lightPrimaryColour[1],
        b: d.lightPrimaryColour[2],
      });
      setLightSecondary({
        r: d.lightSecondaryColour[0],
        g: d.lightSecondaryColour[1],
        b: d.lightSecondaryColour[2],
      });
      setDarkPrimary({
        r: d.darkPrimaryColour[0],
        g: d.darkPrimaryColour[1],
        b: d.darkPrimaryColour[2],
      });
      setDarkSecondary({
        r: d.darkSecondaryColour[0],
        g: d.darkSecondaryColour[1],
        b: d.darkSecondaryColour[2],
      });

      setBrandName(d.name);
      setDomain(d.domain);
      setLoading(false);
    }
  }, [branding.isLoading, branding.data]);

  if (loading) {
    return <LoadingWheel />;
  } else {
    const colours =
      currentTab === 0
        ? {
            primary: lightPrimary!,
            setPrimary: setLightPrimary,
            secondary: lightSecondary!,
            setSecondary: setLightSecondary,
          }
        : {
            primary: darkPrimary!,
            setPrimary: setDarkPrimary,
            secondary: darkSecondary!,
            setSecondary: setDarkSecondary,
          };

    return (
      <>
        <LogoCrop open={logoCropOpen} onClose={() => setLogoCropOpen(false)} />
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 className="text-2xl font-semibold leading-7  text-primary">
              Branding
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
              Upload your company logo, and add your brand colours.
            </p>

            <div className="my-6 pt-6 grid grid-cols-3 border-t border-gray-400 dark:border-gray-500">
              <div className="flex items-center font-medium text-gray-900 dark:text-gray-200 sm:flex-none sm:pr-6">
                Brand Name
              </div>
              <div>
                <TextField
                  value={brandName}
                  onChange={(e) => setBrandName(e.currentTarget.value)}
                />
              </div>
              <div className="flex items-center justify-end"></div>
            </div>

            {/* <div className="my-6 pt-6 grid grid-cols-3 border-t border-gray-400 dark:border-gray-500">
              <div className="flex items-center font-medium text-gray-900 dark:text-gray-200 sm:flex-none sm:pr-6">
                Domain
              </div>
              <div>
                <TextField
                  value={domain}
                  onChange={(e) => setDomain(e.currentTarget.value)}
                />
              </div>
              <div className="flex items-center justify-end"></div>
            </div> */}

            <div className="my-6 pt-6 grid grid-cols-3 border-t border-gray-400 dark:border-gray-500">
              <div className="flex items-center font-medium text-gray-900 dark:text-gray-200 sm:flex-none sm:pr-6">
                Logo
              </div>
              <div style={logoHatchingStyles} className="flex justify-center">
                <img
                  src={`${theme.logo}?r=${new Date().getMilliseconds()}`}
                  alt="Logo"
                />
              </div>
              <div className="flex items-center justify-end">
                <Button
                  onClick={() => setLogoCropOpen(true)}
                  size="sm"
                  className="font-semibold"
                >
                  Update
                </Button>
              </div>
            </div>

            <div className="border border-gray-400 dark:border-gray-500">
              <nav className="-mb-px flex" aria-label="Tabs">
                {["Light", "Dark"].map((tab, i) => (
                  <span
                    onClick={() => setCurrentTab(i)}
                    key={tab}
                    className={clsx(
                      i === currentTab
                        ? "border-primary/90 bg-primary/40 text-black dark:text-white"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200",
                      "whitespace-nowrap border-b-2 py-4 px-8 text-sm font-medium",
                    )}
                  >
                    {tab}
                  </span>
                ))}
              </nav>
            </div>
            <div className="p-4 border-b border-x border-gray-400 dark:border-gray-500">
              <dl className="py-2 space-y-6 divide-y divide-gray-300 text-sm leading-6 dark:divide-gray-600">
                <div className="grid grid-cols-3">
                  <div className="flex items-center font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                    Primary
                  </div>
                  <div>
                    <RgbColorPicker
                      color={colours.primary}
                      onChange={colours.setPrimary}
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <TextField
                      onChange={(e) =>
                        colours.setPrimary(HEXtoRGB(e.currentTarget.value))
                      }
                      type="text"
                      value={RGBtoHEX(
                        colours.primary.r,
                        colours.primary.g,
                        colours.primary.b,
                      )}
                    />
                  </div>
                </div>
                <div className="pt-6 sm:flex sm:items-center">
                  <div className="grid grid-cols-3">
                    <div className="flex items-center font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                      Secondary
                    </div>
                    <div>
                      <RgbColorPicker
                        color={colours.secondary}
                        onChange={colours.setSecondary}
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <TextField
                        onChange={(e) =>
                          colours.setSecondary(HEXtoRGB(e.currentTarget.value))
                        }
                        type="text"
                        value={RGBtoHEX(
                          colours.secondary.r,
                          colours.secondary.g,
                          colours.secondary.b,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </dl>
            </div>
            <div className="flex justify-end mt-6 space-x-4">
              <Button variant="outlined" onClick={previewTheme}>
                Preview
              </Button>
              <Button onClick={handleUpdate}>Save</Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
