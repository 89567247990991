import * as Sentry from "@sentry/react";
import { Outlet } from "react-router";

import { CDNURL } from "../config";
import { ErrorFallback } from "../ErrorFallback";

export function UnauthenticatedLayout() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className="h-screen w-screen grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-12">
        <div className="lg:col-span-2 xl:col-span-3 shadow-2xl bg-backdrop">
          <div className="h-full w-full flex items-center">
            <Outlet />
          </div>
        </div>
        <div className="bg-blue-200 hidden md:block lg:col-span-2 xl:col-span-9">
          <img
            alt="Background"
            src={`${CDNURL}/assets/images/loginbg.webp`}
            className="h-full object-cover"
          />
        </div>
      </div>
    </Sentry.ErrorBoundary>
  );
}
