import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IDataPoint } from "../../types/DataPoint/DataPoint";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import useQSBuilder from "../../hooks/useQSBuilder";

export default function useDataPoints(hardwareModelId?: string) {
  const { get } = useApiHelper();
  const buildQS = useQSBuilder();

  const dataPoints = useQuery({
    queryKey: ["reading", "datapoints", hardwareModelId],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPoint>>(
        `/v1/reading/datapoints${hardwareModelId ? buildQS({ hardwareModel: hardwareModelId }) : ""}`,
      ).then((res) => res.data.data),
  });

  return {
    dataPoints,
  };
}
