import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IInstaller } from "../../types/installer/Installer";

export default function useOrganisationInstaller() {
  const { get } = useApiHelper();

  const installer = useQuery({
    queryKey: ["organisation", "installer"],
    queryFn: () =>
      get<IDefaultResponseWithData<IInstaller>>(
        "/v1/organisation/installer",
      ).then((res) => res.data.data),
  });

  return {
    installer,
  };
}
