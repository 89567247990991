import { useQuery } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IUserOrganisation } from "../../types/UserOrganisation/UserOrganisation";

export default function useUserOrganisations() {
  const { get } = useApiHelper();

  const userOrganisations = useQuery({
    queryKey: userQueryKeys.organisations(),
    queryFn: () =>
      get<IDefaultResponseWithData<IUserOrganisation>>(
        `/v1/user/organisations`,
      ).then((res) => res.data.data),
  });

  return {
    userOrganisations,
  };
}
