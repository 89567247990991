import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { DateTime } from "luxon";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { LoadingWheel } from "../base/LoadingWheel";
import { useTheme } from "../../contexts/ThemeContext";
import { IDataPoint } from "../../types/DataPoint/DataPoint";
import { IReadingPartial } from "./../../types/DataPoint/Reading";
import { useLiveReading } from "../../contexts/LiveReadingContext";
import useReadingsHistory from "../../data/datapoint/useReadingsHistory";

interface LiveGraphProps {
  dataPoint: IDataPoint;
  hardwareId: string;
  endDate: string;
}

export function LiveGraph({ dataPoint, hardwareId, endDate }: LiveGraphProps) {
  const { theme } = useTheme();
  const [endDateInternal, __] = useState(
    new Date(DateTime.fromISO(endDate).toJSDate()),
  );
  const [startDateInternal, _] = useState(
    new Date(
      DateTime.fromJSDate(endDateInternal).minus({ minutes: 5 }).toJSDate(),
    ),
  );
  const { readings } = useReadingsHistory(
    hardwareId,
    dataPoint.id,
    startDateInternal.toISOString(),
    endDateInternal.toISOString(),
  );
  const { lastUpdate, loading } = useLiveReading();
  const chartRef = useRef<am5.Root | null>(null);
  const seriesRef = useRef<am5.Series | null>(null);
  const chartColour = theme[theme.isDark ? "dark" : "light"].primary;
  const [graphDataError, setGraphDataError] = useState(false);
  const [noReadings, setNoReadings] = useState(false);
  const cache = useRef<{ shouldFill: boolean; data: IReadingPartial[] }>({
    shouldFill: true,
    data: [],
  });

  useLayoutEffect(() => {
    if (!readings.isLoading && readings.data && readings.data.length > 0) {
      setGraphDataError(false);
      let root = am5.Root.new(`chartdiv-${dataPoint.id}`);

      root.setThemes([am5themes_Animated.new(root)]);

      chartRef.current = root;

      if (theme.isDark) {
        root.interfaceColors.set("text", am5.color(0xffffff));
      } else {
        root.interfaceColors.set("text", am5.color(0x000000));
      }

      let data = readings.data
        ?.map((reading) => {
          if (reading.readingData[0]) {
            return {
              timestamp: new Date(reading.timestamp).getTime(), // Parse the timestamp into a Date object
              value: reading.readingData[0].transformedValue,
            };
          } else {
            return {
              timestamp: new Date(reading.timestamp).getTime(), // Parse the timestamp into a Date object
              value: 0,
            };
          }
        })
        .reverse();

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingLeft: 0,
          paddingRight: 0,
          layout: root.verticalLayout,
          pinchZoomY: false,
          pinchZoomX: false,
          wheelY: "none",
          wheelX: "none",
          wheelable: false,
          panX: false,
          panY: false,
        }),
      );

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          autoZoom: false,
          extraTooltipPrecision: 1,
          numberFormat: `##'${dataPoint.unit}'`,
          renderer: am5xy.AxisRendererY.new(root, {}),
          zoomY: false,
          zoomX: false,
          panX: false,
          panY: false,
        }),
      );

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          zoomY: false,
          zoomX: false,
        }),
      );

      // Create series
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          autoGapCount: 10,
          name: "Data",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]{valueY} ${dataPoint.unit}`,
          }),
          fill: am5.color(
            `rgb(${chartColour.r}, ${chartColour.g}, ${chartColour.b})`,
          ),
          stroke: am5.color(
            `rgb(${chartColour.r}, ${chartColour.g}, ${chartColour.b})`,
          ),
        }),
      );

      series.strokes.template.set("strokeWidth", 2);
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 0.4,
      });

      series.data.setAll(data!);

      seriesRef.current = series;

      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: "none",
        }),
      );

      return () => {
        root.dispose();
      };
    } else if (readings.data?.length === 0) {
      setNoReadings(true);
    } else {
      setGraphDataError(true);
    }
  }, [
    chartColour.b,
    chartColour.g,
    chartColour.r,
    dataPoint.id,
    dataPoint.unit,
    readings.data,
    readings.isLoading,
    theme.isDark,
  ]);

  const addReadingToGraph = (reading: IReadingPartial) => {
    //Find reading for datapoint
    let readingData = reading.readingData.find(
      (reading) => reading.dataPointId === dataPoint.id,
    );

    if (reading && readingData !== undefined) {
      let ts = new Date(reading.timestamp!).getTime();
      let luxonDateTime = DateTime.fromMillis(ts);

      // Subtract 5 minutes from the original timestamp
      let fiveMinutesAgo = luxonDateTime.minus({ minutes: 5 }).toMillis();

      // Add all items older than 5 mins to an array
      let removeItems: any[] = [];
      seriesRef.current!.data.values.forEach((v: any, i) =>
        v.timestamp < fiveMinutesAgo ? removeItems.push(v) : () => {},
      );

      // Append reading to graph
      seriesRef.current!.data.push({
        timestamp: ts, // Parse the timestamp into a Date object
        value: readingData!.transformedValue,
      });

      // Remove all items older than 5 mins
      removeItems.forEach((v) => seriesRef.current!.data.removeValue(v));
    }
  };

  useEffect(() => {
    if (!loading && lastUpdate && cache.current.shouldFill) {
      const cacheReverse = [...lastUpdate].reverse();

      cache.current.data.push(...cacheReverse);
    }
  }, [hardwareId, loading, lastUpdate, cache.current.shouldFill]);

  useEffect(() => {
    if (seriesRef.current && !loading && lastUpdate) {
      if (cache.current.shouldFill && cache.current.data.length > 0) {
        cache.current.shouldFill = false;

        const cacheReverse = cache.current.data;

        cacheReverse.forEach((read) => addReadingToGraph(read));
      } else {
        const readings = [...lastUpdate].reverse();

        readings.forEach((read) => addReadingToGraph(read));
      }
    }
  }, [loading, lastUpdate]);

  if (!readings.isLoading) {
    if (!graphDataError) {
      return (
        <div
          id={`chartdiv-${dataPoint.id}`}
          style={{ width: "100%", height: "200px", fontSize: 12 }}
        ></div>
      );
    } else if (noReadings) {
      return (
        <div
          id={`chartdiv-${dataPoint.id}`}
          className="flex items-center justify-center"
          style={{ width: "100%", height: "200px" }}
        >
          <p>No readings</p>
        </div>
      );
    } else {
      return (
        <div
          id={`chartdiv-${dataPoint.id}`}
          className="flex items-center justify-center"
          style={{ width: "100%", height: "200px" }}
        >
          <p>Error getting graph data</p>
        </div>
      );
    }
  } else {
    return (
      <div
        id={`chartdiv-${dataPoint.id}`}
        className="flex items-center justify-center"
        style={{ width: "100%", height: "200px" }}
      >
        <LoadingWheel />
      </div>
    );
  }
}
