import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

import { IJobCategory } from "../../types/JobCategory/JobCategory";

export default function useJobCategoriesMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware Type");

  const createJobCategory = useMutation({
    mutationFn: (jobCategory) =>
      post("/v1/superadmin/job/categories", jobCategory),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["job", "categories"]);
      queryClient.invalidateQueries(["superadmin", "job", "categories"]);
    },
  });

  const updateJobCategory = useMutation({
    mutationFn: (jobCategory: IJobCategory) =>
      put<IJobCategory>(
        `/v1/superadmin/job/categories/${jobCategory.id}`,
        jobCategory,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["job", "categories"]);
      queryClient.invalidateQueries(["superadmin", "job", "categories"]);
    },
  });

  const delJobCategory = useMutation({
    mutationFn: (jobCategory: IJobCategory) =>
      del(`/v1/superadmin/job/categories/${jobCategory.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["job", "categories"]);
      queryClient.invalidateQueries(["superadmin", "job", "categories"]);
    },
  });

  return {
    createJobCategory,
    updateJobCategory,
    delJobCategory,
  };
}
