import { useEffect, useMemo, useState } from "react";

import { IReadingData, IReadingDataPartial } from "../types/DataPoint/Reading";
import useDataPointFunctions from "../data/datapoint/useDataPointFunctions";
import useDataPoints from "../data/datapoint/useDataPoints";
import { IDataPoint } from "../types/DataPoint/DataPoint";

export interface IProcessedReading {
  displayString: string;
  dataPoint: IDataPoint;
  reading: IReadingData;
}

export function useReadingsProcessor(hardwareModelId: string) {
  const { dataPointFunctions } = useDataPointFunctions();
  const { dataPoints } = useDataPoints(hardwareModelId);
  const [ready, setReady] = useState(false);

  const readingProcessor = useMemo(
    () =>
      (readingPartial: IReadingDataPartial): IProcessedReading | null => {
        let processedValue: string | number = readingPartial.transformedValue;

        let matchedDataPoint = dataPoints.data?.find(
          (dp) => dp.id === readingPartial.dataPointId,
        );

        if (!matchedDataPoint) {
          console.error(
            `Unable to find a dataPoint object to match for id ${readingPartial.dataPointId}`,
          );

          return null;
        }

        const reading: IReadingData = {
          ...readingPartial,
          dataPoints: matchedDataPoint!,
        };

        //Check if this datapoint has transformations e.g divide by 100
        if (reading.dataPoints.transformations.length > 0) {
          //Sort the transformations
          reading.dataPoints.transformations.sort((a, b) => a.order - b.order);

          //Loop over the transformations and apply them
          reading.dataPoints.transformations.forEach((transformation, i) => {
            //@ts-ignore
            processedValue = globalThis[transformation.functionId](
              processedValue,
              transformation.arguments,
            );
          });
        }

        //Check if this datapoint has options e.g mapping 1 to Yes
        if (reading.dataPoints.options.length > 0) {
          //Attempt to find the correct option for the reading value
          const option = reading.dataPoints.options.find(
            (o) => o.value === reading.transformedValue,
          );

          if (option) {
            //If correct option is found, replace raw value with mapped
            processedValue = option.label;
          }
        } else if (
          reading.dataPoints.profiles[0] &&
          reading.dataPoints.profiles[0].precision
        ) {
          let float = parseFloat(processedValue.toString());
          processedValue = float.toFixed(
            matchedDataPoint.profiles[0].precision,
          );
        }

        // Do we need to add the unit
        let displayUnit = !isNaN(processedValue as any);

        // Return the manipulated value and the unit
        return {
          displayString: `${processedValue} ${
            displayUnit ? reading.dataPoints.unit : ""
          }`,
          dataPoint: matchedDataPoint!,
          reading: reading,
        };
      },
    [dataPoints.data],
  );

  const loadScript = (
    fnId: string,
    url: string,
    async = true,
    type = "text/javascript",
  ) => {
    return new Promise((resolve, reject) => {
      if (document.querySelectorAll(`[data-fnid="${fnId}"]`).length) {
        resolve({ status: true });
      } else {
        try {
          const scriptEle = document.createElement("script");
          scriptEle.type = type;
          scriptEle.async = async;
          scriptEle.src = url;
          scriptEle.dataset.tag = "transformScript";
          scriptEle.dataset.fnid = fnId;

          scriptEle.addEventListener("load", (ev) => {
            resolve({ status: true });
          });

          scriptEle.addEventListener("error", (ev) => {
            reject({
              status: false,
              message: `Failed to load the script ${url}`,
            });
          });

          document.body.appendChild(scriptEle);
        } catch (error) {
          reject(error);
        }
      }
    });
  };

  useEffect(() => {
    if (
      !dataPointFunctions.isLoading &&
      dataPointFunctions.data &&
      !dataPoints.isLoading &&
      dataPoints.data
    ) {
      Promise.all(
        dataPointFunctions.data!.map((fn) => loadScript(fn.id, fn.url)),
      ).then(() => setReady(true));
    }
  }, [
    dataPointFunctions.isLoading,
    dataPointFunctions.data,
    dataPoints.isLoading,
    dataPoints.data,
  ]);

  return {
    processorReady: ready,
    readingProcessor,
  };
}
