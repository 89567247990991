import { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router";
import { useQueryClient } from "react-query";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowRightOnRectangleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { authAPIURL } from "../../config";
import { Card } from "../../components/base/Card";
import { Table } from "../../components/base/Table";
import useApiHelper from "../../hooks/useApiHelper";
import { Button } from "../../components/base/Button";
import { ICustomer } from "../../types/Customer/Customer";
import { useUserContext } from "../../contexts/UserContext";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { UnlinkCustomer } from "../../components/Customer/UnlinkCustomer";
import { CreateEditCustomer } from "../../components/Customer/CreateEditCustomer";

const colHelper = createColumnHelper<ICustomer>();

export function CustomersPage() {
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    options: { credentials: "include" },
    headers: { Authorization: "" },
  });
  const queryClient = useQueryClient();
  const { setUser } = useUserContext();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState<ICustomer | undefined>(
    undefined,
  );
  const [deleteCustomer, setDeleteCustomer] = useState<ICustomer | undefined>(
    undefined,
  );

  const handleDeleteCustomer = (customer: ICustomer) => {
    setDeleteCustomer(customer);
    setDeleteOpen(true);
  };

  const handleDeletedCustomer = () => {
    setDeleteOpen(false);
  };

  const handleEditCustomer = (customer: ICustomer) => {
    setEditCustomer(customer);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editCustomer && setTimeout(() => setEditCustomer(undefined), 200);
    setEditOpen(false);
  };

  const handleLoginAsCustomer = async (customerId: string) => {
    try {
      let tokenResponse = await post(
        "/v1/sso/token",
        {
          organisationId: customerId,
          type: "portal",
        },
        {
          withCredentials: true,
        },
      );

      let decodedToken = jwtDecode<{ oid: string }>(tokenResponse.data.token!);

      setUser({ type: "loading", payload: true });
      setUser({
        type: "installerActAs",
        payload: {
          token: tokenResponse.data.token!,
          organisationId: decodedToken.oid,
        },
      });

      queryClient.clear();
      queryClient.cancelQueries();

      navigate("/");
    } catch (err) {
      console.error("err", err);
    } finally {
      setUser({ type: "loading", payload: false });
    }
  };

  useEffect(() => {
    setPageTitle("Customers");
  }, [setPageTitle]);

  const cols = [
    colHelper.accessor("organisationNumber", { header: "Customer Number" }),
    colHelper.accessor("organisationName", { header: "Customer Name" }),
    colHelper.accessor("disabled", {
      header: "Disabled",
      cell: (info) => (info.getValue() ? "Yes" : "No"),
    }),
    colHelper.display({
      header: "Login As",
      cell: (info) => (
        <ArrowRightOnRectangleIcon
          onClick={() => handleLoginAsCustomer(info.row.original.id)}
          className="w-6 h-6 text-gray-700 dark:text-gray-300 hover:cursor-pointer hover:text-black dark:hover:text-white"
        />
      ),
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          <TrashIcon
            onClick={() => handleDeleteCustomer(info.row.original)}
            className="w-6 h-6 text-gray-700 dark:text-gray-300 hover:cursor-pointer hover:text-black dark:hover:text-white"
          />
          <PencilIcon
            onClick={() => handleEditCustomer(info.row.original)}
            className="w-6 h-6 text-gray-700 dark:text-gray-300 hover:cursor-pointer hover:text-black dark:hover:text-white"
          />
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditCustomer
        open={editOpen}
        onClose={handleCreateEditClose}
        customer={editCustomer}
      />
      <UnlinkCustomer
        customer={deleteCustomer!}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleDeletedCustomer}
      />
      <Card title="All Customers">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Customer</Button>
        </div>
        <div>
          <Table<ICustomer>
            url="/installer/customers"
            queryKey={["installer", "customers"]}
            columns={cols}
            filterFields={[
              { field: "organisationNumber", fieldLabel: "Customer Number" },
              { field: "organisationName", fieldLabel: "Customer Name" },
            ]}
          />
        </div>
      </Card>
    </>
  );
}
