import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";
import { IDataPointOption } from "../../../types/DataPoint/DataPointOption";
import { useDefaultMutateResponseHandlers } from "../../../hooks/useDefaultMutateResponeHandlers";

export default function useSuperAdminDataPointOptionMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Data Point Option");

  const createDataPointOption = useMutation({
    mutationFn: (dataPointOption) =>
      post("/v1/superadmin/datapoint/option", dataPointOption),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "options"]);
    },
  });

  const updateDataPointOption = useMutation({
    mutationFn: (dataPointOption: IDataPointOption) =>
      put<IDataPointOption>(
        `/v1/superadmin/datapoint/option/${dataPointOption.id}`,
        dataPointOption,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "options"]);
    },
  });

  const delDataPointOption = useMutation({
    mutationFn: (dataPointOption: IDataPointOption) =>
      del(`/v1/superadmin/datapoint/option/${dataPointOption.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["reading", "latest"]);
      queryClient.invalidateQueries(["superadmin", "datapoint", "options"]);
    },
  });

  return {
    createDataPointOption,
    updateDataPointOption,
    delDataPointOption,
  };
}
