import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";
import { Modal } from "../../layouts/Modal";
import { TextArea } from "../base/TextArea";
import { TextField } from "../base/TextField";
import { DataPointSelect } from "./DataPointSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import { IDataPointProfile } from "../../types/DataPoint/DataPointProfile";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import useSuperAdminDataPointProfileMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointProfileMutation";
import { GlobalHardwareModelFilterBanner } from "../shared/GlobalHardwareModelFilterBanner";

export interface CreateEditDataPointProfileProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  dataPointProfile?: IDataPointProfile;
}

export function CreateEditDataPointProfile({
  open,
  onClose,
  dataPointProfile,
}: CreateEditDataPointProfileProps) {
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IDataPointProfile>();
  const { hardwareTypes } = useHardwareTypes();
  const { createDataPointProfile, updateDataPointProfile } =
    useSuperAdminDataPointProfileMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [readOnly, setReadOnly] = useState(false);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({ length: 2 }), 200);
  };

  const handleCreate = (data: any) => {
    createDataPointProfile.mutate({
      ...data,
      bit: data.bit !== "" ? data.bit : null,
      readOnly: readOnly,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateDataPointProfile.mutate({
      ...dataPointProfile,
      ...data,
      bit: data.bit !== "" ? data.bit : null,
      readOnly: readOnly,
    });
    handleClose();
  };

  useEffect(() => {
    if (dataPointProfile) {
      setReadOnly(dataPointProfile.readOnly);
      reset({
        hardwareTypeId: dataPointProfile.hardwareTypeId,
        hardwareManufacturerId: dataPointProfile.hardwareManufacturerId,
        hardwareModelId: dataPointProfile.hardwareModelId,
        dataPointId: dataPointProfile.dataPointId,
        index: dataPointProfile.index,
        length: dataPointProfile.length,
        bit: dataPointProfile.bit,
        registerName: dataPointProfile.registerName,
        registerAddress: dataPointProfile.registerAddress,
        registerDetails: dataPointProfile.registerDetails,
        minimumValue: dataPointProfile.minimumValue,
        maximumValue: dataPointProfile.maximumValue,
        precision: dataPointProfile.precision,
        notes: dataPointProfile.notes,
        hardwareModel: dataPointProfile.hardwareModel,
      });
    } else if (globalHardwareModelFilter) {
      setReadOnly(false);
      reset({
        hardwareModelId: globalHardwareModelFilter.id,
        hardwareManufacturerId:
          globalHardwareModelFilter.hardwareManufacturerId,
        hardwareTypeId: globalHardwareModelFilter.hardwareTypeId,
        length: 2,
      });
    } else {
      setReadOnly(false);
      reset({ length: 2 });
    }
  }, [
    reset,
    open,
    dataPointProfile,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${dataPointProfile ? "Edit" : "Create"} Data Point Profile`}
      description={`Use this form to ${
        dataPointProfile ? "edit" : "create"
      } a data point profile`}
    >
      <GlobalHardwareModelFilterBanner />
      {hardwareTypes.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(
            dataPointProfile ? handleUpdate : handleCreate,
          )}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("dataPointId");
              },
            })}
            type={watch("hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("dataPointId");
              },
            })}
            manufacturer={watch("hardwareManufacturerId")}
          />
          <DataPointSelect
            {...register("dataPointId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={dataPointProfile?.dataPointId}
            filterUnused
          />
          <TextField {...register("index")} label="Index" />
          <TextField {...register("length")} label="Length" />
          <TextField {...register("bit")} label="Bit" />
          <TextField {...register("precision")} label="Precision" />
          <TextField {...register("minimumValue")} label="Minimum Value" />
          <TextField {...register("maximumValue")} label="Maximum Value" />
          <TextField {...register("registerName")} label="Register Name" />
          <TextField
            {...register("registerAddress")}
            label="Register Address"
          />
          <div className="col-span-2">
            <TextArea
              {...register("registerDetails")}
              label="Register Details"
              rows={2}
            />
          </div>
          <div className="col-span-2">
            <TextArea {...register("notes")} label="Notes" rows={4} />
          </div>
          <div className="mt-6 flex sm:col-span-2 justify-between">
            <div className="space-x-4">
              <Button type="submit">
                {dataPointProfile ? "Update" : "Create"}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <Toggle
              label="Read Only"
              checked={readOnly}
              onChange={setReadOnly}
            />
          </div>
        </form>
      )}
    </Modal>
  );
}
