import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { useUserContext } from "../../../contexts/UserContext";
import { CategoryPill } from "../../../components/Job/CategoryPill";
import { IJobCategory } from "../../../types/JobCategory/JobCategory";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import useJobCategoriesMutations from "../../../data/job/useJobCategoriesMutations";
import { CreateEditJobCategory } from "../../../components/Job/CreateEditJobCategory";

const colHelper = createColumnHelper<IJobCategory>();

export function JobCategoriesPage() {
  const { delJobCategory, updateJobCategory } = useJobCategoriesMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editJobCategory, setEditJobCategory] = useState<
    IJobCategory | undefined
  >(undefined);
  const [deleteJobCategory, setDeleteJobCategory] = useState<
    IJobCategory | undefined
  >(undefined);

  const handleDeleteJobCategory = (jobCategory: IJobCategory) => {
    setDeleteJobCategory(jobCategory);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteJobCategory = () => {
    delJobCategory.mutate(deleteJobCategory!);
    setDeleteOpen(false);
  };

  const handleRestoreJobCategory = (jobCategory: IJobCategory) => {
    updateJobCategory.mutate({ ...jobCategory, restore: true });
  };

  const handleEditJobCategory = (jobCategory: IJobCategory) => {
    setEditJobCategory(jobCategory);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editJobCategory && setTimeout(() => setEditJobCategory(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor("id", { header: "Id" }),
    colHelper.accessor("name", {
      header: "Category",
      cell: (info) => <CategoryPill category={info.row.original} />,
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreJobCategory(info.row.original)}
            />
          ) : (
            <>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditJobCategory(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteJobCategory(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditJobCategory
        open={editOpen}
        onClose={handleCreateEditClose}
        jobCategory={editJobCategory}
      />
      <ConfirmDelete
        itemName={deleteJobCategory?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteJobCategory()}
      />
      <Card title="Job Categories">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Category</Button>
        </div>
        <div>
          <Table<IJobCategory>
            url="/superadmin/job/categories"
            queryKey={["superadmin", "job", "categories"]}
            columns={cols}
            enableShowDeleted
          />
        </div>
      </Card>
    </>
  );
}
