import { useEffect, useState } from "react";

import clsx from "clsx";

import { fingotiCDNURL } from "../../config";
import { IUser } from "../../types/User/User";

export interface AvatarProps extends Partial<IUser> {
  id: string;
  forename: string;
  surname: string;
  size?: number;
}

export function Avatar({ id, forename, surname, size = 10 }: AvatarProps) {
  const [useFallback, setUseFallback] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSizeClass = () => {
    switch (size) {
      case 8:
        return "h-8 w-8";
      case 10:
      default:
        return "w-10 h-10";
    }
  };

  const stringToColour = function (str: string) {
    if (str === undefined) {
      return "#000000";
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      var hexVal = "00" + value.toString(16);
      colour += hexVal.substring(hexVal.length - 2);
    }

    return colour;
  };

  useEffect(() => {
    const checkExists = async () => {
      try {
        let result = await fetch(`${fingotiCDNURL}/images/avatars/${id}`, {
          method: "HEAD",
        });

        if (result.status !== 200) {
          setUseFallback(true);
        }
      } catch (err: any) {
        setUseFallback(true);
      } finally {
        setLoading(false);
      }
    };

    checkExists();
  }, [id]);

  if (loading) {
    return <></>;
  } else if (useFallback) {
    return (
      <div className="aspect-square h-10 w-10">
        <span
          style={{ background: stringToColour(id!) }}
          className="w-full h-full inline-flex items-center justify-center rounded-full"
        >
          <span className="text-lg leading-none text-white">
            {forename ? forename.charAt(0).toLocaleUpperCase() : ""}
            {surname ? surname.charAt(0).toLocaleUpperCase() : ""}
          </span>
        </span>
      </div>
    );
  } else {
    return (
      <img
        className={clsx("rounded-full", getSizeClass())}
        src={`${fingotiCDNURL}/images/avatars/${id}`}
        alt={`${forename} ${surname} Avatar`}
      />
    );
  }
}
