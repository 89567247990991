import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useOrganisationInstallerMutations() {
  const queryClient = useQueryClient();
  const { del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Installer");

  const deleteInstaller = useMutation({
    mutationFn: () => del(`/v1/organisation/installer`),
    onError: (error) => handleError(error, "unlinke"),
    onSuccess: (data) => handleSuccess(data, "unlinke"),
    onSettled: () =>
      queryClient.invalidateQueries(["organisation", "installer"]),
  });

  return {
    deleteInstaller,
  };
}
