import { useEffect, useState } from "react";
import { MFARecoveryCodesResponse } from "../../../types/responses/mfa/MFARecoveryCodesResponse";
import useApiHelper from "../../../hooks/useApiHelper";
import { authAPIURL } from "../../../config";
import { SectionLoading } from "../../shared/SectionLoading";
import { Alert } from "../../base/Alert";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { Button } from "../../base/Button";
import { ConfirmPassword } from "../../shared/ConfirmPassword";
import { useNotification } from "../../../hooks/useNotifications";
import { MFARegenerateRequest } from "../../../types/requests/mfa/MFARegenerateRequest";
import { MFARegenerateResponse } from "./../../../types/responses/mfa/MFARegenerateResponse";

export function MFARecoveryCodes({ open, onClose }: ModalControlProps) {
  const { get, post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
  });
  const addNotification = useNotification();
  const [requirePasswordOpen, setRequirePasswordOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>();
  const [error, setError] = useState(false);

  const handleCopyCodes = async () =>
    await navigator.clipboard.writeText(recoveryCodes!.join("\n"));

  const handleDownloadCodes = () => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(recoveryCodes!.join("\n")),
    );
    element.setAttribute("download", "planet-devices-recovery-codes.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleRegenerate = async (password?: string) => {
    setRequirePasswordOpen(false);
    setLoading(true);

    try {
      const regenerateResponse = await post<
        MFARegenerateRequest,
        MFARegenerateResponse
      >("/v1/mfa/recover/regenerate", { password });

      if (regenerateResponse.status === 200) {
        onClose(false);
        addNotification(
          "success",
          regenerateResponse.data.message ?? "MFA Recovery codes regenerated",
        );
      }
    } catch (e) {
      addNotification("error", "Password incorrect");
    } finally {
      onClose(false);
      setLoading(false);
    }
  };

  const getRecoveryCodes = async () => {
    try {
      const codesResponse =
        await get<MFARecoveryCodesResponse>("/v1/mfa/recover");

      if (codesResponse.status === 200) {
        setRecoveryCodes(codesResponse.data.codes);
      } else {
        throw Error("Error getting MFA details");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getRecoveryCodes();
    }
  }, [open]);

  return (
    <>
      <ConfirmPassword
        open={requirePasswordOpen}
        onConfirm={handleRegenerate}
        onCancel={() => setRequirePasswordOpen(false)}
      />
      <Modal title="Multi-factor recovery codes" open={open} onClose={onClose}>
        {loading ? (
          <SectionLoading />
        ) : error ? (
          <div className="mt-6">
            <Alert
              severity="error"
              title="Error"
              message="Failed to get multi-factor recovery codes, please try again"
            />
          </div>
        ) : (
          <div>
            <p>
              Please take a copy of these codes and keep them safe, they will
              allow you to gain access to your account in the event of an
              authenticator not being available
            </p>
            <div className="w-1/2 grid lg:grid-cols-2 mx-auto mt-10">
              {recoveryCodes?.map((code) => (
                <div className="py-1.5 px-3 m-0.5 border text-center rounded bg-gray-600 border-gray-800 font-mono">
                  {code}
                </div>
              ))}
            </div>
            <div className="mt-10 flex justify-center">
              <Button onClick={() => setRequirePasswordOpen(true)}>
                Regenerate
              </Button>
            </div>
            <div className="mt-10 flex justify-between">
              <Button variant="outlined" onClick={() => onClose(true)}>
                Cancel
              </Button>
              <div className="space-x-2">
                <Button onClick={handleCopyCodes}>Copy to Clipboard</Button>
                <Button onClick={handleDownloadCodes}>Download</Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
