import { useState } from "react";

import { Button } from "../base/Button";
import useUser from "../../data/user/useUser";
import { MFAEnable } from "./Security/MFASetup";
import { MFADisable } from "./Security/MFADisable";
import { SectionLoading } from "../shared/SectionLoading";
import { MFARecoveryCodes } from "./Security/MFARecoveryCodes";

export function SecurityTab() {
  const { user } = useUser();
  const [mfaEnableOpen, setMfaEnableOpen] = useState(false);
  const [mfaDisableOpen, setMfaDisableOpen] = useState(false);
  const [mfaCodesOpen, setMfaCodesOpen] = useState(false);

  return (
    <>
      <>
        <MFAEnable
          open={mfaEnableOpen}
          onClose={() => setMfaEnableOpen(false)}
        />
        <MFADisable
          open={mfaDisableOpen}
          onClose={() => setMfaDisableOpen(false)}
        />
        <MFARecoveryCodes
          open={mfaCodesOpen}
          onClose={() => setMfaCodesOpen(false)}
        />
      </>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-2xl font-semibold  text-primary leading-7">
            Security
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
            Manage multi-factor authentication.
          </p>
          {user.isLoading ? (
            <SectionLoading />
          ) : (
            <dl className="mt-6 space-y-6 divide-y divide-gray-300 border-t border-gray-400 text-sm leading-6 dark:divide-gray-600 dark:border-gray-500">
              <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Multi-factor authentication
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200 space-x-4">
                    <span>
                      {user.data?.mfaEnabled ? "Enabled" : "Disabled"}
                    </span>
                    {user.data?.mfaEnabled && (
                      <Button
                        onClick={() => setMfaCodesOpen(true)}
                        size="sm"
                        className="font-semibold"
                      >
                        Recovery Codes
                      </Button>
                    )}
                  </div>
                  <Button
                    onClick={
                      user.data?.mfaEnabled
                        ? () => setMfaDisableOpen(true)
                        : () => setMfaEnableOpen(true)
                    }
                    size="sm"
                    className="font-semibold"
                  >
                    {user.data?.mfaEnabled ? "Disable" : "Enable"}
                  </Button>
                </dd>
              </div>
              {/* <div className="pt-6 sm:flex sm:items-center">
                <dt className="font-medium text-gray-900 dark:text-gray-200 sm:w-64 sm:flex-none sm:pr-6">
                  Password
                </dt>
                <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-200"></div>
                  <Button size="sm" className="font-semibold">
                    Change
                  </Button>
                </dd>
              </div> */}
            </dl>
          )}
        </div>
      </div>
    </>
  );
}
