import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { Button } from "../../../components/base/Button";
import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/outline";
import { CreateEditDataPointFunction } from "../../../components/DataPoint/CreateEditDataPointFunction";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { IDataPointFunction } from "../../../types/DataPoint/DataPointFunction";
import useSuperAdminDataPointFunctionMutations from "../../../data/datapoint/superadmin/useSuperAdminDataPointFunctionMutation";

const colHelper = createColumnHelper<IDataPointFunction>();

export function DataPointFunctionsPage() {
  const { delDataPointFunction, updateDataPointFunction } =
    useSuperAdminDataPointFunctionMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editDataPointFunction, setEditDataPointFunction] = useState<
    IDataPointFunction | undefined
  >(undefined);
  const [deleteDataPointFunction, setDeleteDataPointFunction] = useState<
    IDataPointFunction | undefined
  >(undefined);

  const handleDeleteDataPointFunction = (
    dataPointFunction: IDataPointFunction,
  ) => {
    setDeleteDataPointFunction(dataPointFunction);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteDataPointFunction = () => {
    delDataPointFunction.mutate(deleteDataPointFunction!);
    setDeleteOpen(false);
  };

  const handleRestoreDataPointFunction = (
    dataPointFunction: IDataPointFunction,
  ) => {
    updateDataPointFunction.mutate({ ...dataPointFunction, restore: true });
  };

  const handleEditDataPointFunction = (
    dataPointFunction: IDataPointFunction,
  ) => {
    setEditDataPointFunction(dataPointFunction);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editDataPointFunction &&
      setTimeout(() => setEditDataPointFunction(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor("id", { header: "Id" }),
    colHelper.accessor("name", { header: "Name" }),
    //colHelper.accessor("url", { header: "URL" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreDataPointFunction(info.row.original)}
            />
          ) : (
            <>
              <a href={info.row.original.url} target="_blank" rel="noreferrer">
                <CodeBracketIcon className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer" />
              </a>
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditDataPointFunction(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteDataPointFunction(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditDataPointFunction
        open={editOpen}
        onClose={handleCreateEditClose}
        dataPointFunction={editDataPointFunction}
      />
      <ConfirmDelete
        itemName={deleteDataPointFunction?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteDataPointFunction()}
      />
      <Card title="Data Point Function">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>
            Add Data Point Function
          </Button>
        </div>
        <div>
          <Table<IDataPointFunction>
            url="/superadmin/datapoint/function"
            queryKey={["superadmin", "datapoint", "functions"]}
            columns={cols}
            enableShowDeleted
          />
        </div>
      </Card>
    </>
  );
}
