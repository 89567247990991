import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import useApiHelper from "../../hooks/useApiHelper";
import { Button } from "../../components/base/Button";
import { applicationId, authAPIURL } from "../../config";
import { TextField } from "../../components/base/TextField";
import { useUserContext } from "../../contexts/UserContext";
import { Alert, AlertProps } from "../../components/base/Alert";
import { IDefaultResponse } from "../../types/system/DefaultResponse";
import { SectionLoading } from "../../components/shared/SectionLoading";
import {
  SSOPasswordChangeFormFields,
  SSOPasswordChangeRequest,
} from "../../types/requests/sso/SSOPasswordChangeRequest";

export function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: false,
    addToken: false,
  });
  const { loading: userLoading, isLoggedIn } = useUserContext();
  const { register, handleSubmit } = useForm<SSOPasswordChangeFormFields>();
  const [token, setToken] = useState<string>();
  const [noToken, setNoToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AlertProps | undefined>();
  const [success, setSuccess] = useState(false);

  const handleReset = async (resetDetails: SSOPasswordChangeFormFields) => {
    setLoading(true);

    try {
      const resetResponse = await post<
        SSOPasswordChangeRequest,
        IDefaultResponse
      >(`/v1/sso/password/reset?token=${token}`, {
        ...resetDetails,
        applicationId: applicationId,
      });

      if (resetResponse.status === 200 && resetResponse.data.success) {
        setSuccess(true);
      } else {
        throw Error("An unknown error has occurred");
      }
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError({ severity: "error", message: err.response.data.message });
      } else {
        setError({
          severity: "error",
          message: "An unknown error has occurred",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userLoading && isLoggedIn) {
      navigate("/");
    }

    if (searchParams.has("token")) {
      const token = searchParams.get("token")!;
      setToken(token);
      setLoading(false);
    } else {
      setNoToken(true);
      setLoading(false);
    }
  }, [searchParams, userLoading, isLoggedIn, navigate]);

  return (
    <div className="w-10/12 xl:w-8/12 mx-auto">
      {loading ? (
        <SectionLoading />
      ) : (
        <>
          <div className="mb-6 text-4xl font-medium dark:text-white">
            Password Reset
          </div>
          {noToken ? (
            <Alert
              className="mb-6"
              severity="error"
              title="An error occurred"
              message="An unknown error has occurred"
            />
          ) : (
            <>
              {error && <Alert {...error} className="mb-6" />}
              {success && (
                <Alert
                  className="mb-6"
                  severity="success"
                  title="Password Reset!"
                  message="Your password has been reset, you can now login using your new password"
                />
              )}
              {!success ? (
                <form onSubmit={handleSubmit(handleReset)}>
                  <div className="space-y-2">
                    <TextField
                      {...register("newPassword")}
                      fullWidth
                      type="password"
                      placeholder="New Password"
                      label="New Password"
                    />
                    <p className="text-gray-400 leading-tight text-xs">
                      Passwords must be at least six characters long, have at
                      least one uppercase letter, one number, and one symbol.
                    </p>
                    <TextField
                      {...register("confirmPassword")}
                      fullWidth
                      type="password"
                      placeholder="Confirm New Password"
                      label="Confirm New Password"
                    />
                  </div>
                  <div className="space-y-4 mt-6">
                    <Button fullWidth type="submit">
                      Reset Password
                    </Button>
                  </div>
                </form>
              ) : (
                <Button fullWidth onClick={() => navigate("/login")}>
                  Back to Login
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
