import { useQuery } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IUserConfiguration } from "../../types/UserConfiguration/UserConfiguration";

export default function useUserConfigurations(type: number) {
  const { get } = useApiHelper();

  const userConfigurations = useQuery({
    queryKey: userQueryKeys.configurations(type),
    queryFn: () =>
      get<IDefaultResponseWithData<IUserConfiguration>>(
        `/v1/user/configurations?type=${type}`,
      ).then((res) => res.data.data),
  });

  return {
    userConfigurations,
  };
}
