import { useQuery } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../../types/system/DefaultResponse";
import { IDataPointProfile } from "../../../types/DataPoint/DataPointProfile";

export default function useSuperAdminDataPointProfiles(hardwareModel?: string) {
  const { get } = useApiHelper();

  const dataPointProfiles = useQuery({
    queryKey: ["superadmin", "datapoint", "profiles", hardwareModel],
    queryFn: () =>
      get<IDefaultResponseWithData<IDataPointProfile>>(
        `/v1/superadmin/datapoint/profile${
          hardwareModel ? `/${hardwareModel}` : ""
        }`,
      ).then((res) => res.data.data),
  });

  return {
    dataPointProfiles,
  };
}
