import { useMemo, useState } from "react";

import clsx from "clsx";
import { useQuery } from "react-query";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";

import { Select } from "../Select";
import { Toggle } from "../Toggle";
import useApiHelper from "../../../hooks/useApiHelper";
import useQSBuilder from "../../../hooks/useQSBuilder";
import { SectionLoading } from "../../shared/SectionLoading";
import { IBaseModel } from "../../../types/system/BaseModel";
import {
  PredefinedTableFilterField,
  TableFilter,
  TableFilterField,
} from "./TableFilter";
import { IDefaultPaginatedResponse } from "../../../types/system/DefaultResponse";
import {
  TableFilterContextProvider,
  useTableFilter,
} from "./TableFilterContext";

export interface TableProps<T extends IBaseModel> {
  url: string;
  queryKey: string[];
  columns: ColumnDef<T, any>[];
  filterFields?: TableFilterField[];
  predefinedFilterFields?: PredefinedTableFilterField[];
  enableShowDeleted?: boolean;
}

export function Table<T extends IBaseModel>({
  url,
  queryKey,
  columns,
  filterFields,
  predefinedFilterFields,
  enableShowDeleted = false,
}: TableProps<T>) {
  return (
    <TableFilterContextProvider
      predefinedFilterFields={predefinedFilterFields}
      filterFields={filterFields}
    >
      <TableImpl
        url={url}
        queryKey={queryKey}
        columns={columns}
        filterFields={filterFields}
        enableShowDeleted={enableShowDeleted}
        predefinedFilterFields={predefinedFilterFields}
      />
    </TableFilterContextProvider>
  );
}

function TableImpl<T extends IBaseModel>({
  url,
  queryKey,
  columns,
  filterFields,
  predefinedFilterFields,
  enableShowDeleted,
}: TableProps<T>) {
  const { get } = useApiHelper();
  const buildQS = useQSBuilder();
  const { filters, getFilterQueryString } = useTableFilter();
  const defaultData = useMemo(() => [], []);
  const pageSizeOptions = [5, 10, 20, 30, 40, 50];
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchDataOptions = {
    pageNumber: pageIndex,
    itemsPerPage: pageSize,
    includeDeleted: includeDeleted,
  };

  const dataQuery = useQuery(
    [...queryKey, { ...fetchDataOptions, ...filters }],
    () =>
      get<IDefaultPaginatedResponse<T>>(
        `/v1${url}${buildQS(fetchDataOptions)}${getFilterQueryString()}`,
      ).then((res) => res),
    { keepPreviousData: true },
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: dataQuery.data?.data.data ?? defaultData,
    columns,
    pageCount: dataQuery.data?.data.count
      ? Math.ceil(dataQuery.data.data.count / pageSize)
      : 1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  if (dataQuery.isLoading) {
    return <SectionLoading />;
  } else {
    return (
      <div>
        {filterFields || predefinedFilterFields ? (
          <TableFilter filterFields={filterFields} />
        ) : (
          <></>
        )}
        {enableShowDeleted && (
          <Toggle
            label="Show Deleted"
            checked={includeDeleted}
            onChange={(value) => setIncludeDeleted(value)}
            className="my-4"
          />
        )}
        <div className="block overflow-x-auto overflow-y-hidden">
          <table className="min-w-full table border border-primary/40 divide-y divide-primary dark:border-primary">
            <thead className="bg-primary/40">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-3 py-3 text-left text-sm font-semibold"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-primary/40">
              {table.getRowModel().rows.map((row, rowIndex) => (
                <tr
                  key={row.id}
                  className={clsx(
                    `${rowIndex % 2 !== 0 && "bg-primary/10"}`,
                    row.original.deletedOn
                      ? "bg-red-400/50 opacity-70 dark:text-white italic"
                      : "",
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="whitespace-nowrap px-3 py-4 text-sm"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full ml-auto mt-2 flex flex-col items-center space-y-2 lg:space-y-0 lg:flex-row lg:justify-between">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <div>
            <button
              type="button"
              className={`rounded-full p-2 text-gray-600 hover:cursor-pointer hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 
            ${
              !table.getCanPreviousPage() &&
              "hover:!cursor-default !text-gray-400 hover:!bg-paper dark:!text-gray-500"
            }
            `}
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronDoubleLeftIcon className="w-5 h-5" />
            </button>
            <button
              type="button"
              className={`-ml-1 rounded-full p-2 text-gray-600 hover:cursor-pointer hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 
            ${
              !table.getCanPreviousPage() &&
              "hover:!cursor-default !text-gray-400 hover:!bg-paper dark:!text-gray-500"
            }
            `}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <button
              type="button"
              className={`-ml-1 rounded-full p-2 text-gray-600 hover:cursor-pointer hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 
            ${
              !table.getCanNextPage() &&
              "hover:!cursor-default !text-gray-400 hover:!bg-paper dark:!text-gray-500"
            }
            `}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
            <button
              type="button"
              className={`-ml-1 rounded-full p-2 text-gray-600 hover:cursor-pointer hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 
            ${
              !table.getCanNextPage() &&
              "hover:!cursor-default !text-gray-400 hover:!bg-paper dark:!text-gray-500"
            }
            `}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <ChevronDoubleRightIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <span>Per Page</span>
            <Select
              small
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.currentTarget.value));
              }}
            >
              {pageSizeOptions.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}
