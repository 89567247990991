import { useEffect, useState } from "react";

import date from "date-and-time";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { upsert } from "../../utils/upsert";
import { Card } from "../../components/base/Card";
import { IHardware } from "../../types/Hardware/Hardware";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { alphabeticalSort } from "../../utils/alphabeticalSort";
import { useLiveReading } from "../../contexts/LiveReadingContext";
import { ReadingCard } from "../../components/LiveView/ReadingCard";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { usePendingActions } from "../../contexts/PendingActionContext";
import {
  IProcessedReading,
  useReadingsProcessor,
} from "../../hooks/useReadingProcessor";
import { LiveReadingsViewAs } from "../../pages/LiveView";

interface IReadingsDisplayProps {
  hardware: IHardware;
  viewAs: LiveReadingsViewAs;
}

export function ReadingsDisplay({ hardware, viewAs }: IReadingsDisplayProps) {
  const { setSubTitle } = usePageTitle();
  const { setHardware } = usePendingActions();
  const { latest, latestTimestamp, loading } = useLiveReading();
  const { processorReady, readingProcessor } = useReadingsProcessor(
    hardware.hardwareModelId,
  );
  const [processedReadings, setProcessedReadings] = useState<
    IProcessedReading[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (processorReady && !loading && latest) {
      latest.readingData.forEach((reading) => {
        let pr = readingProcessor(reading);

        if (pr !== null) {
          setProcessedReadings(
            upsert((i) => i.dataPoint.id, reading.dataPointId, pr),
          );
        }
      });

      setIsLoading(false);
    } else if (processorReady && !loading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [processorReady, latest, loading]);

  useEffect(() => {
    setHardware(hardware.id);
  }, [hardware]);

  useEffect(() => {
    if (!loading && latestTimestamp) {
      setSubTitle(
        `Last updated: ${date.format(
          new Date(latestTimestamp),
          "DD/MM/YYYY HH:mm:ss",
        )}`,
      );
    } else if (!loading && !latest) {
      setSubTitle("Last updated: Never");
    }
  }, [setSubTitle, loading, latest]);

  const viewAsFilter =
    viewAs === "customer"
      ? (r: IProcessedReading) => r.dataPoint.customerEnable
      : viewAs === "installer"
        ? (r: IProcessedReading) => r.dataPoint.installerEnable
        : () => true;

  return isLoading ? (
    <SectionLoading />
  ) : latest ? (
    <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 768: 2 }}>
      <Masonry gutter="1.5rem">
        {processedReadings
          .filter(viewAsFilter)
          .sort(alphabeticalSort((i) => i.dataPoint.name))
          .map((reading) => (
            <ReadingCard
              key={reading.dataPoint.id}
              hardware={hardware}
              timestamp={latest.timestamp}
              initialProcessedReading={reading}
            />
          ))}
      </Masonry>
    </ResponsiveMasonry>
  ) : (
    <Card>No Readings</Card>
  );
}
